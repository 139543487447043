import { Container, Typography, Box, Grid, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Blogback from '../../../Component/Blogback'
import Writemessage3 from '../../../Component/Writemessage3';
import { Helmet } from 'react-helmet';
import DPAProfile from '../../../Component/DPAProfile';


export default function The_great_skills() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>
            <Helmet>
                <meta charset="UTF-8" />
                <h1>The Great Skills Shortage and BhagvatGita </h1>
                <title>The Great Skills Shortage and BhagvatGita</title>
                <meta name="description" content="Explore the concept of the great skills shortage and its impact. Learn how coaching and focusing on coachability can address skill shortages." />
                <meta name="keywords" content="Great skills shortage, opportunity, actionable knowledge, skills, corporate role, pandemic, socio-economic paradigm, Mahabharat, Lord Krishna, Bhagvat Gita, Duryodhana, PandavaGita, coaching, improvement, employability, talent supply, post-pandemic world, inflation, recession." />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/blog/the-great-skills" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box id="blog-page" className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Typography variant='h3' className='subhead skblue al_center'>
                                Blog
                            </Typography>
                            <Typography variant='h1' className='blue mainhead al_center mb0'>
                                The Great Skills Shortage and BhagvatGita
                            </Typography>
                            <Typography variant='h4' className='blue al_center'>
                                November 30, 2021
                            </Typography>
                        </Grid>
                    </Grid>



                    <Box className='whitebx'>



                        <Typography variant='h6' className='black jst'>Something unprecedented has happened in 2021. There is a great resignation wave at all levels of skills set and nearly every organization is feeling constrained by skills. Skills which are otherwise in demand has greatest shortage, while otherwise mundane skills like waiting tables are also in relatively short supply. </Typography>

                        <Typography variant='h6' className='black jst'>Is this a problem or is this an opportunity? In my view, this is an opportunity for one in multi-generation reset for human race to spread actionable knowledge and skills across all stratas of humanity and what is more important, corporates have a play a pivot role to ensure that we get positive results in this era which has been thrust by pandemic caused stirring of the socio-economic paradigm. </Typography>

                        <Typography variant='h4' className='blue jst'>Let me explain how.</Typography>

                        <Typography variant='h6' className='black jst'>In the great war of Mahabharat, the Lord Krishna imparts the wisdom of being righteous to the confused human Arjun, which enables Arjun to wage a war against his own folks who were on the path to decadence and wrongdoing. Those teachings of the Lord Krishna are embedded in a text called Bhagvat Gita.</Typography>

                        <Typography variant='h6' className='black jst'>Now, there is a seemingly valid argument as to why the Lord should spend time with righteous but confused Arjun, so that Arjun can go on and wage a bloody war? Why not spend the same time with the village of the events, Duryodhana, and teach him the righteous path so that the war can be avoided completed, and no blood is shed?</Typography>

                        <Typography variant='h6' className='black jst'>The answer is given by Duryodhana, the villain of this war, himself; in another body of text called PandavaGita, in which Duryodhana states -</Typography>

                        <Typography variant='h6' className='black jst'>“I know what is right but I am not able to practice it; I know what is wrong and I am not able to keep away from it. I act as I am directed to by some mysterious power that is seated in my heart. I am but a machine.”</Typography>

                        <Typography variant='h4' className='blue jst'>Original text:</Typography>

                        <Typography variant='h6' className='skblue jst'>“जानामि धर्मम् न च मे प्रवृत्ति:, जानाम्यधर्मम् न च मे निवृत्ति:<br />
                            त्वया हृषीकेश हृदिस्थितेन यथा नियुक्तोस्मि तथा करोमि” </Typography>

                        <Typography variant='h6' className='black jst'>The modern term for Duryodhana will be that he is “uncoachable” into right action, although he has all the unnecessary knowledge, because he is not able to translate the knowledge into practice.</Typography>

                        <Typography variant='h6' className='black jst'>I conclude that, there is no point in working with people who consider themselves a machine incapable of improving themselves, whatever maybe their current mastery of skills; but rather work with people, however confused they are today, who want to improve their own understanding and actions. </Typography>

                        <Typography variant='h6' className='black jst'>What the relevance for corporate world here?</Typography>

                        <Typography variant='h6' className='black jst'>There are many articles, which have been published for decades now that, a great majority of graduates are deemed “unemployable” for large corporates because they lack basic skills. I have always taken objection to this term “unemployable” because it transfers the failure of education system to care for its students to the young graduates. The right parameter to look for is to find out if those young people “coachable” or not, however confused and skillless they may sound right now.</Typography>

                        <Typography variant='h6' className='black jst'>In the pre-pandemic world of great supply of talent pouring out in job market, companies could lazily check the current skillsets and categorize the talent as employable or otherwise. In the post-pandemic world of great shortage of skills, corporates have to play a role of Lord Krishna and identify confused but coachable Arjuns. If many corporates make this transition, then human society will improve, otherwise, the bidding war for the “employables” will continue, leading to higher inflation, which will eventually lead to faster arrival of recession. If corporates shift their focus from employables to coachables, the inflationary pressures will die out sooner rather than later, and next recession will be postponed for a longer while.</Typography>



                        {/* <Box mt={5}>
                            <Subscribe />
                        </Box> */}

                        <Box>
                            <DPAProfile />
                        </Box>

                    </Box>


                    <Box className='pages'>
                        <Writemessage3 pageVisited="The Great Skills Shortage and BhagvatGita" />
                    </Box>




                    <Blogback />


                </Container>
            </Box>


        </>
    );
}
