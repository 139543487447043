import { Container, Typography, Box, Grid, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


import Writemessage3 from '../../../Component/Writemessage3';

import Blogback from '../../../Component/Blogback'
import Funding_Stress from "../../../asset/images/insight/blog_pages/funding_stress.webp";
import { Helmet } from 'react-helmet';
import DPAProfile from '../../../Component/DPAProfile';





export default function Extreme_funding_stress() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>



            <Helmet>
                <meta charset="UTF-8" />
                <h1>Extreme Funding Stress in Dollar Market </h1>
                <title>Extreme Funding Stress in Dollar Market | Decimal Point Analytics</title>
                <meta name="description" content="Explore the alarming levels of funding stress in the dollar market. Analyze the liquidity demands and challenges faced by banks with Decimal Point Analytics." />
                <meta name="keywords" content="Funding stress, dollar market, liquidity demand, NY Fed, net funding, 2008 crisis, liquidity shortfall, QE programs, global forced shutdown, Fed, dollar demands, BIS, non-US entities, USD borrowings, dollar liquidity shortage, interesting times." />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/blog/extreme-funding-stress" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>



            <Box id="blog-page" className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Typography variant='h3' className='subhead skblue al_center'>
                                Blog
                            </Typography>
                            <Typography variant='h1' className='blue mainhead al_center mb0'>
                                Extreme Funding Stress in Dollar Market
                            </Typography>
                            <Typography variant='h4' className='blue al_center'>
                                March 18, 2020
                            </Typography>
                        </Grid>
                    </Grid>


                    <Box className='whitebx'>



                        <Typography variant='h6' className='black jst'>One measure of funding stress in dollar markets is how much liquidity is demanded by banks from NY Fed. The chart below shows the net liquidity demand / supply amanaged by NY Fed from 7th July 2000 till 17th March 2020. Positive numbers indicate that banks are short of liquidity; while negative numbers indicate that banks have excess liquidity.</Typography>


                        <Typography variant='h4' className='blue jst'>Chart 1: Net Funding by NY Fed</Typography>


                        <Box className='al_left' mt={4} mb={4}>
                            <img src={Funding_Stress} className='topbanner2' alt='Funding Stress' />
                        </Box>

                        <Typography variant='h6' className='black jst'>Source: NY Fed, Decimal Point Analytics calculations</Typography>

                        <Typography variant='h6' className='black jst'>The chart clearly shows that even during the stress of 2008 crisis, banks faced liquidity shortfall to the tune of $200 billion. However, now, as of yesterday, banks have faced liquidity shortfall to the tune of $500 billion. Meaning, the current funding stress in dollars is far higher than it was ever experienced. This is after nearly $4000 billion of QE programs, which have injected liquidity in the market at the start of 2010s. This is just first week of global forced shutdown, and we do not know how long it is going to last. My guess is that Fed will be racing against time every evening to meet the global dollar demands. As an aside, BIS estimates that non-US entities have borrowed $14 trillion on-balance-sheet in USD terms (and it can be easily assumed that similar amount in USD is borrowed off-balance-sheet by non-US entities). Fed is not legally responsible for ensuring that dollars reach these non-US entities to repay their dollars when they come due. How that sector manages the dollar liquidity shortage will be an interesting item to watch in coming weeks. Be prepared for interesting times.</Typography>

                        {/* <Box mt={5}>
                            <Subscribe />
                        </Box> */}

                        <Box>
                            <DPAProfile />
                        </Box>

                    </Box>


                    <Box className='pages'>
                        <Writemessage3 pageVisited="Extreme Funding Stress in Dollar Market" />
                    </Box>

                    <Blogback />

                </Container>
            </Box>

        </>
    );
}
