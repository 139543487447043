import { Container, Typography, Box, Grid, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


import Writemessage3 from '../../../Component/Writemessage3';
import { Helmet } from 'react-helmet';
import Blogback from '../../../Component/Blogback'
import DPAProfile from '../../../Component/DPAProfile';

export default function AI_heuristics_and_bio() {


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);





    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <h1>Artificial Intelligence, Heuristics and Biomimicry </h1>
                <title>Artificial Intelligence, Heuristics and Biomimicry</title>
                <meta name="description" content="Discover the potential of combining AI, biomimicry, and heuristics to enhance AI systems. Explore natural solutions and heuristics for overcoming AI challenges." />
                <meta name="keywords" content="Artificial Intelligence, AI, heuristics, biomimicry, computation requirements, human-level AGI, natural solutions, efficiency, training sets, biological world, development, challenges, advancements" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/blog/ai-heuristics-and-bio" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box id="blog-page" className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Typography variant='h3' className='subhead skblue al_center'>
                                Blog
                            </Typography>
                            <Typography variant='h1' className='blue mainhead al_center mb0'>
                                Artificial Intelligence, Heuristics and Biomimicry
                            </Typography>
                            <Typography variant='h4' className='blue al_center'>
                                May 29, 2017
                            </Typography>
                        </Grid>
                    </Grid>

                    <Box className='whitebx'>


                        <Typography variant='h6' className='black jst'>Across the world, AI is the talk of town for last two years or so. This area of enquiry is making significant strides day by day, covering more and more ground, and will catch up with animal level intelligence sooner rather than later.</Typography>

                        <Typography variant='h6' className='black jst'>However, when one sees beyond the PR-doped headlines, one sees many hurdles – such as huge computation requirements, need to have large sample sizes, ability of humans to fool the AI easily using simple perturbations, and inability to master human language reproduction, just to name a few. Granted, the scientists are making progress in each of the areas above, however, reaching truly human level AGI is still beyond the horizons that guide most investors.</Typography>

                        <Typography variant='h6' className='black jst'>I think two areas where AI researchers can benefit are Biomimicry and Heuristics. Inputs for both will come natural world, where evolution of four billion years (or is it rather 14 billion years of evolution?) have found certain fast solutions.</ Typography>

                        <Typography variant='h6' className='black jst'>As a first approach, heuristics deployed by living beings, as deciphered by biologists, can be handcoded and given as ready set of solutions which needs to be bettered by any AI solution both in terms of efficacy (achieving the objective function) and efficiency (energy costs, time to solve in terms of computation cycles and size of training sets). This will ensure that AI solutions are better than human understanding of naturally occurring heuristics.</Typography>

                        <Typography variant='h6' className='black jst'>As a second approach, the training sets of AI algorithms should include examples where nature has solved a particular problem efficiently and we humans still do not understand the source of that efficiency. These augmented training sets will be a distraction initially for the algorithm, but with right coaxing, the algorithms can figure out if similar solution can be applied in another context and what adjustments are needed to be made to the natural solution to fit a new application.</Typography>

                        <Typography variant='h6' className='black jst'>I believe adding these two ingredients will further boost already astonishing speed of development in the field of AI.</ Typography>



                        {/* <Box mt={5}>
                            <Subscribe />
                        </Box> */}

                        <Box>
                            <DPAProfile />
                        </Box>

                    </Box>


                    <Box className='pages'>
                        <Writemessage3 pageVisited="Artificial Intelligence, Heuristics and Biomimicry" />
                    </Box>



                    <Blogback />



                </Container>
            </Box>

        </>
    );
}
