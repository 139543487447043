import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Eventback from '../../../Component/Eventback'
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';


export default function UnleashInnovation() {


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Decimal Point Analytics at Cloudflare x YourStory Media Roundtable - December 2024</title>
                <meta name="description" content="Decimal Point Analytics joined the Cloudflare x YourStory Roundtable, discussing cloud optimization, operational efficiency, and flexibility for evolving business needs." />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/roundtable-discussion-on-unleash-innovation-build-the-next-big-thing" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>



            <Box id='event-details' className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={8} md={8} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h2' className='subhead skblue al_center'>
                                    Events
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Roundtable Discussion on Unleash Innovation: Build the Next Big Thing
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box className='whitebx'>

                        <Typography variant='h6' className='black jst'>
                            Decimal Point Analytics participated in the exclusive Cloudflare x YourStory Media Roundtable Discussion, held on December 5, 2024. Dilip Sah, Chief Technology Officer, represented Decimal Point Analytics as a panellist, contributing to insightful discussions on:
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            <ul>
                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        Optimizing cloud expenses while maintaining robust security
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        Streamlining operations for efficient technology management
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        Enhancing flexibility to scale and meet evolving business needs
                                    </Typography>
                                </li>
                            </ul>
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            To discover how Decimal Point Analytics can empower your business by leveraging these insights, <Link to="/contact-us#contact_form" className='link'>reach out to us</Link>.
                        </Typography>

                    </Box>

                    <Eventback />

                </Container>

            </Box>

        </>
    );
}
