import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import { Link } from 'react-router-dom';

import Nov22 from "../../asset/images/media/event/22nov_event.webp";
import Nov18 from "../../asset/images/media/event/18nov_event2.webp";
import Oct10 from "../../asset/images/media/event/10oct_event.webp";
import Oct5 from "../../asset/images/media/event/5oct_event.webp";
import Sep1 from "../../asset/images/media/event/1sep_event.webp";
import Mar24 from "../../asset/images/media/event/24mar_event.webp";
import Nov21 from "../../asset/images/media/event/21nov_event.webp";
import Feb22 from "../../asset/images/media/event/22feb_event.webp";
import Nov3 from "../../asset/images/media/event/3nov_event.webp";
import Aug21 from "../../asset/images/media/event/21aug_event.webp";
import Jul28 from "../../asset/images/media/event/28jul_event.webp";
import Nov30 from "../../asset/images/media/event/30nov_event.webp";
import RoundTable from "../../asset/images/media/event/round_table.webp";
import PRMIA from "../../asset/images/media/event/prmia_event.webp";
import EvolutionOfESG from '../../asset/images/insight/esg/round_table/evolution_of_esg.webp';
import YinandYang from "../../asset/images/media/event/yin_&_yang.webp";
import InnovationSummit from "../../asset/images/media/event/innovation_summit.webp";
import GenAI from "../../asset/images/media/event/genai_for_enterprises.webp";
import tokenization from "../../asset/images/media/event/power_of_tokenization.webp";
import FinancewithAI from "../../asset/images/media/event/finance_with_ai.webp";
import ExploringAiImpact from "../../asset/images/media/event/exploring_ai_impact.webp";
import ALTSNY from "../../asset/images/media/event/altsny.webp";
import ThakurSchool from "../../asset/images/media/event/thakur_global.webp";
import Oracle from "../../asset/images/media/event/oracle_world.webp";
import Fintech from "../../asset/images/media/event/global_fintech.webp";
import Feinstein from "../../asset/images/media/event/feinstein_concert.webp";
import Picup from "../../asset/images/media/event/picup.webp";
import America_East from "../../asset/images/media/event/america_east.webp";
import Finovate from "../../asset/images/media/event/finovate.webp";
import Aarambh from "../../asset/images/media/event/aarambh.webp";
import Harvesting from "../../asset/images/media/event/harvesting_opportunities.webp";
import IFSC from "../../asset/images/media/event/gift_ifsc.webp";
import IFSCA_SAN from "../../asset/images/media/event/ifsca_san_francisco.webp";
import GenAIConclave from "../../asset/images/media/event/gen_ai.webp";
import Talent_Vista from "../../asset/images/media/event/talent_vista2024.webp";
import Boardroom from "../../asset/images/media/event/boardroom_session.webp";
import SmartStrategies from "../../asset/images/media/event/smart_strategies.webp";
import UnleashInnovation from "../../asset/images/media/event/unleash_innovation.webp";

import Event_banner from "../../asset/images/new_banner/event_banner.webp"
import { Helmet } from 'react-helmet';

export default function Event() {


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>

            <Helmet>
                <title>Decimal Point Analytics at the 19th Annual Feinstein Summer Concert </title>
                <meta name="description" content="Explore Decimal Point Analytics' upcoming events, showcasing industry insights and innovative solutions." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/event" />
            </Helmet>

            <Box className="banner_bx">
                <Box style={{ backgroundImage: "url(" + Event_banner + ")" }} className="banner_img">
                    <Box className="img_over bl_overlay">
                        <Grid container spacing={0} alignItems="center" justifyContent='center'>
                            <Grid item lg={4} md={5} sm={6} xs={8}>
                                <Box className="al_center">
                                    <Typography className="white" variant="h1">Event</Typography>
                                    <Box mt={'10px'}>
                                        <Typography className="white" variant="h4">Join Us In Our Insightful Events.</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>

            <Box className='section2 sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Box className='reportinfo'>
                        <Grid container spacing={1} alignItems="top">

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/roundtable-discussion-on-unleash-innovation-build-the-next-big-thing'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + UnleashInnovation + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Roundtable Discussion on Unleash Innovation: Build the Next Big Thing
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Dec, 2024 </Typography>
                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/where-innovation-meets-strategy-an-exclusive-boardroom-session'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + Boardroom + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Where Innovation Meets Strategy: An Exclusive Boardroom Session
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Nov, 2024 </Typography>
                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/decimal-point-analytics-at-ifsca-official-talent-vista-2024'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + Talent_Vista + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Decimal Point Analytics at IFSCA Official Talent Vista 2024
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Nov, 2024 </Typography>
                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/roundtable-discussion-on-smart-strategies-for-multi-cloud-environments-and-data-integration'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + SmartStrategies + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Roundtable Discussion on Smart Strategies for Multi-Cloud Environments and Data Integration
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Nov, 2024 </Typography>
                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/decimal-point-analytics-at-ifsca-san-francisco-conference-2024'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + IFSCA_SAN + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Decimal Point Analytics at IFSCA San Francisco Conference 2024
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Oct, 2024 </Typography>
                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/harvesting-opportunities-funds-in-gift-city-scaling-new-heights'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + Harvesting + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Harvesting Opportunities: Funds in GIFT City Scaling New Heights
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Oct, 2024 </Typography>
                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/decimal-point-analytics-at-the-gift-ifsc-conference-2024-in-new-york'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + IFSC + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Decimal Point Analytics at the GIFT IFSC Conference 2024 in New York
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Oct, 2024 </Typography>
                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/decimal-point-analytics-at-gen-ai-conclave-awards-2024'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + GenAIConclave + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Decimal Point Analytics at Gen.ai Conclave & Awards 2024
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Oct, 2024 </Typography>
                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/oracle-cloud-world-ai-powered-data-management-with-dilip-sah'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + Oracle + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Oracle Cloud World: AI-Powered Data Management with Dilip Sah
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Sep, 2024 </Typography>
                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/finovate-fall-2024-connecting-with-industry-leaders'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + Finovate + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Finovate Fall 2024: Connecting with Industry Leaders
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Sep, 2024 </Typography>
                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/executive-roundtable-on-innovating-finance-with-ai'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + FinancewithAI + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Executive Roundtable on Innovating Finance with AI
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Sep, 2024 </Typography>
                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/global-fintech-fest-2024-insights-from-the-panel-discussion-on-gift-city'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + Fintech + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Global Fintech Fest 2024: Insights from the Panel Discussion on GIFT City
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Aug, 2024 </Typography>
                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/arun-singh-at-avantika-universitys-student-orientation-ceremony-aarambh'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + Aarambh + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Arun Singh at Avantika University’s Student Orientation Ceremony "Aarambh"
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Aug, 2024 </Typography>
                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/decimal-point-analytics-at-america-east-meet-nyc-2024-advancing-data-driven-insights'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + America_East + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Decimal Point Analytics at America East Meet NYC 2024: Advancing Data-Driven Insights
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Aug, 2024 </Typography>
                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/paresh-sharma-represents-decimal-point-analytics-at-the-5th-picup-fintech-conference'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + Picup + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Paresh Sharma Represents Decimal Point Analytics at the 5th PICUP Fintech Conference
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Aug, 2024 </Typography>
                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/shailesh-dhuris-talk-at-thakur-global-business-school-navigating-emerging-markets'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + ThakurSchool + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Shailesh Dhuri's Talk at Thakur Global Business School: Navigating Emerging Markets
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Aug, 2024 </Typography>
                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/decimal-point-analytics-proudly-supported-the-annual-feinstein-summer-concert-featuring-gwen-stefani'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + Feinstein + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Decimal Point Analytics Proudly Supported the 19th Annual Feinstein Summer Concert Featuring Gwen Stefani
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Jul, 2024 </Typography>
                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/decimal-point-analytics-at-altsny-june-2024'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + ALTSNY + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Decimal Point Analytics at ALTSNY June 2024
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Jun, 2024 </Typography>
                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/exploring-ais-impact-on-the-insurance-sector-insights-from-the-mena-insurTech-association-summit-2024'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + ExploringAiImpact + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Exploring AI's Impact on the Insurance Sector: Insights from the MENA InsurTech Association Summit 2024
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > May, 2024 </Typography>
                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/unveiling-the-transformative-power-of-tokenization-insights-from-the-cfa-india-fintech-conference'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + tokenization + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Unveiling the Transformative Power of Tokenization: Insights from the 6th CFA India Fintech Conference
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Mar, 2024 </Typography>
                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/executive-roundtable-exploring-generative-ai-for-enterprises'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + GenAI + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Executive Roundtable: Exploring Generative AI for Enterprises
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Dec, 2023 </Typography>
                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/innovation-summit-2023-at-sda-bocconi-asia-center'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + InnovationSummit + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Innovation Summit 2023 at SDA Bocconi Asia Center
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Oct, 2023 </Typography>
                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/yin-&-yang-of-web3-&-ai-to-transform-insurance-processes'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + YinandYang + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Yin & Yang of Web3 & AI to Transform Insurance Processes
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Oct, 2023 </Typography>
                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/executive-roundtable-the-evolution-of-esg-reporting-implications-for-fund-managers'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + EvolutionOfESG + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Executive Roundtable- The Evolution of ESG Reporting Implications for Fund Managers
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Jul, 2023 </Typography>
                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/quantifying-esg-risk'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + PRMIA + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Quantifying ESG Risk
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Jun, 2023 </Typography>
                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/executive-roundtable-on-portfolio-alignment-tools'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + RoundTable + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Executive Roundtable on Portfolio Alignment Tools: Current Practices and Outlook
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Mar, 2023 </Typography>
                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/convergence-of-ai-and-iot'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + Nov30 + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Convergence of AI & IoT for a safer and equal society
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Nov, 2022 </Typography>
                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/application-of-machine-learning-in-catastrophe-modeling'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + Nov22 + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Application of Machine Learning in Catastrophe Modeling
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Nov, 2022 </Typography>
                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/data-analytics-making-it-work'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + Nov18 + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Data Analytics: Making it work in the real world
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Nov, 2022 </Typography>
                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/round-table-by-dpa'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + Oct10 + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Round Table by DPA - Is Net zero still a myth?
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Oct, 2022 </Typography>
                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/techniche-2022'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + Oct5 + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Decimal point analytics a sponsorer in “Techniche 2022”
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Oct, 2022 </Typography>
                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/are-developed-markets-riskier'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + Sep1 + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Are Developed Markets Riskier Than Developing Markets?
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Sep, 2022 </Typography>
                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/indo-french-chamber-of-commerce'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + Mar24 + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Indo-French Chamber of Commerce and Industry (IFCCI)
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Mar, 2021 </Typography>
                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/chetana-institutes-of-management'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + Nov21 + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Chetana’s Institutes of Management
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' >Nov, 2019 </Typography>
                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/mipm-ies-mcrc-convention'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + Feb22 + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                NIPM - IES MCRC Convention
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' >Feb, 2019 </Typography>
                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/weschool-mumbai'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + Nov3 + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                WeSchool Mumbai
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Nov, 2018 </Typography>
                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/professional-risk-managers-international-association'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + Aug21 + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Professional Risk Managers' International Association
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Aug, 2018 </Typography>
                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/nl-dalmia-college'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + Jul28 + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                NL Dalmia College
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Jul, 2018 </Typography>
                                    </Box>
                                </Link>
                            </Grid>

                        </Grid>
                    </Box>


                </Container>
            </Box>

        </>
    );
}
