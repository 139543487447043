import { Container, Typography, Box, Grid, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


import Writemessage3 from '../../../Component/Writemessage3';

import Blogback from '../../../Component/Blogback'
import { Helmet } from 'react-helmet';
import DPAProfile from '../../../Component/DPAProfile';
export default function From_us_to_yes_bank() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>



            <Helmet>
                <meta charset="UTF-8" />
                <h1>From Bank of United States to Yes Bank: Why Bank Stocks should be ineligible as loan security </h1>
                <title>Why Bank Stocks Should Be Ineligible As Loan Security</title>
                <meta name="description" content="Explore historical examples of bank failures and the risks of using bank stocks as loan security. Learn proposed solutions and industry implications." />
                <meta name="keywords" content="Bank failures, Bank of United States, Yes Bank, loan security, equity capital, depositors' funds, financial stability, capital adequacy ratios, ineligible equity, refinancing, gullible depositors, debate, banking industry." />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/blog/from-us-to-yes-bank" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box id="blog-page" className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Typography variant='h3' className='subhead skblue al_center'>
                                Blog
                            </Typography>
                            <Typography variant='h1' className='blue mainhead al_center mb0'>
                                From Bank of United States to Yes Bank: Why Bank Stocks should be  ineligible  as loan security
                            </Typography>
                            <Typography variant='h4' className='blue al_center'>
                                April 14, 2020
                            </Typography>
                        </Grid>
                    </Grid>

                    <Box className='whitebx'>



                        <Typography variant='h6' className='black jst'>In December of 1930 Bank of United States (BOUS), based primarily in the city of New York failed. It was the first bank failure in New York during the great depression which started in Oct 1929. The main reason for the failure of the bank was that although ostensibly the bank had sufficient equity capital, in reality, the bank had extended loans against its own shares using, what we will call in modern parlance, SPVs. Thus, in effect, bank had no equity for long time. It was showing depositor’s funds as equity using various routing techniques. </Typography>

                        <Typography variant='h6' className='black jst'>Now, in November of 2019, Yes Bank based in Mumbai is in financial trouble. It is now emerging that Rana Kapoor and other key personnel of the bank had refinanced their equity holdings in the bank from entities who were in effect funded by Yes Bank at related party level. In other words, Yes Bank’s equity capital to that extent is sham capital and depositors’ money has been used to create an illusion of equity capital in this case as well.</Typography>

                        <Typography variant='h6' className='black jst'>Thus, root cause of troubles in these two banks separated by 89 years and seven seas is the same – using depositors’ funds to bolster equity capital.</Typography>

                        <Typography variant='h6' className='black jst'>Whatever capital adequacy ratios that regulators may painstakingly double check are meaningless in these cases. We have two possible solutions to this issue. The first being making bank and NBFC equity ineligible for any loan as security. It should be noted that BOUS had used gullible depositors as well to boost their equity in this manner; so, it is not just promoter equity but any equity in bank should be ineligible for refinancing. The second option is that any bank equity shares on which lien was marked in last 24 months should not be counted as equity capital of bank while calculating CAR for the bank in question.</Typography>

                        <Typography variant='h6' className='black jst'>It will be interesting to see if there is any debate that happens on this topic and what would be the potential outcome of any such debate.</Typography>



                        {/* <Box mt={5}>
                            <Subscribe />
                        </Box> */}

                        <Box>
                            <DPAProfile />
                        </Box>

                    </Box>



                    <Box className='pages'>
                        <Writemessage3 pageVisited="From Bank of United States to Yes Bank Why Bank Stocks should be ineligible as loan security" />
                    </Box>



                    <Blogback />

                </Container>
            </Box>


        </>
    );
}
