import { Container, Typography, Box, Divider, Grid, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Blogback from '../../../Component/Blogback'

import Writemessage3 from '../../../Component/Writemessage3';
import { Helmet } from 'react-helmet';
import DPAProfile from '../../../Component/DPAProfile';



export default function Advances_in_ai() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <h1>Advances in AI - An opportunity for India’s IT/ITES sector to advance in productivity </h1>
                <title>Advances in AI for Enhanced Productivity and Global Growth</title>
                <meta name="description" content="Discover how advances in AI enhance productivity in India's IT/ITES sector. Leverage AI for exponential growth and global demand in IT services." />
                <meta name="keywords" content="Advances in AI, India, IT/ITES sector, productivity, IT services exports, per capita productivity, global economy, demand for IT services, industry crisis, skilled programmers, AI adoption, business strategies, productivity-enhancing tool." />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/blog/advances-in-ai" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />

            </Helmet>


            <Box id="blog-page" className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Typography variant='h3' className='subhead skblue al_center'>
                                Blog
                            </Typography>
                            <Typography variant='h1' className='blue mainhead al_center mb0'>
                                Advances in AI - An opportunity for India’s IT/ITES sector to advance in productivity
                            </Typography>
                            <Typography variant='h4' className='blue al_center'>
                                February 07, 2023
                            </Typography>
                        </Grid>
                    </Grid>


                    <Box className='whitebx'>



                        <Typography variant='h6' className='black jst' > Recently in conversation, a friend on the board of a large listed firm in India expressed fears that automated programming tools powered by AI, for example, ChatGPT, could lead to erosion of India’s IT/ITES exports, resulting in job losses and social unrest. </Typography>

                        <Typography variant='h6' className='black jst' >However, I consider this to be an opportunity for India to move up the value chain. Consider this, for example, Israel, with a population of just 8.9 million, has IT/ITES exports of approximately $50 billion (CY2020). India’s population is approximately 138.9 million,  and we export IT/ITES services worth approximately $180 billion (FY 2022). </Typography>



                        <Typography variant='h6' className='black jst' >India’s IT services exports will increase exponentially if we are able to achieve the per capita productivity of Israel. If India achieves a comparable level of per capita productivity that Israel demonstrates for IT services exports, Indian exports of IT can be $8 trillion in the next two decades, by 2045. </Typography>

                        <Divider />

                        <Box mt={1} mb={2}>
                            <Typography variant='h4' className='blue jst' > The question then is about the demand - will the global economy require this level of IT services exports from India?  </Typography>
                        </Box>


                        <Typography variant='h6' className='black jst' >The 2022 global GDP was estimated at USD 100 trillion. In nominal terms of growth recorded over the past two decades, the global GDP could increase to USD 300 trillion Most of this growth (USD 200 trillion) will be driven by technology embedding in all aspects of the economy, such as Industry 4.0 / IoT / DLT and AI. </Typography>


                        <Typography variant='h6' className='black jst' >To enable this next USD 200 trillion, at current productivity levels of Indian industry, which is the mainstay of global IT outsourcing, is nearly impossible. The shortages of skilled programmers, which have persisted at various intensities since 2012, will just worsen into an industry crisis if productivity does not improve. </Typography>

                        <Typography variant='h6' className='black jst' >By adopting AI effectively, India may be able to produce more value from its population and support the growth of the global economy for the next two decades or more. This will benefit the world in terms of a better standard of living, and serve India well by way of higher IT/ITES exports.</Typography>



                        <Typography variant='h6' className='black jst' >When viewed from a wider perspective, the IT industry need not fear the programming prowess of AI, but should change their business strategies and methods to co-opt this productivity-enhancing tool.</Typography>


                        <Typography variant='h6' className='black jst' >
                            <strong>Shailesh Dhuri</strong> <br />
                            CEO, Decimal Point Analytics Pvt. Ltd
                        </Typography>


                        <Divider />

                        <Box mt={1}>
                            <Typography variant='h4' className='blue jst' > Appendix </Typography>
                        </Box>


                        <Typography variant='h6' className='black jst' >
                            <ul>
                                <li><a href='https://www.cia.gov/the-world-factbook/countries/india/summaries' target='_blank' rel="noopener noreferrer">  https://www.cia.gov/the-world-factbook/countries/india/summaries </a></li>
                           
                                <li> <a href='https://en.globes.co.il/en/article-Israels-technology-exports-assume-ever-greater-importance-1001358460' target='_blank' rel="noopener noreferrer"> https://en.globes.co.il/en/article-Israels-technology-exports-assume-ever-greater-importance-1001358460 </a> </li>
                                <li> <a href='https://www.cia.gov/the-world-factbook/countries/israel/' target='_blank' rel="noopener noreferrer"> https://www.cia.gov/the-world-factbook/countries/israel/ </a>  </li>
                            </ul>
                        </Typography>

                        <Box>
                            <DPAProfile />
                        </Box>


                    </Box>



                    <Box className='pages'>
                        <Writemessage3 pageVisited="Advances in AI - An opportunity for India’s IT/ITES sector to advance in productivity" />
                    </Box>


                    <Blogback />


                </Container>
            </Box>


        </>
    );
}
