import { Container, Typography, Box, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';
import Writemessage3 from '../../../Component/Writemessage3';
import Blogback from '../../../Component/Blogback'
import { Link } from 'react-router-dom';
import DPAProfile from '../../../Component/DPAProfile';


export default function SouthAfricasRemarkableRise() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <h1>South Africa's Remarkable Rise in Responsible Investing and Emission Control: Insights from DPA South Africa ESG Review 2023</h1>
                <title>South Africa's Remarkable Rise in Responsible Investing</title>
                <meta name="description" content="Discover how South Africa leads in responsible investing and promotes social equity. Explore insights from Decimal Point Analytics' ESG Review 2023." />
                <meta name="keywords" content="South Africa, Responsible Investing, ESG, Emission Control, UN PRI, CRISA Code, Carbon Tax, Social Equity, B-BBEE Program, Gender Equality, TCFD Reporting, DPA South Africa ESG Review 2023" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/blog/south-africas-remarkable-rise-in-responsible-investing-insights-from-dpa-south-africa-esg-review-2023" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box id="blog-page" className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Typography variant='h3' className='subhead skblue al_center'>
                                Blog
                            </Typography>
                            <Typography variant='h1' className='blue mainhead al_center'>
                                South Africa's Remarkable Rise in Responsible Investing: Insights from DPA South Africa ESG Review 2023
                            </Typography>
                        </Grid>
                    </Grid>

                    <Box className='whitebx'>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            Responsible Investing is well entrenched in South Africa, finds Decimal Point Analytic’s (DPA) paper ‘SOUTH AFRICA ESG Review 2023’. The proportion of South African funds with responsible investing (RI) focus is more than several larger countries. Further, the regulatory sector has been proactive with creating legislation enabling companies and funds to focus on responsibility investing.
                        </Typography>



                        <Typography variant='h6' className='black jst' gutterBottom> South African funds have been pioneers in RI adoption. As of July’23, 77 entities from South Africa are signatories with UN PRI, giving the nation the 17th rank globally on this count. This is much better than South Africa’s global GDP rank of 37. South African funds had started committing to Responsible Investing under the UN PRI umbrella as early as 2006, making the country one of pioneers of Responsible Investing globally, and in Africa in particular. </Typography>


                        {/* <Box pt={1} pb={1}>
                            <Typography variant='h4' className='blue jst'> Enhancing Risk Management:  </Typography>
                        </Box> */}

                        <Typography variant='h6' className='black jst' gutterBottom>
                            The CRISA code for RI was also a pioneering effort in promoting RI focus. The first Code for Responsible Investing in South Africa (CRISA) was launched as early as in 2011 to encourage institutional investors and service providers to integrate environmental, social and governance (ESG) issues into their investment decisions. An updated code CRISA2 was issued in 2022; this builds on the first CRISA Code (2011) and contains five principles for stewardship and responsible investment as a key component of the South African governance framework,
                        </Typography>




                        <Typography variant='h6' className='black jst' gutterBottom> On actual per capita emissions, South Africa fares adversely, indicating need for more emission focus. South Africa is the 13 largest carbon emitting nation in the world, accounting for 1.3% of global emissions. South Africa’s population is 60m, or about 0.77% of the global population. It is the 24th largest nation in the world in terms of population. In other words, South Africa’s emission rank is higher than its population rank, and its GDP rank. This suggests a need for a greater sense of urgency with respect to emission control.
                        </Typography>



                        {/* <Box pt={1} pb={1}>
                            <Typography variant='h4' className='blue jst'> Improving Customer Experience: </Typography>
                        </Box> */}

                        <Typography variant='h6' className='black jst' gutterBottom>
                            The country’s updated Nationally Determined Contribution (NDC), released in 2021, displays greater ambition in emission reduction compared to the first 2015 version. South Africa is the first African country to adopt a carbon tax policy. South Africa’s corporate sector is increasing its focus on the environment and Net Zero action. At this point, 16 large corporates are working with SBTi for net zero target validation.
                        </Typography>




                        <Typography variant='h6' className='black jst' gutterBottom>
                            Social focus has always been strong in South Africa led by the B-BBEE program. South Africa has taken considerable action in improving social equity, aimed at addressing its history of black oppression, via its the Broad-Based Black Economic Empowerment (B-BBEE) program. There is scope for the compliance to improve. The country has also done well in promoting gender equality. The proportion of women in the formal sector is much better than most countries (while still inferior to males). For example, in the public sector, women are more than 40% of employment at both middle and senior levels. This compares to 25-30% female participation at top levels in Canada, a much more advanced economy.
                        </Typography>




                        {/* <Box pt={1} pb={1}>
                            <Typography variant='h4' className='blue jst'> Detecting Fraud: </Typography>
                        </Box> */}

                        <Typography variant='h6' className='black jst' gutterBottom>
                            South African funds need to improve TCFD reporting focus. We looked at the RI practices amongst South African managed asset companies. We find the practices here are on par with most major economies, and better than many Asian countries that are still lagging on RI practices. Most funds have RI policies, several have internal frameworks for assessing ESG performance; funds are using external data and research sources as well as conducting their own research. Funds are publishing some form of sustainability reports, however, TCFD focus is still nascent and has scope to pick up, less than 10% have published TCFD reports as per a study.
                        </Typography>




                        <Typography variant='h6' className='black jst' gutterBottom>
                            To sum up, South Africa has a great chance to be an example to the rest of the world, and in Africa in particular, on climate and social goals.
                        </Typography>



                        <Typography variant='h6' className='black jst' gutterBottom>
                            Get detailed insights about the South Africa’s ESG Landscape in our South Africa ESG Review 2023 Whitepaper, <Link to="/esg-insights/esg-whitepaper/esg-south-africa-review-2023-whitepaper" className="link" > Download Now. </Link>
                        </Typography>

                        <Box>
                            <DPAProfile />
                        </Box>

                    </Box>


                    <Box className='pages'>
                        <Writemessage3 pageVisited="South Africa's Remarkable Rise in Responsible Investing: Insights from DPA South Africa ESG Review 2023" />
                    </Box>

                    <Blogback />


                </Container>
            </Box>

        </>
    );
}
