import { Container, Typography, Box, Grid, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


import Writemessage3 from '../../../Component/Writemessage3';

import Blogback from '../../../Component/Blogback'
import { Helmet } from 'react-helmet';
import DPAProfile from '../../../Component/DPAProfile';


export default function India_deflation() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>
            <Helmet>
                <meta charset="UTF-8" />
                <h1>India Deflation: Strategic, Tactical and Operational Options for RBI  </h1>
                <title>India Deflation: Strategic, Tactical and Operational Options for RBI</title>
                <meta name="description" content="Explore RBI's options in response to the emerging threat of deflation in India. Analyze the potential impact of revising inflation targets and lowering policy rates." />
                <meta name="keywords" content="India deflation, RBI, strategic response, inflation targeting, inflation corridor, long-term inflation target, tactical response, low policy rates, operational response, Market Stabilization Scheme (MSS), deflation risk, inflation expectations, interest rates, economic stability" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/blog/india-deflation" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>

            <Box id="blog-page" className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Typography variant='h3' className='subhead skblue al_center'>
                                Blog
                            </Typography>
                            <Typography variant='h1' className='blue mainhead al_center mb0'>
                                India Deflation: Strategic, Tactical and  Operational Options for RBI
                            </Typography>
                            <Typography variant='h4' className='blue al_center'>
                                July 26, 2017
                            </Typography>
                        </Grid>
                    </Grid>

                    <Box className='whitebx'>



                        <Typography variant='h6' className='black jst'>In one of my previous write ups, I sketched the most likely scenario of India facing deflation pretty soon. In this write up, I will describe various responses that RBI should have to have for this emerging black swan event. (Black Swan as far as markets and policy makers are concerned.)</Typography>

                        <Typography variant='h4' className='blue jst'>Strategic Response: Narrower, Lower, Forever inflation band</Typography>

                        <Typography variant='h6' className='black jst'>RBI has done really well in announcing inflation corridor a few years ago and, as a result, anchoring inflation expectations. Now, the phase two of this noble journey begins. To take baby steps for inflation targeting, it was alright to set initial target relatively high and wide as compared to other nations. Given that Indian inflation at that time was 7-8 percent and higher, to have a long-term target of 4 percent with a wide 4 four percent band was a visionary move.</Typography>

                        <Typography variant='h6' className='black jst'>It is time now to again display such visionary zeal and revise the long-term inflation target. Maybe RBI will do well to announce a 2 percent inflation target with one percentage range to be achieved by 2022.</Typography>

                        <Typography variant='h6' className='black jst'>RBI would also do well to have the above target in terms of three year CAGR, given the havoc that monsoon can play with annual inflation measurement. Granted, given that media has to sell something in the 24-hour always-on news cycle, any perturbations due to supply shock are dinned up in the media. However, most households can manage sporadic, transient, at the margin price volatility for just a part of basket of goods consumed. What hurts the economy most is the systematic, corrosive, long-term loss of purchasing power.</Typography>

                        <Typography variant='h6' className='black jst'>Yes, there is no international precedence of central bank targeting long-term inflation. But, India had a long tradition of global thought leadership since the times of Buddha and it is time to reassert that thought leadership and do what is right for us Indians and not what is preached by others.</Typography>

                        <Typography variant='h4' className='blue jst'>Tactical Response: Low Policy Rates</Typography>

                        <Typography variant='h6' className='black jst'>I believe all the analysts are already (and some would say always) clued on this response and hence I will not spend much time discussing this, except to state that its immoral to force households to pay 7 percent real interest rates on their housing loans for too long.</Typography>

                        <Typography variant='h4' className='blue jst'>Operational Response: Abandon MSS</Typography>

                        <Typography variant='h6' className='black jst'>RBI has locked up about Rs. 95,000 crores in MSS securities. This should be unwound forthwith and fully, to guide market rates lower till RBI gets chance to review and weigh its policy response on rates.</Typography>


                        {/* <Box mt={5}>
                            <Subscribe />
                        </Box> */}

                        <Box>
                            <DPAProfile />
                        </Box>

                    </Box>


                    <Box className='pages'>
                        <Writemessage3 pageVisited="India Deflation Strategic Tactical and Operational Options for RBI" />
                    </Box>



                    <Blogback />


                </Container>
            </Box>

        </>
    );
}
