import { Container, Typography, Box, Grid, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { Helmet } from 'react-helmet';
import Banner from '../../../asset/images/insight/whitepaper_pages/banner_predictive-intelligence-financial.webp'

import Whitepaperback from '../../../Component/Whitepaperback';
import ViewFullWhitepaper from '../../../Component/forms/ViewFullWhitepaper';

export default function PredictiveIntelligenceFinancial() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    const link = "https://objectstorage.ap-mumbai-1.oraclecloud.com/n/bmexgnkisxsv/b/marketing_dpa/o/Whitepaper%20on%20Predictive%20intelligence%20set%20to%20transform%20financial%20services%20in%202025.pdf"

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Predictive Intelligence in Financial Services: Preparing for the Future | Whitepaper</title>
                <meta name="description" content="Discover how AI and ML are transforming financial decision-making, customer engagement, and fraud detection in financial services." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/whitepapers/predictive-intelligence-in-financial-services-preparing-for-the-future" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box className='section sec_bg'>
                <Container maxWidth="lg">

                    <Grid container spacing={2} justifyContent='center'>


                        <Grid item lg={12} md={12} sm={12} xs={12} className='grid-custom-12'>


                            <Grid container spacing={1} alignItems="top" justifyContent='center' >
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box>
                                        <Typography variant='h2' className='subhead skblue  al_center'>
                                            Whitepaper
                                        </Typography>
                                        <Typography variant='h1' className='blue mainhead al_center' style={{ textTransform: 'none' }}>
                                            Predictive Intelligence in Financial Services: Preparing for the Future
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Box className="" pt={2}>
                                <img src={Banner} className='w100' alt='Banner' />
                            </Box>

                            <Box className='whitebx' mt={1}>


                                <Box mb={1}>
                                    <Grid container spacing={4} alignItems="flex-start">
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Box>

                                                <Typography variant='h6' className='black jst'>
                                                    Market volatility and rising competition make traditional risk management methods obsolete. Leading FSIs are leveraging AI and ML for a competitive edge.
                                                </Typography>

                                                <Typography variant='h6' className='black jst' gutterBottom>
                                                    <strong>Download our Whitepaper: "Predictive Intelligence in Financial Services: Preparing for the Future"</strong> Learn about:
                                                    <ul>
                                                        <li>
                                                            <Typography variant='h6' className='black jst'>
                                                                AI-driven transformation in decision-making, customer journeys, and fraud prevention
                                                            </Typography>
                                                        </li>
                                                        <li>
                                                            <Typography variant='h6' className='black jst'>
                                                                Predictive intelligence use cases, including hyper-personalization and risk management.
                                                            </Typography>
                                                        </li>
                                                        <li>
                                                            <Typography variant='h6' className='black jst'>
                                                                Challenges in adopting predictive AI: data quality, infrastructure, and ethics.
                                                            </Typography>
                                                        </li>
                                                    </ul>
                                                </Typography>

                                                <Typography variant='h6' className='black jst'>
                                                    Uncover the future of financial services and see how leading institutions are leveraging AI to tackle challenges in...
                                                </Typography>

                                            </Box>

                                            <Box className='graybbx2222' mt={2}>
                                                <ViewFullWhitepaper reportby={"Getting Visual with Data for Personalized Banking"} link={link} />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>


                        </Grid>


                        <Grid item lg={4} md={5} sm={5} xs={12} className='grid-custom-12'>
                            <Box >
                                <Whitepaperback />
                            </Box>
                        </Grid>


                    </Grid>
                </Container>
            </Box>

        </>
    );
}
