
import img3 from "../../asset/images/services/sba_blogs/blog_thumbnails/what_are_small.webp";
import img4 from "../../asset/images/services/sba_blogs/blog_thumbnails/business_loan.webp";
import img5 from "../../asset/images/services/sba_blogs/blog_thumbnails/building_process_efficiency.webp";
import img6 from "../../asset/images/services/sba_blogs/blog_thumbnails/guide_to_scaling.webp";
import img8 from "../../asset/images/services/sba_blogs/blog_thumbnails/sba_loan_approvals.webp";


import WhatAreSBALoans from "./SmallBusinessAdministration/WhatAreSBALoans";
import OverviewUSSmallBusinessLoans from "./SmallBusinessAdministration/OverviewUSSmallBusinessLoans";
import BuildingProcessEfficiency from "./SmallBusinessAdministration/BuildingProcessEfficiency";
import The2025GuidetoScalingSBA from "./SmallBusinessAdministration/The2025GuidetoScalingSBA";
import AutomationSpeedsUp from "./SmallBusinessAdministration/AutomationSpeedsUp";

const SBA_Data = [
  {
    link: function () {
      return (
        '/small-business-administration/' + this.heading.toLowerCase().trim().replace(/[:,()]/g, '').replace(/\s+/g, '-')
      );
    },
    imgsrc: img8,
    heading: "How Automation Speeds Up SBA Loan Approvals Without Compromising Accuracy",
    content: AutomationSpeedsUp
  },
  {
    link: function () {
      return (
        '/small-business-administration/' + this.heading.toLowerCase().trim().replace(/[:,()]/g, '').replace(/\s+/g, '-')
      );
    },
    imgsrc: img6,
    heading: "The 2025 Guide to Scaling SBA Loan Processing Without Increasing Overheads",
    content: The2025GuidetoScalingSBA
  },
 
  {
    link: function () {
      return (
        '/small-business-administration/' + this.heading.toLowerCase().trim().replace(/[:,()?]/g, '').replace(/\s+/g, '-')
      );
    },
    imgsrc: img3,
    heading: "What are Small Business Administration (SBA) Loans?",
    content: WhatAreSBALoans
  },
  {
    link: function () {
      return (
        '/small-business-administration/' + this.heading.toLowerCase().trim().replace(/[:,()?]/g, '').replace(/\s+/g, '-')
      );
    },
    imgsrc: img4,
    heading: "Overview of US Small Business Loans",
    content: OverviewUSSmallBusinessLoans
  },
  {
    link: function () {
      return (
        '/small-business-administration/' + this.heading.toLowerCase().trim().replace(/[:,()?]/g, '').replace(/\s+/g, '-')
      );
    },
    imgsrc: img5,
    heading: "Building process efficiency for US Lenders to offer ERC/SBA loans",
    content: BuildingProcessEfficiency
  },
];


export default SBA_Data;