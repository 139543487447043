import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Eventback from '../../../Component/Eventback'
import { Helmet } from "react-helmet";


export default function InnovationMeetsStrategy() {


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Suyash Shrivastava at "Where Innovation Meets Strategy" | Decimal Point Analytics</title>
                <meta name="description" content="Suyash Shrivastava, Senior VP at Decimal Point Analytics, joined industry leaders at the Grand Hyatt, Mumbai, to discuss scalability, cost efficiency, and Oracle Cloud strategies" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/where-innovation-meets-strategy-an-exclusive-boardroom-session" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>



            <Box id='event-details' className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={8} md={8} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h2' className='subhead skblue al_center'>
                                    Events
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Where Innovation Meets Strategy: An Exclusive Boardroom Session
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box className='whitebx'>

                        <Typography variant='h6' className='black jst'>
                            <strong>Suyash Shrivastava, Senior Vice President - Database Architect at Decimal Point Analytics</strong>, participated in the exclusive boardroom session Where Innovation Meets Strategy held at the Grand Hyatt, Mumbai.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            This invite-only event brought together industry leaders to discuss critical topics shaping the future of business and technology:
                            <ul>
                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        <strong>Overcoming scalability and performance barriers</strong>
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        <strong>Achieving cost efficiency at scale</strong>
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        <strong>Harnessing Oracle Cloud Infrastructure for startup growth</strong>
                                    </Typography>
                                </li>
                            </ul>
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            The session provided an excellent platform for exchanging ideas, exploring strategies, and driving meaningful innovation.
                        </Typography>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            At <strong>Decimal Point Analytics</strong>, we remain committed to staying at the forefront of technological advancements, ensuring our solutions deliver transformative value for clients across industries.
                        </Typography>

                    </Box>

                    <Eventback />

                </Container>

            </Box>

        </>
    );
}
