import { Typography, Box, IconButton } from '@material-ui/core';
import React from 'react';
import LinkedInIcon from "@material-ui/icons/LinkedIn"


import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import DTT from "../asset/images/dpa_think_tank.png"


export default function DPAProfile({ imgSrc, name, designation, company, linkedinUrl }) {



    return (
        <>
            <Box id='EmployeeProfile' className='dpa_profile'>

                <List>
                    <ListItem>
                        <ListItemAvatar>
                            <Box className='teama' style={{ backgroundImage: 'url(' + DTT + ')' }}></Box>
                        </ListItemAvatar>
                        <ListItemText
                            primary={<>
                                <Typography variant='h3' className='teaminfo11 blue al_left mb0 h3h4' gutterBottom>
                                    DPA Think Tank
                                </Typography>
                                <Box className='line'></Box>
                            </>}

                            secondary={<>
                                <Box my={1}>
                                    <Typography variant='h6' className='black al_left mb0' gutterBottom>
                                        DPA Think Tank represents the collective wisdom of experts across Decimal Point Analytics. It’s a hub where diverse perspectives converge to share insights, knowledge, and strategies, fostering a deeper understanding of industry trends and innovations.
                                    </Typography>
                                </Box>
                                <Box>
                                    <a href="https://www.linkedin.com/company/decimalpointanalytics/" target='_blank' rel="noopener noreferrer">
                                        <IconButton aria-label="delete" size='medium' className='linkedInicon'>
                                            <LinkedInIcon fontSize="small" className='blue' />
                                        </IconButton>
                                    </a>
                                </Box>
                            </>} />
                    </ListItem>
                </List>

            </Box>
        </>
    );
}