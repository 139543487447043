import { Container, Typography, Box, Grid, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { Helmet } from 'react-helmet';
import Banner from '../../../asset/images/insight/whitepaper_pages/banner_banking.webp'
import ViewFullWhitepaper from '../../../Component/forms/ViewFullWhitepaper';

import Whitepaperback from '../../../Component/Whitepaperback';
import { Divider } from '@mui/material';

export default function GettingVisualWithData() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    const link = "https://objectstorage.ap-mumbai-1.oraclecloud.com/n/bmexgnkisxsv/b/marketing_dpa/o/Decimal%20Point_whitepaper_Paving%20the%20way%20for%20modern%20banking%20to%20foster%20greater%20customer%20intimacy_v1_for%20review.pdf"

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Getting Visual with Data for Personalized Banking</title>
                <meta name="description" content="Explore how banks leverage data visualization tools like Power BI to enhance customer relationships, personalize banking experiences, and optimize operational efficiency." />
                <meta name="keywords" content="data visualization, Power BI, personalized banking, customer relationships, AI in banking, machine learning in banking" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/whitepapers/getting-visual-with-data-for-personalized-banking" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box className="section sec_bg">
                <Container maxWidth="lg">

                    <Grid container spacing={2} justifyContent='center'>


                        <Grid item lg={12} md={12} sm={12} xs={12} className='grid-custom-12'>



                            <Grid container spacing={1} alignItems="top" justifyContent='center' >
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box>
                                        <Typography variant='h3' className='subhead skblue al_center'>
                                            Whitepaper
                                        </Typography>
                                        <Typography variant='h1' className='blue mainhead al_center' style={{ textTransform: 'none' }}>
                                            Getting Visual with Data for Personalized Banking
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Box className="" pt={2}>
                                <img src={Banner} className='w100' alt='Banner' />
                            </Box>

                            <Box className='whitebx' mt={1}>


                                <Box mb={1}>
                                    <Grid container spacing={4} alignItems="flex-start">
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Box>

                                                <Typography variant='h6' className='black jst'>
                                                    Today’s customers expect a deeper and a more personalized connection with their financial institutions. Traditional banking methods are insufficient. Banks can leverage data visualization tools to transform vast amounts of data to facilitate meaningful customer interactions, enhanced engagement and tailored offerings.
                                                </Typography>

                                                <Typography variant='h6' className='black jst' gutterBottom>
                                                    Our Whitepaper – “Getting Visual with Data for Personalized Banking” explores:
                                                    <ul>
                                                        <li>
                                                            <Typography variant='h6' className='black jst'>
                                                                The transformative power of data visualization in banking
                                                            </Typography>
                                                        </li>
                                                        <li>
                                                            <Typography variant='h6' className='black jst'>
                                                                How Microsoft’s Power BI helps banks integrate diverse data sources for real-time insights
                                                            </Typography>
                                                        </li>
                                                        <li>
                                                            <Typography variant='h6' className='black jst'>
                                                                The benefits of hyper-personalization and how it can drive customer loyalty
                                                            </Typography>
                                                        </li>
                                                    </ul>
                                                </Typography>

                                                <Typography variant='h6' className='black jst'>
                                                    Don’t miss this essential guide to modernizing your banking operations and deepening customer relationships using powerful data visualization.
                                                </Typography>

                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Divider />

                                <Box mt={2} className='al_center'>
                                    <Typography variant="h4" className="black">
                                        Get Your Full Copy Now – Simply Fill Out the Form
                                    </Typography>
                                </Box>


                                <Box className='graybbx2222' mt={2}>
                                    <ViewFullWhitepaper reportby={"Getting Visual with Data for Personalized Banking"} link={link} />
                                </Box>

                            </Box>




                        </Grid>




                        <Grid item lg={12} md={12} sm={12} xs={12} className='grid-custom-12'>
                            <Box>
                                <Whitepaperback />
                            </Box>
                        </Grid>


                    </Grid>
                </Container>
            </Box>

        </>
    );
}
