import React from 'react';
import { Route } from 'react-router-dom';
import SBAFooter from './SBAFooter';
import SBAHeader from './SBAHeader';
import Header from './Header';
import FooterNew from './Footer_new';
import MegamenuHeader from './MegamenuHeader';
import CRMHeader from './CRMHeader';

const DPALayout = ({ children }) => {
  return (
    <>
      <Header />
      {children}
      <FooterNew />
    </>
  );
};

const MainLayout = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <DPALayout>
          <Component {...props} />
        </DPALayout>
      )}
    />
  );
};


const DPALayout1 = ({ children }) => {
  return (
    <>
      <SBAHeader />
      {children}
      <SBAFooter />
    </>
  );
};

const SBALayout = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <DPALayout1>
          <Component {...props} />
        </DPALayout1>
      )}
    />
  );
};

const DPALayout4 = ({ children }) => {
  return (
    <>
      <CRMHeader />
      {children}
      {/* <SBAFooter /> */}
    </>
  );
};

const CRMLayout = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <DPALayout4>
          <Component {...props} />
        </DPALayout4>
      )}
    />
  );
};


const DPALayout2 = ({ children }) => {
  return (
    <>
      {children}
      <SBAFooter />
    </>
  );
};


const SBASubmitLayout = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <DPALayout2>
          <Component {...props} />
        </DPALayout2>
      )}
    />
  );
};


const DPALayout3 = ({ children }) => {
  return (
    <>
      <MegamenuHeader />
      {children}
      <FooterNew />

    </>
  );
};


const MegamenuLayout = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <DPALayout3>
          <Component {...props} />
        </DPALayout3>
      )}
    />
  );
};





const FreeLayout = ({ component: Component, children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {/* Render children or pass props to the Component */}
          {children ? children : <Component {...props} />}
        </>
      )}
    />
  );
};




export { MainLayout, SBALayout, SBASubmitLayout, MegamenuLayout, CRMLayout, FreeLayout };
