import { Typography, Box, IconButton } from '@material-ui/core';
import React from 'react';
import LinkedInIcon from "@material-ui/icons/LinkedIn"


import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';


export default function EmployeeProfile({ imgSrc, name, designation, company, linkedinUrl }) {



    return (
        <>
            <Box id='EmployeeProfile'>

                <List>
                    <ListItem>
                        <ListItemAvatar>
                            <Box className='teama' style={{ backgroundImage: `url(${imgSrc})` }}></Box>
                        </ListItemAvatar>
                        <ListItemText
                            primary={<>
                                <Typography variant='h3' className='teaminfo11 blue al_left mb0 h3h4' gutterBottom>
                                    {name}
                                </Typography>
                                <Box className='line'></Box>
                            </>}

                            secondary={<>
                                <Box my={1}>
                                    <Typography variant='h6' className='black al_left mb0' gutterBottom>
                                        <strong>{designation}</strong> <br />
                                        {company}
                                    </Typography>
                                </Box>
                                <Box>
                                    <a href={linkedinUrl} target='_blank' rel="noopener noreferrer">
                                        <IconButton aria-label="delete" size='medium' className='linkedInicon'>
                                            <LinkedInIcon fontSize="small" className='blue' />
                                        </IconButton>
                                    </a>
                                </Box>
                            </>} />
                    </ListItem>
                </List>

            </Box>
        </>
    );
}