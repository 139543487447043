import React, { useEffect } from "react";

import {
  AppBar,
  Divider,
  IconButton,
  ListItemAvatar,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Container,
  Grid,
  Tabs,
  Tab,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Checkbox
} from "@material-ui/core";
import PropTypes from "prop-types";
import Aos from "aos";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk";
import RoomIcon from "@material-ui/icons/Room";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import LoadingButton from "@mui/lab/LoadingButton";
import flags from 'react-phone-number-input/flags'
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import { Alert as MuiAlert } from "@material-ui/lab";
import { Helmet } from 'react-helmet';
import mumbai from "../asset/images/index/mumbai.svg";
import nashik from "../asset/images/index/nashik.svg";
import gift from "../asset/images/index/gift.svg";
import us from "../asset/images/index/us.svg";
import uk from "../asset/images/index/uk.svg";
import { contactApi } from "../Component/api/api";
import CustomePhoneNumber from "../Component/CustomePhoneNumber_Not_Mandatory";

import "aos/dist/aos.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../Style.css";
import "react-toastify/dist/ReactToastify.css";
import 'react-phone-number-input/style.css';

const usePlaceholderStyles = makeStyles(theme => ({
  placeholder: {
    color: "#aaa"
  }
}));

const Placeholder = ({ children }) => {
  const classes = usePlaceholderStyles();
  return <div className={classes.placeholder}>{children}</div>;
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

export default function Contactus() {
  const [value, setValue] = React.useState("Business Inquiry");
  const [tab, setTab] = React.useState(0);
  const [phoneValueError, setPhoneValueError] = React.useState();
  const [phoneValue1, setPhoneValue1] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const nameRegexExp = /^[A-Za-z\s]+$/;
  const indiaPhoneRegex = /^[6-9]\d{9}$/;

  const emailreg =
    /^[a-zA-Z0-9_]+(?:[-._][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+(?:[-.][a-zA-Z0-9_]+)*\.[a-zA-Z]{2,}$/;
  const organizationRegex = /^[a-zA-Z0-9 ,-./!@#$%^&*~]*$/;
  const jobtitleRegex = /^[a-zA-Z0-9 ,-./!@#$%^&*~]+$/;
  const schema = yup.object({
    firstname: yup
      .string()
      .required("First name is required")
      .typeError("Required feild")
      .matches(nameRegexExp, "Enter valid first name"),
    lastname: yup
      .string()
      .required("Last name is required")
      .typeError("Required feild")
      .matches(nameRegexExp, "Enter valid last name"),
    organisation: yup
      .string()
      .required("Organization is required")
      .matches(organizationRegex, "Enter valid organization name")
      .typeError("Required feild")
    ,
    jobtitle: yup
      .string()
      .required("Job title is required")
      .typeError("Required feild")
      .matches(jobtitleRegex, "Enter valid job title")
    ,
    email: yup
      .string()
      .required("Email is required")
      .typeError("Required feild")
      .matches(emailreg, "Email is not valid")
    ,

    hear_aboutus: yup
      .string()
      .typeError("Required feild")
      .required("Required field"),
    tandc: yup.bool(),
    tandce: yup.bool().oneOf([true], "You must accept the terms and conditions")
  });

  const formOptions = {
    mode: "onBlur",
    resolver: yupResolver(schema),
  };

  const { register, control, formState, handleSubmit } = useForm(formOptions);
  const { errors, dirtyFields } = formState;
  const history = useHistory();
  const firstName = React.useRef();
  const lastName = React.useRef();
  const email = React.useRef();
  const organisation = React.useRef();
  const jobtitle = React.useRef();
  const message = React.useRef();
  const [hearAboutUs, setHearaboutUS] = React.useState();



  React.useEffect(() => {
    const fetchIfaEmployees = () => {

      const hearabout = ["Search Engine",
        "Google",
        "Facebook",
        "Youtube",
        "Other paid social media advertising",
        "Facebook post/group",
        "Twitter post",
        "Instagram post/story",
        "Other social media",
        "Email",
        "Radio",
        "TV",
        "Newspaper",
        "Word of mouth",
        "Other"];
      const menuItem = hearabout.map((d) => {
        return (
          <MenuItem key={d} value={d}>
            <>{d}</>
          </MenuItem>
        );
      });
      setHearaboutUS(menuItem);

    };


    fetchIfaEmployees();
  }, []);

  const isPhoneValid = () => {
    if (!phoneValue1) {
      setPhoneValueError("");
      return;
    }

    var phoneValue2 = phoneValue1.includes("+91") ? phoneValue1.substring(3) : phoneValue1;
    if (phoneValue2.trim() === "") {
      setPhoneValueError("Enter phone number");
      return;
    }

    if (isValidPhoneNumber(phoneValue1)) {
      if (phoneValue1.includes("+91")) {
        if (!indiaPhoneRegex.test(phoneValue2)) {
          setPhoneValueError("Invalid phone number");
          return;
        }
      }
      setPhoneValue1(phoneValue1);
      setPhoneValueError("");
    } else {
      setPhoneValueError("Invalid phone number");
    }
  };

  const onSubmit = (data) => {

    if (phoneValue1) {
      if (isValidPhoneNumber(phoneValue1)) {
        if (phoneValue1.includes("+91")) {
          var phoneValue2 = parseInt(phoneValue1.substring(3));
          if (!indiaPhoneRegex.test(phoneValue2)) {
            setPhoneValueError("Invalid phone number");
            return;
          }
        }
        setPhoneValue1(phoneValue1);
        setPhoneValueError("");
      } else {
        setPhoneValueError("Invalid phone number");
        return;
      }
    }


    let tac = false;
    if (data.tandc) {
      tac = true;
    }
    else {
      tac = false;
    }

    const formData = {
      first_name: data.firstname,
      last_name: data.lastname,
      contact_no: phoneValue1,
      message: message.current.value,
      email: data.email,
      job_title: data.jobtitle,
      reason: value,
      organization: data.organisation,
      hear_aboutus: data.hear_aboutus,
      obtain_email_flag: tac
    };


    if (!(data.tandce)) {
      alert("You must accept the terms and conditions");
      return
    }
    handleFormSubmit(formData);
  };

  const post = async (formData) => {
    setLoading(true);
    const message = "Oops, something went wrong, Please try again leter.";
    try {
      const { data, status } = await contactApi(formData);
      if (status === 200) {
        if (data.status === true) {

          setValue("");
          history.push("/form-submit");
        } else {

          toast.error(message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

        }
      }
    } catch (error) {

      toast.error(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setLoading(false);
  };



  const handleFormSubmit = (data) => {
    post(data);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <>

      <Helmet>
        <meta charset="UTF-8" />
        <title>Contact Us | Decimal Point Analytics</title>
        <meta name="description" content="Harness the Power of Data: Transform Your Financial Strategy with our Analytics and Research Solutions in the US. Decimal Point Analytics. Get In Touch Now!" />
        <meta name="keywords" content="artificial intelligence solutions company
Private companies ESG data provider
Staff Augmentation
staff outsourcing solutions
business intelligence analytics,
market research company in US," />
        <meta name="author" content="Decimal Point Analytics" />
        <link rel="canonical" href="https://www.decimalpointanalytics.com/contact-us" />

        <meta name="viewport" content="width=device-width, initial-scale=1.0" />


        <script type="application/ld+json">
          {`
     {
      "@context": "https://schema.org",
    "@type": "Corporation",
    "name": "Decimal Point Analytics",
    "url": "https://www.decimalpointanalytics.com/",
    "logo": "https://www.decimalpointanalytics.com/static/media/dpa_main_logo.df6414e47945efb8229be45724f5238d.svg",
    "contactPoint": [{
      "@type": "ContactPoint",
    "telephone": "+1 917 720 3456",
    "contactType": "customer service",
    "areaServed": "US",
    "availableLanguage": "en"
},{
      "@type": "ContactPoint",
    "telephone": "+44 20 3286 1998",
    "contactType": "customer service",
    "areaServed": "GB",
    "availableLanguage": "en"
},{
      "@type": "ContactPoint",
    "telephone": "+91 99670 66333",
    "contactType": "customer service",
    "areaServed": "IN",
    "availableLanguage": "en"
},{
      "@type": "ContactPoint",
    "telephone": "+91 88056 99353",
    "contactType": "customer service",
    "areaServed": "IN",
    "availableLanguage": "en"
},{
      "@type": "ContactPoint",
    "telephone": "+91 99670 66333",
    "contactType": "customer service",
    "areaServed": "IN",
    "availableLanguage": "en"
}],
    "sameAs": [
    "https://www.linkedin.com/company/decimalpointanalytics/",
    "https://twitter.com/DecimalP",
    "https://www.instagram.com/decimalpointanalytics/",
    "https://www.youtube.com/c/DecimalPointAnalytics"
    ]
}       
          `}
        </script>





      </Helmet>

      <Box id="contact_form" className="section2 sec_bg" component="section">
        <Container maxWidth="lg" className="bdr">
          <Grid container spacing={1} alignItems="top">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className="al_center" pt={5}>
                <Typography variant="h1" className="black newh1">
                  Get in touch with us
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Box className="whitebx" mt={2} mb={5}>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Box className="bluebg">
                  <Box mb={1}>
                    <Typography variant="h4" className="white jst">
                      Select Reason *
                    </Typography>
                  </Box>
                  <Box className="reason">
                    <FormControl component="fieldset">
                      <RadioGroup

                        aria-label="reason"
                        name="reason1"
                        value={value}
                        defaultValue="Business Inquiry"
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="Business Inquiry"
                          control={<Radio />}
                          label="Business Inquiry"
                          color="primary"

                        />
                        <FormControlLabel
                          value="Alliances & Partnerships"
                          control={<Radio />}
                          label="Alliances & Partnerships"
                        />
                        <FormControlLabel
                          value="Analysts & Media Relationships"
                          control={<Radio />}
                          label="Analysts & Media Relationships"
                        />
                        <FormControlLabel
                          value="Career Enquiry"
                          control={<Radio />}
                          label="Career Enquiry"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Box>
              </Grid>

              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Box className="alltxfieldlg ">
                        <TextField
                          id="filled-basic1"
                          label="First Name"
                          variant="standard"
                          fullWidth
                          name="firstname"
                          inputRef={firstName}
                          required
                          {...register("firstname", { required: true })}
                          error={!!errors?.firstname}
                          helperText={errors?.firstname?.message}
                        />
                      </Box>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Box className="alltxfieldlg ">
                        <TextField
                          id="filled-basic2"
                          label="Last Name"
                          variant="standard"
                          fullWidth
                          name="lastname"
                          inputRef={lastName}
                          required
                          {...register("lastname", { required: true })}
                          error={!!errors?.lastname}
                          helperText={errors?.lastname?.message}
                        />
                      </Box>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Box className="alltxfieldlg ">
                        <TextField
                          id="filled-basic3"
                          label="Organization Name"
                          variant="standard"
                          fullWidth
                          name="Organization Name"
                          inputRef={organisation}
                          required
                          {...register("organisation", { required: true })}
                          error={!!errors?.organisation}
                          helperText={errors?.organisation?.message}
                        />
                      </Box>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Box className="alltxfieldlg ">
                        <TextField
                          id="filled-basic4"
                          label="Job Title"
                          variant="standard"
                          fullWidth
                          name="jobtitle"
                          inputRef={jobtitle}
                          required
                          {...register("jobtitle", { required: true })}
                          error={!!errors?.jobtitle}
                          helperText={errors?.jobtitle?.message}
                        />
                      </Box>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Box className="alltxfieldlg " >
                        <TextField
                          id="filled-basic5"
                          label="Email Address"
                          variant="standard"
                          fullWidth
                          name="email"
                          inputRef={email}
                          required
                          {...register("email", {
                            required: true,
                            pattern:
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          })}
                          error={!!errors?.email}
                          helperText={errors?.email?.message}
                        />
                      </Box>
                    </Grid>


                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Box className="alltxfieldlg " mt={2}>

                        <PhoneInput
                          flags={flags}
                          international
                          name="phoneInput"
                          placeholder='Enter phone number'
                          value={phoneValue1}
                          onChange={setPhoneValue1}
                          inputComponent={CustomePhoneNumber}

                        />



                      </Box>
                      <Typography style={{ color: "red", fontSize: "13px" }}>{phoneValueError}</Typography>
                    </Grid>


                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box className="alltxfieldlg " mt={2}>
                        <FormControl variant="standard" fullWidth>
                          <Controller
                            control={control}
                            name="hear_aboutus"
                            defaultValue={""}
                            label="user role"
                            required
                            placeholder="Hear Aount us?"
                            render={({ field, field: { value } }) => (
                              <>
                                <Select
                                  {...field}
                                  displayEmpty
                                  error={!!errors?.hear_aboutus}
                                  placeholder="Select Role"
                                  renderValue={value !== "" ? undefined : () => <Placeholder>How did you hear about us? *</Placeholder>}
                                >
                                  <MenuItem value="" selected></MenuItem>
                                  {hearAboutUs}
                                </Select>
                                <Typography style={{ color: "red", fontSize: "13px" }}>{errors?.hear_aboutus?.message}</Typography>


                              </>
                            )}
                          />
                        </FormControl>
                      </Box>
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box className="alltxfieldlg ">
                        <TextField
                          id="filled-basic6"
                          label="Your Message"
                          variant="standard"
                          fullWidth
                          inputRef={message}
                          multiline
                          rows={4}
                        />
                      </Box>
                    </Grid>


                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box className="alltxfieldlg chkbx" >
                        <FormControlLabel
                          control={
                            <Checkbox
                              defaultChecked={true}
                              name="checkedB"
                              color="primary"
                              size="small"
                              {...register("tandc")}
                              error={!!errors?.tandc}

                            />
                          }
                          label="Subscribe to our Newsletter"
                        />
                      </Box>

                      <Box className="alltxfieldlg chkbx" >

                        <FormControlLabel
                          control={
                            <Checkbox
                              defaultChecked={true}
                              name="checkedB"
                              color="primary"
                              size="small"
                              {...register("tandce")}
                              error={!!errors?.tandce}

                            />
                          }
                          label={<Typography className="label2">By clicking submit, I agree to Decimal Point Analytics <Link to="/terms">Terms of Use</Link> and <Link to="/policy">Privacy Policy</Link>
                          </Typography>}

                        />



                      </Box>

                      <Box className="checkbx-error">
                        {dirtyFields.tandce && errors.tandce && (
                          <MuiAlert severity="error">You must accept the terms and conditions</MuiAlert>
                        )}
                      </Box>
                    </Grid>


                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box className="al_center" mt={3}>
                        {loading ? (
                          <LoadingButton
                            loading={loading}
                            variant="outlined"
                            disabled
                          >
                            disabled
                          </LoadingButton>
                        ) : (
                          <Button
                            onClick={() => { handleSubmit(onSubmit)(); isPhoneValid() }}
                            variant="contained"
                            className="dpabtn_1"
                          >
                            Submit
                          </Button>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Grid container spacing={1} alignItems="top">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className="al_center">
                <Typography variant="h2" className="black">
                  Our Offices
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Box className="maintabbedsection location">
            <Grid container spacing={1} alignItems="top">
              <Grid item lg={10} md={10} sm={12} xs={12}>
                <AppBar position="static" className="tabbedbar">
                  <Tabs
                    value={tab}
                    onChange={handleChangeTab}
                    indicatorColor="none"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                  >
                    <Tab label="U.S.A" {...a11yProps(0)} />
                    <Tab label="UK" {...a11yProps(1)} />
                    <Tab label="Mumbai" {...a11yProps(2)} />
                    <Tab label="Nashik" {...a11yProps(3)} />
                    <Tab label="GIFT City" {...a11yProps(4)} />
                  </Tabs>
                </AppBar>
              </Grid>
            </Grid>
          </Box>

          <Box className="whitebx" mb={5}>
            <Grid container spacing={1}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TabPanel value={tab} index={0} className="padd">
                  <Box>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item lg={8} md={8} sm={6} xs={12}>
                        <Box className="al_left">
                          <Typography variant="h4" className="black">
                            USA: <span className="blue">New York</span>
                          </Typography>
                          <Divider />
                          <Box mt={2}>
                            <Typography variant="h6" className="black">
                              17 State Street, Suite 4000, New York, NY 10004
                              U.S.A
                            </Typography>
                          </Box>

                          <Box className="addinfo">
                            <List>
                              <ListItem>
                                <ListItemAvatar>
                                  <a href="mailto:info@decimalpointanalytics.com">
                                    <IconButton aria-label="delete" size="small">
                                      <MailOutlineIcon />
                                    </IconButton>
                                  </a>
                                </ListItemAvatar>
                                <ListItemText primary="Email" secondary={<a href="mailto:info@decimalpointanalytics.com" className="black">info@decimalpointanalytics.com</a>} />
                              </ListItem>

                              <ListItem>
                                <ListItemAvatar>
                                  <IconButton aria-label="delete" size="small">
                                    <PhoneInTalkIcon />
                                  </IconButton>
                                </ListItemAvatar>
                                <ListItemText primary="General Queries" secondary={<a href="tel:+19177203456" className="black">+1 917 720 3456</a>} />
                              </ListItem>

                              <ListItem>
                                <ListItemAvatar>
                                  <a
                                    href="https://goo.gl/maps/Vta1rvR39vWFt7Du9"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <IconButton
                                      aria-label="delete"
                                      size="small"
                                    >
                                      <RoomIcon />
                                    </IconButton>
                                  </a>
                                </ListItemAvatar>
                                {/* <ListItemText secondary="Location" /> */}
                                <ListItemText secondary={<a href="https://goo.gl/maps/Vta1rvR39vWFt7Du9" target="_blank"
                                  rel="noopener noreferrer" className="black">Location</a>} />
                              </ListItem>
                            </List>
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Box className="al_right">
                          <img src={us} className="locicon" alt="US" />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </TabPanel>

                <TabPanel value={tab} index={1} className="padd">
                  <Box>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item lg={8} md={8} sm={6} xs={12}>
                        <Box className="al_left">
                          <Typography variant="h4" className="black">
                            United Kingdom: <span className="blue">London</span>
                          </Typography>
                          <Divider />
                          <Box mt={2}>
                            <Typography variant="h6" className="black">
                              Jhumat House, 160 London Road, Barking, IG11 8BB
                            </Typography>
                          </Box>

                          <Box className="addinfo">
                            <List>
                              <ListItem>
                                <ListItemAvatar>
                                  <a href="mailto:info@decimalpointanalytics.com">
                                    <IconButton aria-label="delete" size="small">
                                      <MailOutlineIcon />
                                    </IconButton>
                                  </a>
                                </ListItemAvatar>
                                <ListItemText primary="Email" secondary={<a href="mailto:info@decimalpointanalytics.com" className="black">info@decimalpointanalytics.com</a>} />
                              </ListItem>

                              <ListItem>
                                <ListItemAvatar>
                                  <IconButton aria-label="delete" size="small">
                                    <PhoneInTalkIcon />
                                  </IconButton>
                                </ListItemAvatar>
                                <ListItemText primary="General Queries" secondary={<a href="tel:+442032861998" className="black">+44 20 3286 1998</a>} />
                              </ListItem>

                              <ListItem>
                                <ListItemAvatar>
                                  <a
                                    href="https://goo.gl/maps/LTRpKdupmvewJTjC9"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <IconButton
                                      aria-label="delete"
                                      size="small"
                                    >
                                      <RoomIcon />
                                    </IconButton>
                                  </a>
                                </ListItemAvatar>
                                <ListItemText secondary={<a href="https://goo.gl/maps/LTRpKdupmvewJTjC9" target="_blank"
                                  rel="noopener noreferrer" className="black">Location</a>} />
                              </ListItem>
                            </List>
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Box className="al_right">
                          <img src={uk} className="locicon" alt="UK" />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </TabPanel>

                <TabPanel value={tab} index={2} className="padd">
                  <Box>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item lg={8} md={8} sm={6} xs={12}>

                        <Grid container spacing={0} alignItems="top">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_left">
                              <Typography variant="h4" className="black">
                                India: <span className="blue">Mumbai</span>
                              </Typography>
                              <Divider />
                              <Box mt={2}>
                                <Typography variant="h6" className="black">
                                  5A, B-Wing, Trade Star Building, J. B. Nagar,
                                  Andheri (East), Mumbai - 400
                                  059, Maharashtra, India
                                </Typography>
                              </Box>

                            </Box>
                          </Grid>
                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Box className="addinfo">
                              <List>
                                <ListItem>
                                  <ListItemAvatar>
                                    <a href="mailto:info@decimalpointanalytics.com">
                                      <IconButton aria-label="delete" size="small">
                                        <MailOutlineIcon />
                                      </IconButton>
                                    </a>
                                  </ListItemAvatar>
                                  <ListItemText primary="Email" secondary={<a href="mailto:info@decimalpointanalytics.com" className="black">info@decimalpointanalytics.com</a>} />
                                </ListItem>


                                <ListItem>
                                  <ListItemAvatar>

                                    <IconButton aria-label="delete" size="small">
                                      <WorkOutlineOutlinedIcon />
                                    </IconButton>

                                  </ListItemAvatar>
                                  <ListItemText primary="Placement Queries" secondary={<a href="tel:+919029279601" className="black">+91 90292 79601</a>} />
                                </ListItem>


                                <ListItem>

                                  <ListItemAvatar>

                                    <IconButton
                                      aria-label="delete"
                                      size="small"
                                    >
                                      <BusinessCenterOutlinedIcon />
                                    </IconButton>

                                  </ListItemAvatar>
                                  <ListItemText primary="Marketing Queries" secondary={<a href="mailto:marketing@decimalpointanalytics.com" className="black">marketing@decimalpointanalytics.com</a>} />

                                </ListItem>


                              </List>
                            </Box>
                          </Grid>
                          <Grid item lg={6} md={6} sm={12} xs={12}>

                            <Box className="addinfo">
                              <List>

                                <ListItem>
                                  <ListItemAvatar>
                                    <IconButton aria-label="delete" size="small">
                                      <PhoneInTalkIcon />
                                    </IconButton>
                                  </ListItemAvatar>
                                  <ListItemText primary="Other Queries" secondary={<a href="tel:+918793212456" className="black">+91 87932 12456</a>} />
                                </ListItem>


                                <ListItem>
                                  <ListItemAvatar>
                                    <a
                                      href="https://goo.gl/maps/ZtAdhzPGb68gwkUC9"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <IconButton
                                        aria-label="delete"
                                        size="small"
                                      >
                                        <RoomIcon />
                                      </IconButton>
                                    </a>
                                  </ListItemAvatar>
                                  <ListItemText secondary={<a href="https://goo.gl/maps/ZtAdhzPGb68gwkUC9" target="_blank"
                                    rel="noopener noreferrer" className="black">Location</a>} />
                                </ListItem>


                              </List>
                            </Box>


                          </Grid>
                        </Grid>


                      </Grid>

                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Box className="al_right">
                          <img src={mumbai} className="locicon" alt="MUMBAi" />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </TabPanel>

                <TabPanel value={tab} index={3} className="padd">
                  <Box>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item lg={8} md={8} sm={6} xs={12}>


                        <Grid container spacing={0} alignItems="top">
                          <Grid item lg={12} md={12} sm={12} xs={12}>

                            <Box className="al_left">
                              <Typography variant="h4" className="black">
                                India: <span className="blue">Nashik</span>
                              </Typography>
                              <Divider />
                              <Box mt={2}>
                                <Typography variant="h6" className="black">
                                  7th Floor, Roongta Supremus, Nashik, 422 002 India
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>


                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Box className="addinfo">
                              <List>
                                <ListItem>
                                  <ListItemAvatar>
                                    <a href="mailto:info@decimalpointanalytics.com">
                                      <IconButton aria-label="delete" size="small">
                                        <MailOutlineIcon />
                                      </IconButton>
                                    </a>
                                  </ListItemAvatar>
                                  <ListItemText primary="Email" secondary={<a href="mailto:info@decimalpointanalytics.com" className="black">info@decimalpointanalytics.com</a>} />
                                </ListItem>

                                <ListItem>
                                  <ListItemAvatar>

                                    <IconButton aria-label="delete" size="small">
                                      <WorkOutlineOutlinedIcon />
                                    </IconButton>

                                  </ListItemAvatar>
                                  <ListItemText primary="Placement Queries" secondary={<a href="tel:+918767714207" className="black">+91 87677 14207</a>} />
                                </ListItem>


                                <ListItem>

                                  <ListItemAvatar>

                                    <IconButton
                                      aria-label="delete"
                                      size="small"
                                    >
                                      <BusinessCenterOutlinedIcon />
                                    </IconButton>

                                  </ListItemAvatar>
                                  <ListItemText primary="Marketing Queries" secondary={<a href="mailto:marketing@decimalpointanalytics.com" className="black">marketing@decimalpointanalytics.com</a>} />

                                </ListItem>



                              </List>
                            </Box>
                          </Grid>

                          <Grid item lg={6} md={6} sm={12} xs={12}>

                            <Box className="addinfo">
                              <List>

                                <ListItem>
                                  <ListItemAvatar>
                                    <IconButton aria-label="delete" size="small">
                                      <PhoneInTalkIcon />
                                    </IconButton>
                                  </ListItemAvatar>
                                  <ListItemText primary="Other Queries" secondary={<a href="tel:+918805699353" className="black">+91 88056 99353</a>} />
                                </ListItem>


                                <ListItem>
                                  <ListItemAvatar>
                                    <a
                                      href="https://goo.gl/maps/sk4VqMrnvNj7DpsW9"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <IconButton
                                        aria-label="delete"
                                        size="small"
                                      >
                                        <RoomIcon />
                                      </IconButton>
                                    </a>
                                  </ListItemAvatar>
                                  <ListItemText secondary={<a href="https://goo.gl/maps/sk4VqMrnvNj7DpsW9" target="_blank"
                                    rel="noopener noreferrer" className="black">Location</a>} />
                                </ListItem>



                              </List>
                            </Box>


                          </Grid>


                        </Grid>
                      </Grid>

                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Box className="al_right">
                          <img src={nashik} className="locicon" alt="NSk" />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </TabPanel>

                <TabPanel value={tab} index={4} className="padd">
                  <Box>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item lg={8} md={8} sm={6} xs={12}>
                        <Grid container spacing={0} alignItems="top">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_left">
                              <Typography variant="h4" className="black">
                                India: <span className="blue">GIFT City</span>
                              </Typography>
                              <Divider />
                              <Box mt={2}>
                                <Typography variant="h6" className="black">
                                  D-601, World Trade Center, GIFT City, Gujarat,
                                  382355, India
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>

                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Box className="addinfo">
                              <List>
                                <ListItem>
                                  <ListItemAvatar>
                                    <a href="mailto:info@decimalpointanalytics.com">
                                      <IconButton aria-label="delete" size="small">
                                        <MailOutlineIcon />
                                      </IconButton>
                                    </a>
                                  </ListItemAvatar>
                                  <ListItemText primary="Email" secondary={<a href="mailto:info@decimalpointanalytics.com" className="black">info@decimalpointanalytics.com</a>} />
                                </ListItem>


                                <ListItem>
                                  <ListItemAvatar>

                                    <IconButton aria-label="delete" size="small">
                                      <WorkOutlineOutlinedIcon />
                                    </IconButton>

                                  </ListItemAvatar>
                                  <ListItemText primary="Placement Queries" secondary={<a href="tel:+919672900303" className="black">+91 96729 00303</a>} />
                                </ListItem>


                                <ListItem>
                                  <ListItemAvatar>
                                    <IconButton
                                      aria-label="delete"
                                      size="small"
                                    >
                                      <BusinessCenterOutlinedIcon />
                                    </IconButton>
                                  </ListItemAvatar>
                                  <ListItemText primary="Marketing Queries" secondary={<a href="mailto:marketing@decimalpointanalytics.com" className="black">marketing@decimalpointanalytics.com</a>} />
                                </ListItem>


                              </List>
                            </Box>
                          </Grid>


                          <Grid item lg={6} md={6} sm={12} xs={12}>

                            <Box className="addinfo">
                              <List>


                                <ListItem>
                                  <ListItemAvatar>
                                    <IconButton aria-label="delete" size="small">
                                      <PhoneInTalkIcon />
                                    </IconButton>
                                  </ListItemAvatar>
                                  <ListItemText primary="Other Queries" secondary={<a href="tel:+919712453631" className="black">+91 97124 53631</a>} />
                                </ListItem>


                                <ListItem>
                                  <ListItemAvatar>
                                    <a
                                      href="https://goo.gl/maps/mWjAeMpYofB7rcJQ9"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <IconButton
                                        aria-label="delete"
                                        size="small"
                                      >
                                        <RoomIcon />
                                      </IconButton>
                                    </a>
                                  </ListItemAvatar>
                                  <ListItemText secondary={<a href="https://goo.gl/maps/mWjAeMpYofB7rcJQ9" target="_blank"
                                    rel="noopener noreferrer" className="black">Location</a>} />
                                </ListItem>



                              </List>
                            </Box>


                          </Grid>


                        </Grid>
                      </Grid>

                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Box className="al_right">
                          <img src={gift} className="locicon" alt="GIFT" />
                        </Box>
                      </Grid>



                      <Grid item lg={8} md={8} sm={6} xs={12}>
                        <Grid container spacing={0} alignItems="top">

                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Divider />
                          </Grid>

                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_left">

                              <Box mt={2}>
                                <Typography variant="h6" className="blue">
                                  <strong>DPA SMARTCLOUD IFSC PRIVATE LIMITED</strong>
                                </Typography>
                              </Box>

                              <Box mt={2}>
                                <Typography variant="h6" className="black">
                                  Unit No. 701 Signature, Eleventh Floor, Block 13B, Zone - 1, Gandhinagar,
                                  <br />GIFT SEZ, Gandhinagar, Gujarat, India, 382355
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>

                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Box className="addinfo">
                              <List>
                                <ListItem>
                                  <ListItemAvatar>
                                    <a href="mailto:info@dpasmartcloudifsc.com">
                                      <IconButton aria-label="delete" size="small">
                                        <MailOutlineIcon />
                                      </IconButton>
                                    </a>
                                  </ListItemAvatar>
                                  <ListItemText primary="Email" secondary={<a href="mailto:info@dpasmartcloudifsc.com" className="black">info@dpasmartcloudifsc.com</a>} />
                                </ListItem>

                                <ListItem>
                                  <ListItemAvatar>
                                    <IconButton aria-label="delete" size="small">
                                      <PhoneInTalkIcon />
                                    </IconButton>
                                  </ListItemAvatar>
                                  <ListItemText primary="Other Queries" secondary={<a href="tel:+919898997300" className="black">+91 98989 97300</a>} />
                                </ListItem>

                              </List>
                            </Box>
                          </Grid>

                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Box className="addinfo">
                              <List>
                                <ListItem>
                                  <ListItemAvatar>
                                    <a
                                      href="https://maps.app.goo.gl/BYgf68KZkanHsNtZA"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <IconButton
                                        aria-label="delete"
                                        size="small"
                                      >
                                        <RoomIcon />
                                      </IconButton>
                                    </a>
                                  </ListItemAvatar>
                                  <ListItemText secondary={<a href="https://maps.app.goo.gl/BYgf68KZkanHsNtZA" target="_blank"
                                    rel="noopener noreferrer" className="black">Location</a>} />
                                </ListItem>
                              </List>
                            </Box>
                          </Grid>
                        </Grid>

                      </Grid>

                    </Grid>
                  </Box>
                </TabPanel>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
}
