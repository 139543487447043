import { TextField, Button, Grid, Box, } from "@material-ui/core";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { esgFormApi } from "../api/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



export default function ViewFullWhitepaper(props) {

    const schema = yup.object().shape({
        name: yup.string().typeError("Required field").required("Name is required"),
        email: yup.string().typeError("Required field").required("Email is required").email().max(75),
        company: yup.string().typeError("Required field").required("Company name is required"),
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    let report_type = "Whitepaper";
    try {
        report_type = props.report_type;
    } catch (e) {
    }


    // to download file directly in folder
    const onSubmit = async (data) => {
        const payload = {
            email: data.email,
            report_for: props.reportby,
            report_type: report_type,
        };

        if (data.name.trim() !== "") {
            payload.name = data.name;
        }

        if (data.company.trim() !== "") {
            payload.company = data.company;
        }

        const d1 = await esgFormApi(payload);
        if (d1.data.status === true) {
            try {
                // Fetch the file from the provided link
                const fileResponse = await fetch(props.link.toString());

                // Check if the fetch was successful
                if (!fileResponse.ok) {
                    console.error('Failed to fetch the file. Status:', fileResponse.status);
                    throw new Error('Failed to fetch the file');
                }

                // Extract the file name from the Content-Disposition header
                const contentDisposition = fileResponse.headers.get("Content-Disposition");
                let fileName;

                if (contentDisposition) {
                    const fileNameMatch = contentDisposition.match(/filename\*?=['"]?([^;'"]+)/);
                    if (fileNameMatch) {
                        fileName = decodeURIComponent(fileNameMatch[1].replace(/_/g, " ")); // Decode and replace underscores with spaces
                    } else {
                        fileName = "report.pdf"; // Fallback name
                    }
                } else {
                    // Extract the file name from the URL if Content-Disposition is missing
                    const urlParts = props.link.split("/");
                    fileName = urlParts[urlParts.length - 1].split("?")[0] || "report.pdf"; // Remove query params if any
                    fileName = decodeURIComponent(fileName.replace(/_/g, " ")); // Decode and replace underscores with spaces
                }

                // Create a Blob from the file response
                const fileBlob = await fileResponse.blob();

                // Create an object URL for the Blob
                const fileURL = URL.createObjectURL(fileBlob);

                // Create a hidden <a> element to trigger the download
                const link = document.createElement("a");
                link.href = fileURL;
                link.download = fileName; // Use the determined file name
                link.style.display = 'none'; // Hide the link element
                document.body.appendChild(link); // Append it to the DOM
                link.click(); // Trigger the download

                // Clean up by revoking the object URL and removing the link element
                URL.revokeObjectURL(fileURL);
                document.body.removeChild(link);
            } catch (error) {
                console.error('Error during file download:', error);
                toast.error("Something went wrong while downloading the file.");
            }
        } else {
            toast.error("Please Enter Valid details before Downloading", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };



    // const onSubmit = async (data) => {
    //     const payload = {
    //         email: data.email,
    //         report_for: props.reportby,
    //         report_type: report_type,
    //     };

    //     if (data.name.trim() !== "") {
    //         payload.name = data.name;
    //     }

    //     if (data.company.trim() !== "") {
    //         payload.company = data.company;
    //     }

    //     try {
    //         const response = await esgFormApi(payload);

    //         if (response.data.status === true) {
    //             const fileName = props.fileName || `${props.reportType}_Report.pdf`; 

    //             console.log('File URL...................:', props.link);  
    //             console.log('File Name......................:', fileName); 

    //             const fileResponse = await fetch(props.link.toString());

    //             if (!fileResponse.ok) {
    //                 console.error('Failed to fetch the file. Status:', fileResponse.status);
    //                 throw new Error('Failed to fetch the file');
    //             }

    //             const fileBlob = await fileResponse.blob();
    //             const fileURL = URL.createObjectURL(fileBlob);


    //             console.log('File Blob URL:', fileBlob);

    //             const link = document.createElement("a");
    //             link.href = fileURL;
    //             link.style.display = 'none';
    //             document.body.appendChild(link); 
    //             link.click(); 


    //             URL.revokeObjectURL(fileURL);
    //             document.body.removeChild(link);


    //             console.log('Download triggered for:', fileName);

    //         } else {
    //             toast.error("Please Enter Valid details before Downloading", {
    //                 position: "top-center",
    //                 autoClose: 2000,
    //                 hideProgressBar: false,
    //                 closeOnClick: true,
    //                 pauseOnHover: true,
    //                 draggable: true,
    //                 progress: undefined,
    //             });
    //         }
    //     } catch (error) {
    //         console.error('Error during file download:', error);
    //         toast.error("Something went wrong, please try again later.");
    //     }
    // };



    return (
        <>
            <Box>
                <Grid container spacing={2}>

                    {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box>
                            <Typography variant="h4" className="blue jst">
                                Learn More...
                            </Typography>
                        </Box>
                    </Grid> */}

                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Box className="alltxfieldlg" mt={1}>
                            <Controller
                                name="name"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Name *"
                                        variant="standard"
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        error={!!errors.name}
                                        helperText={errors.name?.message}
                                    />
                                )}
                            />
                        </Box>
                    </Grid>

                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Box className="alltxfieldlg" mt={1}>
                            <Controller
                                name="email"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Email *"
                                        variant="standard"
                                        fullWidth
                                        error={!!errors.email}
                                        helperText={errors.email?.message}
                                    />
                                )}
                            />
                        </Box>
                    </Grid>

                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Box className="alltxfieldlg" mt={1}>
                            <Controller
                                name="company"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Company Name *"
                                        variant="standard"
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        error={!!errors.company}
                                        helperText={errors.company?.message}
                                    />
                                )}
                            />
                        </Box>
                    </Grid>

                    <Grid item lg={3} md={12} sm={6} xs={12}>
                        <Box className="al_center" mt={1}>
                            <Button variant="contained" className="dpabtn_2" type="submit" onClick={handleSubmit(onSubmit)}>
                                Download Now
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );

}
