import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import SmallBusiness from "../../asset/images/new_banner/small_business_banner.webp"

import ProductCTAA from '../../Component/ProductCTAA';
import whyCP from "../../asset/images/product/creditpulse/why_credit_pulse.webp"



import promise1 from "../../asset/images/innovation/sba/promise1.png";
import promise2 from "../../asset/images/innovation/sba/promise2.png";
import promise3 from "../../asset/images/innovation/sba/promise3.png";
import promise4 from "../../asset/images/innovation/sba/promise4.png";

import efficiency1 from "../../asset/images/innovation/sba/efficiency1.png";
import efficiency2 from "../../asset/images/innovation/sba/efficiency2.png";
import efficiency3 from "../../asset/images/innovation/sba/efficiency3.png";

import story1 from "../../asset/images/innovation/sba/story1.png";
import story2 from "../../asset/images/innovation/sba/story2.png";
import story3 from "../../asset/images/innovation/sba/story3.png";

import approach1 from "../../asset/images/innovation/sba/approach1.png";
import approach2 from "../../asset/images/innovation/sba/approach2.png";
import approach3 from "../../asset/images/innovation/sba/approach3.png";
import approach4 from "../../asset/images/innovation/sba/approach4.png";

import Service1 from "../../asset/images/innovation/sba/services/service1.png";
import Service2 from "../../asset/images/innovation/sba/services/service2.png";
import Service3 from "../../asset/images/innovation/sba/services/service3.png";
import Service4 from "../../asset/images/innovation/sba/services/service4.png";
import Service5 from "../../asset/images/innovation/sba/services/service5.png";
import Service6 from "../../asset/images/innovation/sba/services/service6.png";

import GetInTouch from '../../Component/Get_in_touch';

import banenr2 from "../../asset/images/innovation/sba/sba_services.webp";
import Services from "../../asset/images/innovation/sba/services.webp";
import SBA_Data from './SBA_Data';
import SBA_Success_Stories from './SBA_Success_Stories';
import SBA_Podcast from './SBA_Podcast';


import ForwardIcon from '@mui/icons-material/Forward';
import WritemessageSBA from '../../Component/WritemessageSBA';

export default function SmallBusinessAdministration() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <h1> SBA Loan Underwriting Process Outsource Services </h1>
                <title> SBA Loan Underwriting Process Outsource Services | DPA </title>
                <meta name="description" content="Outsource your SBA underwriting needs for efficiency and risk reduction. From pre-screening to post-closing, streamline your loan processes with DPA's expert support." />
                <meta name="keywords" content="Loan application, Loan processing, Efficiency, Automation, SBA loan journey" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/small-business-administration" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box className="banner_bx" mb={"2px"}>
                <Box style={{ backgroundImage: "url(" + SmallBusiness + ")" }} className="banner_img">
                    <Box className="img_over bl_overlay">
                        <Grid container spacing={0} alignItems="center" justifyContent='center'>
                            <Grid item lg={7} md={8} sm={10} xs={12}>
                                <Box className="al_center" mt={3}>
                                    <Typography className="white" variant="h1">Small Business Administration Lending Services</Typography>
                                    <Box mt={'10px'}>
                                        <Typography className="white" variant="h4">Streamline The  SBA Loan Process: Achieve Efficiency And Accuracy
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>

            <WritemessageSBA pageVisited="Small Business Administration Lending Services" />

            <Box className='section2 sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center">
                                <Box className='al_center'>
                                    <Typography variant='h2' className='black'>
                                        Efficient SBA Loan Outsourcing Services <span className='lower'>for</span> NBFCs <span className='lower'>and</span> SBA Lenders
                                    </Typography>
                                </Box>
                                <Typography variant='h6' className='black jst' gutterBottom>
                                    At Decimal Point Analytics, we specialize in providing comprehensive SBA loan outsourcing services to non-banking financial companies (NBFCs) and SBA lenders. Our dedicated team of experts assists lenders in streamlining their SBA loan processes, ensuring efficiency, accuracy, and compliance with regulatory requirements. Outsource your SBA loan processing tasks to Decimal Point Analytics and experience unmatched efficiency and accuracy.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            {/* ------------------------------------------------------------------------------------------------------ */}




            {/* ------------------------------------------------------------------------------------------------------ */}

            <Box id='sba_solutions' className='section mob_card' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container spacing={3} alignItems="center" justifyContent='center'>

                        <Grid container spacing={1} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='al_center'>
                                    <Typography variant='h2' className='black'>
                                        Our Promise
                                        <Typography variant='h2' className='black h2v2'>
                                            Efficiency, Clarity, and Support
                                        </Typography>
                                    </Typography>
                                </Box>

                            </Grid>
                        </Grid>

                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box className='boxbg mnhg4'>
                                <Box className='al_center'>
                                    <img src={promise1} className='allic' alt='OP1' />
                                </Box>
                                <Box>
                                    <Typography variant='h6' className='blue al_center fw6'>
                                        High-Quality Solutions
                                    </Typography>
                                    <Typography variant='h6' className='black al_center mb0'>
                                        We offer high-quality solutions tailored to meet the diverse needs of SBA lenders, ensuring efficiency, clarity, and unwavering support.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box className='boxbg mnhg4'>
                                <Box className='al_center'>
                                    <img src={promise2} className='allic' alt='OP2' />
                                </Box>
                                <Box>
                                    <Typography variant='h6' className='blue al_center fw6'>
                                        Swift Processing
                                    </Typography>
                                    <Typography variant='h6' className='black al_center mb0'>
                                        With our advanced technology and strategic leveraging of global time zones, we expedite loan application processing times, providing swift and efficient service to SBA lenders.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box className='boxbg mnhg4'>
                                <Box className='al_center'>
                                    <img src={promise3} className='allic' alt='OP3' />
                                </Box>
                                <Box>
                                    <Typography variant='h6' className='blue al_center fw6'>
                                        Expert Analysis
                                    </Typography>
                                    <Typography variant='h6' className='black al_center mb0'>
                                        Our seasoned credit team, skilled in risk and financial analysis, meticulously reviews your SBA loan application, ensuring it meets your specific needs and adheres to strict SBA guidelines.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box className='boxbg mnhg4'>
                                <Box className='al_center'>
                                    <img src={promise4} className='allic' alt='OP4' />
                                </Box>
                                <Box>
                                    <Typography variant='h6' className='blue al_center fw6'>
                                        Process Excellence
                                    </Typography>
                                    <Typography variant='h6' className='black al_center mb0'>
                                        We champion reduced errors and heightened efficiency through well-defined processes and established standard operating procedures, specifically designed for scalability, lean operations, and optimal SBA lender support services.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='flx_center mt16'>
                                <ProductCTAA allignment="flx_fs" cta_content="For a Free Consultation" cta_btn_name="Contact Us Today!" pageVisited="Small Business Administration" />
                            </Box>
                        </Grid>

                    </Grid>
                </Container>
            </Box>

            <Box id='sba_solutions' className='section sec_bg mob_card' component='section'>
                <Box className='over'></Box>
                <Container maxWidth='lg' className='bdr' style={{ position: 'relative', }}>

                    <Box>
                        <Grid container spacing={3} alignItems="top">

                            <Grid container spacing={1} alignItems="top">
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className='al_center'>
                                        <Typography variant='h2' className='black'>
                                            Comprehensive SBA Lending Services
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>


                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box>
                                    <Typography variant='h6' className='black al_center'>
                                         Decimal Point Analytics offers comprehensive support services to lenders at every stage of the SBA lending journey, ensuring seamless and efficient operations. Our range of services include:
                                    </Typography>
                                </Box>
                            </Grid>

                        </Grid>

                        <Grid container direction="row"
                            justifyContent="center"
                            alignItems="center">

                            <Grid item lg={9} md={9} sm={12} xs={12}>
                                <Box className="al_center" my={3}>
                                    <img src={Services} className="topbanner2" alt="Banner2" />
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} alignItems="center">

                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box className='boxbg mnhg3'>
                                            <Box className='al_center'>
                                                <img src={Service1} className='allic' alt='CS1' />
                                            </Box>
                                            <Box>
                                                <Typography variant='h6' className='blue al_center fw6'>
                                                    Underwriting Outsourcing
                                                </Typography>
                                                <Typography variant='h6' className='black al_center mb0'>
                                                    We specialize in SBA loan underwriting services for US lenders. Outsourcing underwriting to us offers expertise, efficiency, compliance, risk management, and cost-effectiveness.
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>

                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box className='boxbg mnhg3'>
                                            <Box className='al_center'>
                                                <img src={Service2} className='allic' alt='CS2' />
                                            </Box>
                                            <Box>
                                                <Typography variant='h6' className='blue al_center fw6'>
                                                    SBA Applications
                                                </Typography>
                                                <Typography variant='h6' className='black al_center mb0'>
                                                    We assist lenders in navigating the complexities of SBA applications, guiding them through the submission process, ensuring compliance with regulatory requirements, and providing a comprehensive SBA eligibility checklist.
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>


                                </Grid>
                            </Grid>

                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <Box className='al_center'>
                                    <img src={banenr2} className='topbanner2' alt='Banner2' />
                                </Box>
                            </Grid>

                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box className='boxbg mnhg3'>
                                            <Box className='al_center'>
                                                <img src={Service4} className='allic' alt='CS4' />
                                            </Box>
                                            <Box>
                                                <Typography variant='h6' className='blue al_center fw6'>
                                                    Servicing
                                                </Typography>
                                                <Typography variant='h6' className='black al_center mb0'>
                                                    We manage SBA loans continuously, handling payment processing, account maintenance, and customer support. We prioritize timely communication with borrowers and promptly resolve any servicing issues to uphold borrower satisfaction.
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>

                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box className='boxbg mnhg3'>
                                            <Box className='al_center'>
                                                <img src={Service5} className='allic' alt='CS5' />
                                            </Box>
                                            <Box>
                                                <Typography variant='h6' className='blue al_center fw6'>
                                                    KYC Outsourcing
                                                </Typography>
                                                <Typography variant='h6' className='black al_center mb0'>
                                                    We offer KYC outsourcing for SBA lending, ensuring regulatory compliance and risk mitigation with advanced technology and best practices.
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>


                                </Grid>
                            </Grid>


                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item lg={4} md={4} sm={12} xs={12}>
                                        <Box className='boxbg mnhg3'>
                                            <Box className='al_center'>
                                                <img src={Service3} className='allic' alt='CS3' />
                                            </Box>
                                            <Box>
                                                <Typography variant='h6' className='blue al_center fw6'>
                                                    Processing & Closing
                                                </Typography>
                                                <Typography variant='h6' className='black al_center mb0'>
                                                    We streamline the lending process from document collection to loan closure. Our services cover verification, documentation, ensuring smooth transactions, and minimizing delays
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>

                                    <Grid item lg={4} md={4} sm={12} xs={12}>
                                        <Box className='boxbg mnhg3'>
                                            <Box className='al_center'>
                                                <img src={Service6} className='allic' alt='CS6' />
                                            </Box>
                                            <Box>
                                                <Typography variant='h6' className='blue al_center fw6'>
                                                    Loan Outsourcing & Ongoing Support
                                                </Typography>
                                                <Typography variant='h6' className='black al_center mb0'>
                                                    We assist lenders in finding suitable SBA loans and offer continuous support. This includes loan sourcing, application processing, documentation, and closing.
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>

                                    <Grid item lg={4} md={4} sm={12} xs={12}>
                                        <Box className='boxbg mnhg3'>
                                            <Box className='al_center'>
                                                <img src={approach3} className='allic' alt='CS6' />
                                            </Box>
                                            <Box>
                                                <Typography variant='h6' className='blue al_center fw6'>
                                                    UCC Filing and Management Services
                                                </Typography>
                                                <Typography variant='h6' className='black al_center mb0'>
                                                We streamline UCC filing and management for lending operations, offering timely UCC-1 and UCC-3 filings, lien tracking, customized reporting, and expert guidance to ensure efficiency and compliance.
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='flx_fs mob_center'>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box className='flx_center mt16'>
                                            <ProductCTAA allignment="flx_fs" cta_content="To explore how our Comprehensive Lending SBA services can simplify your financial operations!" cta_btn_name="Contact Us Today" pageVisited="Small Business Administration" />
                                        </Box>
                                    </Grid>
                                    {/* <Typography className="black" variant="h6">
                                <Link className='link' to='/contact-us#contact_form'>Get in Touch</Link>  to explore how our UCC services can simplify your financial operations!
                            </Typography> */}
                                </Box>
                            </Grid>


                        </Grid>
                    </Box>
                </Container >

            </Box>


            {/* <Box id='sba_solutions' className="section" component="section">
                <Container maxWidth="lg" className="bdr">
                    <Grid container spacing={0} alignItems="center" justifyContent='center'>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center">
                                <Typography className="black" variant="h2">
                                    UCC Filing and Management Services
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item lg={5} md={5} sm={6} xs={12}>
                            <Box className='al_center'>
                                <img src={UCC_Filing} className='topbanner2' alt='UCC Filing and Management Services' />
                            </Box>
                        </Grid>

                        <Grid item lg={7} md={7} sm={6} xs={12}>
                            <Box>
                                <Typography className="blue" variant="h6">
                                    <Typography className="black" variant="h6"> At Decimal Point Analytics, we streamline UCC filing and management to support your lending operations. Our
                                        services include:</Typography>
                                    <ul>
                                        <li>
                                            <Typography variant="h6" className="black jst" gutterbottom> <strong> Comprehensive UCC Filing Support: </strong>  Ensure timely and accurate filing of UCC-1 and UCC-3 forms.</Typography>
                                        </li>

                                        <li>
                                            <Typography variant="h6" className="black jst" gutterbottom> <strong> Lien Management: </strong>Stay updated with lien tracking and amendments to maintain compliance. </Typography>
                                        </li>

                                        <li>
                                            <Typography variant="h6" className="black jst" gutterbottom> <strong> Customized Reporting: </strong> Receive tailored reports to monitor UCC activities effectively.</Typography>
                                        </li>

                                        <li>
                                            <Typography variant="h6" className="black jst" gutterbottom> <strong> Expert Guidance:</strong> Rely on our experienced professionals for seamless UCC management. </Typography>
                                        </li>
                                    </ul>
                                    <Typography className="black" variant="h6"> We ensure efficiency, accuracy, and peace of mind, empowering small businesses to focus on growth while we
                                        handle the complexities.</Typography>
                                </Typography>
                            </Box>

                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='flx_fs mob_center'>
                                <Typography className="black" variant="h6">
                                <Link className='link' to='/contact-us#contact_form'>Get in Touch</Link>  to explore how our UCC services can simplify your financial operations!
                            </Typography>
                                </Box>
                            </Grid>
                        </Grid>



                    </Grid>
                </Container>
            </Box> */}


            <Box id='sba_solutions' className='section mob_card' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container spacing={3} alignItems="center" justifyContent='center'>

                        <Grid container spacing={1} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='al_center'>
                                    <Typography variant='h2' className='black'>
                                        Structured <span className='lower'>and</span> Transparent Approach <span className='lower'>to</span> SBA Loan Outsourcing
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h6' className='black al_center'>
                                    Our approach to SBA loan outsourcing is structured and transparent. From initial onboarding to project execution, we prioritize communication, transparency, and collaboration. Our clients are kept informed at every step, ensuring a seamless outsourcing experience.
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box className='boxbg flx_fs align_c'>
                                <Box mr={'4px'}>
                                    <img src={approach1} className='allic mb0' alt='OP1' />
                                </Box>
                                <Box>
                                    <Typography variant='h6' className='black al_l mb0'>
                                        Understanding your requirements and documentation
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box className='boxbg flx_fs align_c'>
                                <Box mr={'4px'}>
                                    <img src={approach2} className='allic mb0' alt='OP2' />
                                </Box>
                                <Box>
                                    <Typography variant='h6' className='black al_l mb0'>
                                        Identifying suitable resources for training
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box className='boxbg flx_fs align_c'>
                                <Box mr={'4px'}>
                                    <img src={approach3} className='allic mb0' alt='OP1' />
                                </Box>
                                <Box>
                                    <Typography variant='h6' className='black al_l mb0'>
                                        Providing senior team support during transition
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box className='boxbg flx_fs align_c'>
                                <Box mr={'4px'}>
                                    <img src={approach4} className='allic mb0' alt='OP2' />
                                </Box>
                                <Box>
                                    <Typography variant='h6' className='black al_l mb0'>
                                        Delivering projects with continuous process documentation
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='flx_center mt16'>
                                <ProductCTAA allignment="flx_fs" cta_content="For a Free Consultation" cta_btn_name="Contact Us Today!" pageVisited="Small Business Administration" />
                            </Box>
                        </Grid>

                    </Grid>


                </Container>
            </Box>

            <Box id='sba_solutions' className='section sec_bg mob_card' component='section'>
                <Box className='over'></Box>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container spacing={3} alignItems="center" justifyContent='center'>

                        <Grid container spacing={1} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='al_center'>
                                    <Typography variant='h2' className='black'>
                                        Leveraging Automated Loan Management Systems
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box mt={0}>
                                <Typography variant='h6' className='black al_center'>
                                    Enhance operational efficiency and reduce manual workload with our automated loan management systems. Decimal Point Analytics provides state-of-the-art SBA lending services tailored to streamline loan origination, processing, and servicing. Experience seamless automation that maximizes productivity and minimizes errors in your SBA lending operations.
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='boxbg mnhg6'>
                                <Box className='al_center'>
                                    <img src={efficiency1} className='allic' alt='EE1' />
                                </Box>
                                <Box>
                                    <Typography variant='h6' className='black al_center mb0'>
                                        Reduce manual workload and free up your team for strategic tasks.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='boxbg mnhg6'>
                                <Box className='al_center'>
                                    <img src={efficiency2} className='allic' alt='EE2' />
                                </Box>
                                <Box>
                                    <Typography variant='h6' className='black al_center mb0'>
                                        Minimize errors through automated workflows and data validation.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='boxbg mnhg6'>
                                <Box className='al_center'>
                                    <img src={efficiency3} className='allic' alt='EE3' />
                                </Box>
                                <Box>
                                    <Typography variant='h6' className='black al_center mb0'>
                                        Enhance efficiency by streamlining document management and approvals.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                    </Grid>
                </Container>

            </Box>

            <Box id='sba_solutions' className="section " component="section">
                <Container maxWidth="lg" className="bdr">
                    <Grid container spacing={0} alignItems="center" justifyContent='center'>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center">
                                <Typography className="black" variant="h2">
                                    Why Choose Decimal Point Analytics <span className='lower'>for</span> Your SBA Lending Needs?
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item lg={5} md={5} sm={6} xs={12}>
                            <Box className='al_center'>
                                <img src={whyCP} className='topbanner5' alt='Why Choose Decimal Point Analytics for Your SBA Lending Needs? ' />
                            </Box>
                        </Grid>

                        <Grid item lg={7} md={7} sm={6} xs={12}>
                            <Box>
                                <Typography className="blue" variant="h6">
                                    <ul>
                                        <li>
                                            <Typography variant="h6" className="black jst" gutterbottom> <strong> Tailored Solutions: </strong>  Our approach is customized to suit your specific requirements in SBA lending. We understand the unique needs of your business and tailor our solutions accordingly.</Typography>
                                        </li>

                                        <li>
                                            <Typography variant="h6" className="black jst" gutterbottom> <strong> Expert Team: </strong> Our seasoned professionals excel in SBA lending practices, including loan underwriting support and loan processing outsourcing. We provide comprehensive support at every stage of the lending process. </Typography>
                                        </li>

                                        <li>
                                            <Typography variant="h6" className="black jst" gutterbottom> <strong> Innovation-Driven: </strong> We are constantly exploring and integrating the latest technological advancements to keep your SBA lending operations ahead of the curve.</Typography>
                                        </li>

                                        <li>
                                            <Typography variant="h6" className="black jst" gutterbottom> <strong> Client-Centric: </strong> Your success is our priority. We are committed to providing transparent, efficient, and supportive SBA lender services to help you achieve your financial goals. </Typography>
                                        </li>
                                    </ul>
                                </Typography>
                            </Box>

                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='flx_fs mob_center'>
                                    <ProductCTAA allignment="flx_fs" cta_content="For a Free Consultation" cta_btn_name="Contact Us Today!" pageVisited="Small Business Administration" />
                                </Box>
                            </Grid>
                        </Grid>



                    </Grid>
                </Container>
            </Box>

            <Box id='sba_solutions' className='section sec_bg  mob_card' component='section'>
                <Box className='over'></Box>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container spacing={3} alignItems="center" justifyContent='center'>

                        <Grid container spacing={1} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='al_center'>
                                    <Typography variant='h2' className='black'>
                                        Improving SBA Loan Processing Efficiency

                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box mt={0}>
                                <Typography variant='h6' className='black al_center'>
                                    Choose Decimal Point Analytics as your trusted partner for outsourcing SBA lending operations. With our deep industry knowledge, innovative solutions, and unwavering commitment to client success, we empower lenders to achieve their lending goals and drive success in the competitive landscape of small business financing.
                                </Typography>
                            </Box>
                        </Grid>



                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='boxbg mnhg6'>
                                <Box className='al_center'>
                                    <img src={story1} className='allic' alt='SS1' />
                                </Box>
                                <Box>
                                    <Typography variant='h6' className='black al_center mb0'>
                                        Reduced loan underwriting time by 40%
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='boxbg mnhg6'>
                                <Box className='al_center'>
                                    <img src={story2} className='allic' alt='SS2' />
                                </Box>
                                <Box>
                                    <Typography variant='h6' className='black al_center mb0'>
                                        Achieved a remarkable 99.7% accuracy in data extraction and entry
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='boxbg mnhg6'>
                                <Box className='al_center'>
                                    <img src={story3} className='allic' alt='SS3' />
                                </Box>
                                <Box>
                                    <Typography variant='h6' className='black al_center mb0'>
                                        Increased processing volume to 100+ loans for underwriting including Micro, Small and Large loans
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                    </Grid>
                </Container>

            </Box>

            <Box id='sba_solutions' className='section    mob_card' component='section'>
                <Box className='over'></Box>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container spacing={3} alignItems="center" justifyContent='center'>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center">
                                <Typography className="black" variant="h2">
                                    Explore our Resources
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center" >
                                <Typography className="blue h2v2 fw5" variant="h2">
                                    Our Success Stories
                                </Typography>
                            </Box>
                        </Grid>

                        {SBA_Success_Stories.map((item, index) => (
                            <Grid item lg={4} md={4} sm={6} xs={12} key={index}>
                                <Box className='reportinfo'>
                                    <Link to={typeof item.link === 'function' ? item.link.bind(item)() : item.link || item.link1}>
                                        <Box className="reportm_bg">
                                            <Box
                                                className="reportimg_bg"
                                                style={{
                                                    backgroundImage: `url(${item.imgsrc})`,
                                                }}
                                            >
                                            </Box>
                                            <Box my={2}>
                                                <Typography className="reporttitle two">
                                                    {item.heading}
                                                </Typography>
                                            </Box>
                                            <Box className="line"></Box>
                                        </Box>
                                    </Link>
                                </Box>
                            </Grid>
                        ))}


                        <Grid item lg={10} md={10} sm={10} xs={10}>
                            <Box mt={2}>
                                <Typography variant='h6' className='black al_center fw6 mb0'>
                                    Ready to unlock the potential of your SBA lending process?
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='flx_center'>
                                <ProductCTAA allignment="flx_fs" cta_content="For a Free Consultation" cta_btn_name="Contact Us Today!" pageVisited="Small Business Administration" />
                            </Box>
                        </Grid>


                    </Grid>
                </Container>

            </Box>

            <Box id='sba_solutions' className="section sec_bg" component="section">
                <Container maxWidth="lg" className="bdr">
                    <Grid container spacing={0} alignItems="center" justifyContent='center'>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center">
                                <Typography className="blue h2v2 fw5" variant="h2">
                                    Blogs
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid container spacing={2} alignItems="center" justifyContent='center'>

                            {SBA_Data.map((item, index) => (
                                <Grid item lg={4} md={4} sm={6} xs={12} key={index}>
                                    <Box className='reportinfo'>
                                        <Link to={typeof item.link === 'function' ? item.link.bind(item)() : item.link || item.link1}>
                                            <Box className="reportm_bg">
                                                <Box
                                                    className="reportimg_bg"
                                                    style={{
                                                        backgroundImage: `url(${item.imgsrc})`,
                                                    }}
                                                >
                                                </Box>
                                                <Box my={2}>
                                                    <Typography className="reporttitle two">
                                                        {item.heading}
                                                    </Typography>
                                                </Box>
                                                <Box className="line"></Box>
                                            </Box>
                                        </Link>
                                    </Box>
                                </Grid>
                            ))}

                        </Grid>
                    </Grid>
                </Container>
            </Box>


            <Box id='sba_solutions' className='section   mob_card' component='section' mt={5}>
                <Container>
                    <Grid container spacing={2} alignItems="center" justifyContent='center'>


                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center" >
                                <Typography className="blue h2v2 fw5" variant="h2">
                                    Podcast
                                </Typography>
                            </Box>
                        </Grid>

                        {SBA_Podcast.map((item, index) => (
                            <Grid item lg={4} md={4} sm={6} xs={12} key={index}>
                                <Box className='reportinfo'>
                                    <a href={item.link} target="_blank" rel="noopener noreferrer">
                                        <Box className="reportm_bg">
                                            <Box
                                                className="reportimg_bg"
                                                style={{
                                                    backgroundImage: `url(${item.imgsrc})`,
                                                }}
                                            >
                                            </Box>
                                            <Box className="reporttitle_box">
                                                <Typography className="reporttitle two">
                                                    {item.heading}
                                                </Typography>
                                            </Box>
                                            <Typography variant='h6' className='skblue'>
                                                {item.date}
                                            </Typography>
                                            <Box className="line"></Box>
                                            <Box className="reporttitle_box">
                                                <Typography variant="h6" className="black al_left thr">
                                                    {item.subheading}
                                                </Typography>
                                            </Box>
                                            <Box className='flx_fs al_left'>
                                                <Box>
                                                    <Typography variant='h6' className='skblue lh20' gutterBottom>
                                                        Watch Now
                                                    </Typography>
                                                </Box>
                                                <Box mr={3}>
                                                    <ForwardIcon className='skblue fast_forward' fontSize='small' />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </a>
                                </Box>
                            </Grid>
                        ))}


                    </Grid>
                </Container>
            </Box>

            <Box className='sec_bg'>
                <GetInTouch />
            </Box>

        </>
    );
}
