import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export default function Mumbai_table() {

    const data = [
        		
        { monthYear: 'Dec-24', dpa: 9869, employees: 179, ue: 55 },
        { monthYear: 'Nov-24', dpa: 10594, employees: 183, ue: 58 },
        { monthYear: 'Oct-24', dpa: 11411, employees: 180, ue: 63 },
        { monthYear: 'Sep-24', dpa: 9098, employees: 183, ue: 50 },
        { monthYear: 'Aug-24', dpa: 9880, employees: 183, ue: 54 },
        { monthYear: 'Jul-24', dpa: 9372, employees: 192, ue: 49 },
        { monthYear: 'Jun-24', dpa: 9146, employees: 194, ue: 47 },
        { monthYear: 'May-24', dpa: 10213, employees: 203, ue: 50},
        { monthYear: 'Apr-24', dpa: 10232, employees: 199, ue: 51 },
        { monthYear: 'Mar-24', dpa: 9062, employees: 204, ue: 44 },
        { monthYear: 'Feb-24', dpa: 9349, employees: 205, ue: 46 },
        { monthYear: 'Jan-24', dpa: 9930, employees: 205, ue: 48 },
        { monthYear: 'Dec-23', dpa: 10445, employees: 210, ue: 50 },
        { monthYear: 'Nov-23', dpa: 11233, employees: 213, ue: 53 },
        { monthYear: 'Oct-23', dpa: 12183, employees: 220, ue: 55 },
        { monthYear: 'Sep-23', dpa: 9393, employees: 219, ue: 43 },
        { monthYear: 'Aug-23', dpa: 10363, employees: 229, ue: 45 },
        { monthYear: 'Jul-23', dpa: 9461, employees: 296, ue: 32 },
        { monthYear: 'Jun-23', dpa: 11989, employees: 300, ue: 40 },
        { monthYear: 'May-23', dpa: 12242, employees: 279, ue: 44 },
        { monthYear: 'Apr-23', dpa: 10904, employees: 280, ue: 39 },
        { monthYear: 'Mar-23', dpa: 10906, employees: 326, ue: 33 },
        { monthYear: 'Feb-23', dpa: 10374, employees: 340, ue: 31 },
        { monthYear: 'Jan-23', dpa: 11206, employees: 345, ue: 32 },
        { monthYear: 'Dec-22', dpa: 12207, employees: 336, ue: 36 },
        { monthYear: 'Nov-22', dpa: 11703, employees: 298, ue: 39 },
        { monthYear: 'Oct-22', dpa: 11046, employees: 295, ue: 37 },
        { monthYear: 'Sep-22', dpa: 10315, employees: 303, ue: 34 },
        { monthYear: 'Aug-22', dpa: 11039, employees: 308, ue: 36 },
        { monthYear: 'Jul-22', dpa: 10648, employees: 315, ue: 34 },
        { monthYear: 'Jun-22', dpa: 11507, employees: 310, ue: 37 },
        { monthYear: 'May-22', dpa: 11138, employees: 274, ue: 41 },
        { monthYear: 'Apr-22', dpa: 10386, employees: 278, ue: 37 },
        { monthYear: 'Mar-22', dpa: 10530, employees: 278, ue: 38 },
        { monthYear: 'Feb-22', dpa: 7584, employees: 277, ue: 27 },
        { monthYear: 'Jan-22', dpa: 7380, employees: 289, ue: 26 },
        { monthYear: 'Dec-21', dpa: 9689, employees: 296, ue: 33 },
        { monthYear: 'Nov-21', dpa: 9995, employees: 286, ue: 35 },
        { monthYear: 'Oct-21', dpa: 8476, employees: 286, ue: 30 },
        { monthYear: 'Sep-21', dpa: 7291, employees: 288, ue: 25 },
        { monthYear: 'Aug-21', dpa: 8167, employees: 295, ue: 28 },
        { monthYear: 'Jul-21', dpa: 8646, employees: 270, ue: 32 },
        { monthYear: 'Jun-21', dpa: 8538, employees: 262, ue: 33 },
        { monthYear: 'May-21', dpa: 9361, employees: 248, ue: 38 },
        { monthYear: 'Apr-21', dpa: 8860, employees: 210, ue: 42 },
        { monthYear: 'Mar-21', dpa: 8142, employees: 210, ue: 39 },
        { monthYear: 'Feb-21', dpa: 7521, employees: 190, ue: 40 },
        { monthYear: 'Jan-21', dpa: 9288, employees: 181, ue: 51 },
        { monthYear: 'Dec-20', dpa: 9660, employees: 178, ue: 54 },
        { monthYear: 'Nov-20', dpa: 9404, employees: 174, ue: 54 },
        { monthYear: 'Oct-20', dpa: 9648, employees: 178, ue: 54 },
        { monthYear: 'Sep-20', dpa: 8852, employees: 170, ue: 52 },
        { monthYear: 'Aug-20', dpa: 8460, employees: 166, ue: 51 },
        { monthYear: 'Jul-20', dpa: 7896, employees: 168, ue: 47 },
        { monthYear: 'Jun-20', dpa: 8212, employees: 163, ue: 50 },
        { monthYear: 'May-20', dpa: 8752, employees: 157, ue: 56 },
        { monthYear: 'Apr-20', dpa: 8480, employees: 158, ue: 54 },
        { monthYear: 'Mar-20', dpa: 9484, employees: 159, ue: 60 },
        { monthYear: 'Feb-20', dpa: 10076, employees: 162, ue: 62 },
        { monthYear: 'Jan-20', dpa: 10444, employees: 158, ue: 66 },
    ];

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden', borderRadius: '0px', padding: '5px' }}>
            <TableContainer sx={{ maxHeight: '260px' }}>
                <Table stickyHeader aria-label="sticky table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ minWidth: '80px' }}>MM/YY</TableCell>
                            <TableCell align="right" className='skblue' sx={{ minWidth: '80px' }}>DPA</TableCell>
                            <TableCell align="right" sx={{ minWidth: '80px' }}>EMPLOYEES</TableCell>
                            <TableCell align="right" sx={{ minWidth: '80px' }}>U/E</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {data.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>{item.monthYear}</TableCell>
                                <TableCell align="right">{item.dpa}</TableCell>
                                <TableCell align="right">{item.employees}</TableCell>
                                <TableCell align="right">{item.ue}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}