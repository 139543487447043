import React from "react";
import { Typography, Box, Button } from "@mui/material";

function Newsletter_Design(props) {
    const { year, link, imgsrc, heading, date, subheading } = props;

    if (year === 2025) {
        return (
            <a href={link} target="_blank" rel="noopener noreferrer">
                <Box style={{ backgroundColor: "#ebf2fc", position: "relative", zIndex: "+999", paddingBottom:"1px" }}>
                    <Typography variant='h6' className='reporttitle'>
                        {date}
                    </Typography>
                </Box>
                <Box id='newsl2025' className="reportm_bg">
                    <Box className="reportimg_bg">
                        <img src={imgsrc} alt="Newsletter" className="newsletter_2025" />
                    </Box>
                </Box>
            </a>
        );
    }

    if (year === 2024) {
        return (
            <a href={link} target="_blank" rel="noopener noreferrer">
                <Box className="reportm_bg">
                    <Typography variant='h6' className='reporttitle'>
                        {date}
                    </Typography>
                    <Box
                        className="reportimg_bg"
                        style={{
                            backgroundImage: `url(${imgsrc})`,
                        }}
                    ></Box>
                    <Box className="reportbox" mt={1}>
                        <Typography className="reporttitle" title={heading}>{heading}</Typography>
                    </Box>
                    <Box mt={1}>
                        <Typography variant='h6' className='black fw5 jst' title={subheading}>{subheading}</Typography>
                    </Box>
                    <Box mt={1}>
                        <Button variant='h6' className='read_btn'>Read More</Button>
                    </Box>
                </Box>
            </a>
        );
    }

    // For 2023 & 2022
    return (
        <a href={link} target="_blank" rel="noopener noreferrer">
            <Box className="reportm_bg">
                <Box
                    className="reportimg_bg"
                    style={{ backgroundImage: `url(${imgsrc})`, }}
                ></Box>

                <Box mt={2} mb={1}>
                    <Typography className="reporttitle one">
                        {date}
                    </Typography>
                </Box>
                <Box className="line"></Box>
            </Box>
        </a>
    );
}

export default Newsletter_Design;
