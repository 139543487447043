import { Container, Typography, Box, Grid, Divider } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { Helmet } from 'react-helmet';
import Preview from "../../../asset/images/insight/whitepaper_pages/llm_whitepaper_preview.webp"
// import ViewFullWhitepaperNew from '../../../Component/forms/ViewFullWhitepaperNew';
import Whitepaperback from '../../../Component/Whitepaperback';
import ViewFullWhitepaper from '../../../Component/forms/ViewFullWhitepaper';

export default function LLM_Models() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    const link = "https://objectstorage.ap-mumbai-1.oraclecloud.com/n/bmexgnkisxsv/b/marketing_dpa/o/Leveraging%20Large%20Language%20Models%20to%20Minimize%20Technical%20Debt_Whitepaper(Download).pdf"

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Managing Technical Debt Crisis with Large Language Models (LLMs)</title>
                <meta name="description" content="Discover how Large Language Models (LLMs) address technical debt crisis by automating development tasks, reducing rework, bugs, and onboarding times." />
                <meta name="keywords" content="Technical debt crisis, Large Language Models, LLMs, software innovation, AI solution, development tasks, code generation, documentation, refactoring, AI integration, AI literacy, AI-human symbiosis, innovative use cases, project risk management" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/whitepapers/leveraging-large-language-models-to-minimize-technical-debt" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box className="section sec_bg">
                <Container maxWidth="lg">

                    <Grid container spacing={2} justifyContent='center'>


                        <Grid item lg={12} md={12} sm={12} xs={12} className='grid-custom-12'>




                            <Grid container spacing={1} alignItems="top" justifyContent='center' >
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box>
                                        <Typography variant='h3' className='subhead skblue al_center'>
                                            Whitepaper
                                        </Typography>
                                        <Typography variant='h1' className='blue mainhead al_center' style={{ textTransform: 'none' }}>
                                            Leveraging Large Language Models to Minimize Technical Debt
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Box className='whitebx' mt={1}>

                                <Grid container spacing={0} alignItems="flex-start">
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box className='preview-box'>
                                            <img src={Preview} className='preview-newsletter' alt='LLM Whitepaper' />
                                        </Box>
                                    </Grid>

                                </Grid>

                                <Divider />

                            <Box mt={2} className='al_center'>
                                <Typography variant="h4" className="black">
                                    Get Your Full Copy Now – Simply Fill Out the Form
                                </Typography>
                            </Box>


                            <Box className='graybbx2222' mt={2}>
                                <ViewFullWhitepaper reportby={"Leveraging Large Language Models to Minimize Technical Debt"} link={link} />
                            </Box>
                            </Box>

                            

                        </Grid>

                        {/* <Grid item lg={4} md={5} sm={5} xs={12} className='grid-custom-12'>
                            <Box className='bg_websection' p={4}>
                                <Box className="web_section whitebx" >
                                    <Box mt={2} className='al_center'>
                                        <Typography variant="h4" className="black">
                                            Download the full Whitepaper
                                        </Typography>
                                    </Box>

                                    <Box className='graybbx222' mt={2}>
                                        <ViewFullWhitepaperNew reportby={"Leveraging Large Language Models to Minimize Technical Debt"} link={link} />
                                    </Box>
                                </Box>
                            </Box>
                        </Grid> */}

                        <Grid item lg={4} md={5} sm={5} xs={12} className='grid-custom-12'>
                            <Box>
                                <Whitepaperback />
                            </Box>
                        </Grid>



                    </Grid>
                </Container>
            </Box>

        </>
    );
}
