import { Container, Typography, Box, Grid, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


import Writemessage3 from '../../../Component/Writemessage3';

import Blogback from '../../../Component/Blogback'
import { Helmet } from 'react-helmet';

import ana_GDP from "../../../asset/images/insight/blog_pages/analysing_GDP.webp";
import DPAProfile from '../../../Component/DPAProfile';

export default function Does_RBI_need_to_revise() {
    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <h1> </h1>
                <title>Does RBI Need To Revise Its Models? | Decimal Point Analytics</title>
                <meta name="description" content="Examine the need for RBI to revise its inflation forecasting models. Analyze the implications of recent low inflation prints on monetary policy decisions." />
                <meta name="keywords" content="RBI, inflation forecast, interest rate decisions, internal models, low inflation, long-range forecasts, margin of error, actual inflation readings, paradigm shift, monetary policy, growth prospects, Indian economy, methodological questions, analysis" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/blog/does-rbi-need-to-revise" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>



            <Box id="blog-page" className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Typography variant='h3' className='subhead skblue al_center'>
                                Blog
                            </Typography>
                            <Typography variant='h1' className='blue mainhead al_center mb0'>
                                Does RBI need to revise its models?
                            </Typography>
                            <Typography variant='h4' className='blue al_center'>
                                August 07, 2017
                            </Typography>
                        </Grid>
                    </Grid>

                    <Box className='whitebx'>



                        <Typography variant='h6' className='black jst'>The low inflation print in recent months has raised some important methodological questions. RBI relies heavily on the internal models that it has developed for its inflation forecast and hence for making interest rate decisions.</Typography>

                        <Typography variant='h6' className='black jst'>The table below shows some of the recent long-range forecast envelops and the actual inflation prints at the end of the forecast periods.</Typography>

                        <Typography variant='h4' className='blue jst'>Table 1: Sample long-range RBI forecasts for inflation</Typography>

                        <Box className='al_left' mt={4} mb={4}>
                            <img src={ana_GDP} className='topbanner2' alt='Ana GDP' />
                        </Box>

                        <Typography variant='h6' className='black jst'>Source: Decimal Point Analytics</Typography>

                        <Typography variant='h6' className='black jst'>The table shows that the long-range forecasts made till Feb 2016 were able to predict the inflation within the margin of error. However, since Sept 2016, the forecasts published by RBI are too high as compared to the actual reads. The actual readings are coming outside the 90 percentile range, and way below the median.</Typography>

                        <Typography variant='h6' className='black jst'>It would be interesting to analyze this phenomenon in detail to study if this is a transient effect or as suggested by us previously a paradigm shift in the efficiency of Indian economy. The result of this analysis will have profound effect on the conduct of monetary policy and on the growth prospects of India.</Typography>

                        <Box>
                            <DPAProfile />
                        </Box>

                    </Box>


                    <Box className='pages'>
                        <Writemessage3 pageVisited="Does RBI need to revise its models" />
                    </Box>

                    <Blogback />

                </Container>
            </Box>


        </>
    );
}
