import { Container, Typography, Box, Grid, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Writemessage3 from '../../../Component/Writemessage3';
import Blogback from '../../../Component/Blogback'
import Demon1 from "../../../asset/images/insight/blog_pages/is_demon_respo.webp";
import { Helmet } from 'react-helmet';
import DPAProfile from '../../../Component/DPAProfile';

export default function Is_demonstration_respo() {


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <h1>Is Demonetization Responsible for Lower GDP Growth Rate? </h1>
                <title>Is Demonetization Responsible for Lower GDP Growth Rate?</title>
                <meta name="description" content="Analyze the impact of demonetization on India's GDP growth. Examine the factors influencing GDP growth and the role of high real interest rates." />
                <meta name="keywords" content="Demonetization, GDP growth rate, India, economic impact, private consumption, capital formation, net imports, reasoning, real interest rates, currency appreciation, trade deficit, analysis" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/blog/is-demonstration-respo" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box id="blog-page" className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Typography variant='h3' className='subhead skblue al_center'>
                                Blog
                            </Typography>
                            <Typography variant='h1' className='blue mainhead al_center mb0'>
                                Is Demonetization Responsible  for  Lower GDP Growth Rate?
                            </Typography>
                            <Typography variant='h4' className='blue al_center'>
                                September 04, 2017
                            </Typography>
                        </Grid>
                    </Grid>


                    <Box className='whitebx'>



                        <Typography variant='h6' className='black jst'>Since India’s April-June 2017 (Q1FY18) numbers are printed below market expectations, there has been some discussions whether demonetization is responsible for the low GDP print.</Typography>

                        <Typography variant='h6' className='black jst'>Some so-called expert economists had predicted, that Indian GDP growth will be lower by 2 percentage points due to demonetization. They had conveniently not given reasoning as to why that would happen. One can imagine thinking that since currency notes are in short supply, the retail transactions will reduce and will affect consumer buying. This looks like the logical course of action for anyone predicting reduction in demand due to reduction in currency in circulation.</Typography>

                        <Typography variant='h4' className='blue jst'>Table 1: Composition of GDP at constant Prices</Typography>

                        <Box className='al_left' mt={4} mb={4}>
                            <img src={Demon1} className='topbanner2' alt='Demon1' />
                        </Box>

                        <Typography variant='h6' className='black jst'>Source: Decimal Point Analytics</Typography>

                        <Typography variant='h6' className='black jst'>Let us now see what actually transpired in the latest reported quarter. Private consumption was 53.6% of GDP in QFY17 and accounted for 62.3% of incremental GDP.  In other words, private consumption grew faster than overall GDP at 6.7% during the year.</Typography>

                        <Typography variant='h6' className='black jst'>However, Capital formation, which accounted for 31% of GDP in Q1FY17, accounted for only 8.8% of the incremental GDP, indicating unwillingness of businesses to invest in future, in spite of seeing reasonable demand currently. Maybe one can explain this as a reaction to high real interest rates, at 5% plus for the best borrowers.</Typography>


                        <Typography variant='h6' className='black jst'>Also, Net Imports dragged down the GDP in Q1FY17 by 0.9% of total, but on incremental basis net imports dragged down the GDP by whopping 45.1%. In other words, if the currency would have not appreciated from above 66.50 to a dollar in April 2016 to current levels of below 64.00; maybe our net imports would have remained stable and the GDP would not have accounted for the 45% downward drag. Had that not happened, India’s GDP growth rate would have been 8.30%, instead of 5.7%. This alone accounts for 2.6% drag on GDP growth rate. If someone can link the worsening trade deficit to demonetization, then yes, demonetization affected GDP growth rate. But, no such link exists.</Typography>

                        <Typography variant='h6' className='black jst'>    On the contrary, as shown above, high real rates and high currency dragged the GDP growth rate down by about 4% by worsening net imports and by reducing capex.
                        </Typography>




                        {/* <Box mt={5}>
                            <Subscribe />
                        </Box> */}

                        <Box>
                            <DPAProfile />
                        </Box>

                    </Box>


                    <Box className='pages'>
                        <Writemessage3 pageVisited="Is Demonetization Responsible for Lower GDP Growth Rate" />
                    </Box>




                    <Blogback />

                </Container>
            </Box>


        </>
    );
}
