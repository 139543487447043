import React, { useEffect, } from "react";
import {
  Container,
  Grid,
  Typography,
  Box,
} from "@material-ui/core";

import "aos/dist/aos.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../Style.css";
import "react-toastify/dist/ReactToastify.css";
import AOS from 'aos';
import 'react-phone-number-input/style.css';
import pdf from '../asset/images/pdf.png'



export default function MagazineCTA(props) {


  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
      <Box id="magazine" className="maintabbedsection stiky3">
        <Container maxWidth="lg" className="bdr">
          <Grid container spacing={1} alignItems="center">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className="fx_c">
                <Box>
                  <img src={pdf} alt="PDF" className="pdf_icon" />
                </Box>
                <Box>
                  <a href='https://objectstorage.ap-mumbai-1.oraclecloud.com/n/bmexgnkisxsv/b/creative_dpa/o/Media%2FThe-Dec-aiml-Perspective.pdf' target="_blank" rel="noopener noreferrer">
                    <Typography className="stay2 al_center" variant="h4">
                      <em>Download <span className='lower'>your copy here</span></em>
                    </Typography>
                  </a>
                </Box>

              </Box>
            </Grid>

          </Grid>
        </Container>
      </Box>

    </>
  );
}
