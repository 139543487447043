import LLM_Models from "../../asset/images/insight/whitepaper_thumbnails/llm_models.webp";
import TurningDataIntoDollars from "../../asset/images/insight/whitepaper_thumbnails/turning_data_into_dollars.webp";
import AlternativeDataInvestment from "../../asset/images/insight/whitepaper_thumbnails/alternative_data_investment.webp";
import GettingVisualWithData from "../../asset/images/insight/whitepaper_thumbnails/banking.webp";
import AmplifiedNeedForTechLed from "../../asset/images/insight/whitepaper_thumbnails/tech_led.webp";
import DataAConfidenceBooster from "../../asset/images/insight/whitepaper_thumbnails/data_cre_lending.webp";
import PredictiveIntelligenceFinancial from "../../asset/images/insight/whitepaper_thumbnails/predictive-intelligence-financial.webp";
// import NavigatingAILandscape from "../../asset/images/insight/whitepaper_thumbnails/navigating_ai_landscape.webp";

const Whitepaper_Data = [
    // {
    //     link: "/whitepapers/navigating-the-ai-landscape-key-trends-shaping-2025-and-beyond",
    //     imgsrc: NavigatingAILandscape,
    //     heading: "Navigating the AI Landscape: Key Trends Shaping 2025 and Beyond",
    //     date: "December, 2024"
    // },
    {
        link: "/whitepapers/predictive-intelligence-in-financial-services-preparing-for-the-future",
        imgsrc: PredictiveIntelligenceFinancial,
        heading: "Predictive Intelligence in Financial Services: Preparing for the Future",
        date: "December, 2024"
    },
    {
        link: "/whitepapers/data-a-confidence-booster-in-commercial-real-estate-cre-lending-decisions",
        imgsrc: DataAConfidenceBooster,
        heading: "Data, a confidence-booster in commercial real estate CRE lending decisions",
        date: "September, 2024"
    },
    {
        link: "/whitepapers/getting-visual-with-data-for-personalized-banking",
        imgsrc: GettingVisualWithData,
        heading: "Getting Visual with Data for Personalized Banking",
        date: "August, 2024"
    },
    {
        link: "/whitepapers/the-amplified-need-for-tech-led-intelligence-in-insurance",
        imgsrc: AmplifiedNeedForTechLed,
        heading: "The Amplified Need for Tech-Led Intelligence in Insurance",
        date: "August, 2024"
    },
    {
        link: "/whitepapers/alternative-data-that-can-power-successful-alternative-investments",
        imgsrc: AlternativeDataInvestment,
        heading: "Alternative Data that can Power Successful Alternative Investments",
        date: "August, 2024"
    },
    {
        link: "/whitepapers/turning-data-into-dollars-combining-advanced-analytics-and-tech-to-unlock-intel-for-hedge-funds",
        imgsrc: TurningDataIntoDollars,
        heading: "Turning Data into Dollars: Combining Advanced Analytics and Tech to Unlock Intel for Hedge Funds",
        date: "August, 2024"
    },
    {
        link: "/whitepapers/leveraging-large-language-models-to-minimize-technical-debt",
        imgsrc: LLM_Models,
        heading: "Leveraging Large Language Models to Minimize Technical Debt",
        date: "August, 2024"
    }
];

export default Whitepaper_Data;