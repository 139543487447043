import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import NewsRoomBack from '../../../Component/NewsRoomBack.js';
import { Helmet } from 'react-helmet';

import Banner from '../../../asset/images/media/press_release/banner_hackathon_2025.webp'
import BNTW from "../../../asset/images/media/bntw.webp";
import MB from "../../../asset/images/media/media_bulletins.webp";
import BND from "../../../asset/images/media/biz_news_desk.webp";
import OMC from "../../../asset/images/media/oneline_media_cafe.webp";
import CMS from "../../../asset/images/media/content_media_solution.webp";
import SBN from "../../../asset/images/media/smart_business_news.webp";
import BWE from "../../../asset/images/media/bw_education.webp";


export default function Hackathon2025() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>DPA Hosts Inaugural Hackathon 2025 to Drive Innovation in AI</title>
                <meta name="description" content="Decimal Point Analytics' Hackathon 2025 empowers employees to create innovative AI and financial solutions, addressing real-world challenges in financial tech." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/decimal-point-analytics-hosts-hackathon-2025" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>


                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h2' className='subhead skblue al_center'>
                                    News Room
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Decimal Point Analytics (DPA) Hosts Hackathon 2025
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box >
                        <img src={Banner} className='w100' alt='Decimal Point Analytics (DPA) Hosts Hackathon 2025' />
                    </Box>

                    <Box>
                        <Grid container direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='whitebx'>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Decimal Point Analytics (DPA), a leading data analytics and AI-driven solutions firm, organized its inaugural Hackathon 2025 to inspire creative problem-solving and collaboration among employees. The event encouraged participants to tackle real-world challenges aligned with DPA’s focus areas on financial analytics and technology.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        The hackathon revolved around six thoughtfully curated themes that align with DPA’s core objectives, including:
                                        <ol>
                                            <li>
                                                <Typography variant='h6' className='black jst'>
                                                    <strong>AI and Machine Learning for Private Equity Insights</strong>
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography variant='h6' className='black jst'>
                                                    <strong>Automated Compliance and Fraud Detection</strong>
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography variant='h6' className='black jst'>
                                                    <strong>Sustainable Investment Analysis</strong>
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography variant='h6' className='black jst'>
                                                    <strong>Customer Sentiment Analysis in Financial Services</strong>
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography variant='h6' className='black jst'>
                                                    <strong>Enhanced Data Visualization for Financial Insights</strong>
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography variant='h6' className='black jst'>
                                                    <strong>Financial Knowledge Graphs Using NLP</strong>
                                                </Typography>
                                            </li>
                                        </ol>
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Participants worked in teams over a five-day period to create innovative solutions to complex problems using cutting-edge tools and technologies. The event culminated in a high-energy presentation where teams demonstrated prototypes ranging from real-time compliance monitoring systems to interactive dashboards and AI-driven sentiment analysis tools.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        The initiative reflects DPA’s commitment to fostering a culture of continuous learning and innovation, guided by the company’s <strong>Mantra & Sutras – Learn, StayAhead, a</strong> set of principles that encourages employees to embrace learning, collaboration, and value delivery. “Hackathon 2025 exemplifies our approach to solving complex challenges through teamwork and creative thinking. It ensures our employees stay ahead of the curve and deliver meaningful insights to our clients,” remarked CEO Shailesh Dhuri.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Several prototypes developed during the event showed promise for further refinement and potential integration into DPA’s solutions. By combining advanced analytics, AI, and employee ingenuity, the hackathon emphasized DPA’s vision of leveraging technology to address challenges in the financial ecosystem.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Hackathon 2025 successfully demonstrated the potential of empowering employees to think outside the box and contribute to DPA’s mission of delivering performance advantages to its clients.
                                    </Typography>

                                </Box>
                            </Grid>
                        </Grid>


                    </Box>

                    <Box>
                        <Box className='graybbx' mt={2}>
                            <Grid container spacing={0} alignItems="center" justifyContent='center'>

                                <Grid item lg={3} md={3} sm={6} xs={12}>
                                    <a href="https://businessnewsthisweek.com/business/decimal-point-analytics-dpa-hosts-hackathon-2025-empowering-innovation-in-data-analytics-and-financial-technology/" target="_blank" rel="noopener noreferrer">
                                        <Box
                                            className="awardsindexcircle"
                                            style={{ backgroundImage: "url(" + BNTW + ")" }}
                                        ></Box>
                                    </a>
                                </Grid>

                                <Grid item lg={3} md={3} sm={6} xs={12}>
                                    <a href="https://mediabulletins.com/business/decimal-point-analytics-hosts-hackathon-2025-driving-innovation-in-data-analytics-and-fintech/" target="_blank" rel="noopener noreferrer">
                                        <Box
                                            className="awardsindexcircle"
                                            style={{ backgroundImage: "url(" + MB + ")" }}
                                        ></Box>
                                    </a>
                                </Grid>

                                <Grid item lg={3} md={3} sm={6} xs={12}>
                                    <a href="https://biznewsdesk.com/business/dpa-hosts-hackathon-2025-paving-the-way-for-innovation-in-data-analytics-and-financial-technology/" target="_blank" rel="noopener noreferrer">
                                        <Box
                                            className="awardsindexcircle"
                                            style={{ backgroundImage: "url(" + BND + ")" }}
                                        ></Box>
                                    </a>
                                </Grid>

                                <Grid item lg={3} md={3} sm={6} xs={12}>
                                    <a href="https://onlinemediacafe.com/business/empowering-the-future-decimal-point-analytics-hosts-hackathon-2025-for-data-and-fintech-innovation/" target="_blank" rel="noopener noreferrer">
                                        <Box
                                            className="awardsindexcircle"
                                            style={{ backgroundImage: "url(" + OMC + ")" }}
                                        ></Box>
                                    </a>
                                </Grid>

                                <Grid item lg={3} md={3} sm={6} xs={12}>
                                    <a href="https://contentmediasolution.com/business/decimal-point-analytics-brings-together-innovators-at-hackathon-2025-in-data-analytics-and-financial-tech/" target="_blank" rel="noopener noreferrer">
                                        <Box
                                            className="awardsindexcircle"
                                            style={{ backgroundImage: "url(" + CMS + ")" }}
                                        ></Box>
                                    </a>
                                </Grid>

                                <Grid item lg={3} md={3} sm={6} xs={12}>
                                    <a href="https://smartbusinesnews.com/business/hackathon-2025-by-decimal-point-analytics-sparks-innovation-in-data-analytics-and-fintech/" target="_blank" rel="noopener noreferrer">
                                        <Box
                                            className="awardsindexcircle"
                                            style={{ backgroundImage: "url(" + SBN + ")" }}
                                        ></Box>
                                    </a>
                                </Grid>

                                <Grid item lg={3} md={3} sm={6} xs={12}>
                                    <a href="https://bweducation.com/article/decimal-point-analytics-dpa-hosts-hackathon-2025-544486" target="_blank" rel="noopener noreferrer">
                                        <Box
                                            className="awardsindexcircle"
                                            style={{ backgroundImage: "url(" + BWE + ")" }}
                                        ></Box>
                                    </a>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>


                    <Box mt={2}>
                        <NewsRoomBack />
                    </Box>

                </Container>
            </Box>





        </>
    );
}
