import {
    Box,
    Grid,
} from '@material-ui/core'; // Added Dialog
import React, { useEffect, } from "react";
import Iframe from 'react-iframe';
import "aos/dist/aos.css";
import Aos from "aos";
import MagazineCTA from '../Component/MagazineCTA';
import "react-toastify/dist/ReactToastify.css";
import mobCover from '../asset/images/magazine-img.webp';
import '@react-pdf-viewer/core/lib/styles/index.css';
import pdfMag from '../asset/pdf/The-Dec-aiml-Perspective.pdf'
import { Helmet } from 'react-helmet';
import Header from '../Component/Header';

export const Magazine = () => {


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>
            <Header />

            <Helmet>
                <meta charset="UTF-8" />
                <title>Year-End Magazine 2024 | Insights and Reflections</title>
                <meta name="description" content="Explore key AI trends shaping 2025. Gain leadership insights from Decimal Point Analytics. Navigate the future with innovation & excellence." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/magazine" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>

            <MagazineCTA pageVisited="Magazine" />

            <Box mt={"60px"}>
                <Iframe
                    src={pdfMag}
                    width="100%"
                    height="600px"
                    style={{ border: 'none' }}
                    title="PDF Viewer"
                    className='magazine-iframe'
                    display="block"
                    position="relative"
                    frameBorder="0"
                    allow='fullscreen'
                />
            </Box>

            <Box className='mg-mobCover'>
                <Grid container spacing={0} alignItems="center" justifyContent='center'>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Box mt={3}>
                        </Box>
                        <Box className='al_center' my={3}>
                            <a href='https://objectstorage.ap-mumbai-1.oraclecloud.com/n/bmexgnkisxsv/b/creative_dpa/o/Media%2FThe-Dec-aiml-Perspective.pdf' target="_blank" rel="noopener noreferrer">
                                <img
                                    src={mobCover}
                                    className='mg-cover'
                                    alt='Magazine Cover'
                                />
                            </a>
                        </Box>
                    </Grid>
                </Grid>
            </Box>


        </>
    );
};