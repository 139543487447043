import React from "react";

import { BrowserRouter as Router, Switch, } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Subscribe from "./Component/Subscribe";
import Insightback from "./Component/Insightback";
import Blogback from "./Component/Blogback";
import Infoback from "./Component/Infoback";
import Casestudyback from "./Component/Casestudyback";
import Form_submit from "./Component/Form_submit";
import Index_new from "./Pages/Index_new";
import Whoweare from "./Pages/About/Whoweare";
import Whatwedo from "./Pages/About/Whatwedo";
import Awards from "./Pages/About/Awards";
import Management from "./Pages/About/Management";
import Technologyedge from "./Pages/About/Technologyedge";
import Valueproposition from "./Pages/About/Valueproposition";
import Structure_innovation from "./Pages/About/Structure_innovation";
import Csr from "./Pages/About/Csr";
import Energy_efficiency from "./Pages/About/Energy_efficiency";
import Quantum_computing from "./Pages/About/Quantum_computing";
import DecimalSutras from "./Pages/About/Decimal_sutras";
import Datasolution from "./Pages/Services/Datasolution";
import BusinessIntelligence from "./Pages/Services/Businessintelligence";
import Artificialintelligence from "./Pages/Services/Artificialintelligence";
import Researchsolution from "./Pages/Services/Researchsolution";
import How_can_asset_managers from "./Pages/Insight/Insightpages/How_can_asset_managers";
import PE_funds from "./Pages/Insight/Insightpages/PE_funds";
import Challenges_in_ESG from "./Pages/Insight/Insightpages/Challenges_in_ESG";
import Solving_challenges from "./Pages/Insight/Insightpages/Solving_challenges";
import Data_visualization from "./Pages/Insight/Insightpages/Data_visualization";
import NLP_technique from "./Pages/Insight/Insightpages/NLP_technique";
import Private_equity from "./Pages/Insight/Insightpages/Private_equity";
import Roadmap_to_institutional from "./Pages/Insight/Insightpages/Roadmap_to_institutional";
import Way_forward_for_asset_management from "./Pages/Insight/Insightpages/Way_forward_for_asset_management";
import Disrupting_asset_management from "./Pages/Insight/Insightpages/Disrupting_asset_management";
import Data_analytics_in_asset from "./Pages/Insight/Insightpages/Data_analytics_in_asset";
import Role_of_advanced_analytics from "./Pages/Insight/Insightpages/Role_of_advanced_analytics";
import Interest_rates_burden from "./Pages/Insight/Insightpages/Interest_rates_burden";
import New_age_asset_managers from "./Pages/Insight/Insightpages/New_age_asset_managers";
import India_on_currency from "./Pages/Insight/Insightpages/India_on_currency";
import India_leverage from "./Pages/Insight/Insightpages/India_leverage";
import Rethinking_corporate_governance from "./Pages/Insight/Insightpages/Rethinking_corporate_governance";
import Robo_advisors from "./Pages/Insight/Insightpages/Robo_advisors";
import Independant_research from "./Pages/Insight/Insightpages/Independant_research";
import Indian_budget_17_18 from "./Pages/Insight/Insightpages/Indian_budget_17_18";
import Trumps_presidency from "./Pages/Insight/Insightpages/Trumps_presidency";
import Germany_and_upcoming_elections from "./Pages/Insight/Insightpages/Germany_and_upcoming_elections";
import Fork_wars from "./Pages/Insight/Insightpages/Fork_wars";
import Pursuit_of_alpha from "./Pages/Insight/Insightpages/Pursuit_of_alpha";
import Research_redux from "./Pages/Insight/Insightpages/Research_redux";
import Assessing_quality_of_research from "./Pages/Insight/Insightpages/Assessing_quality_of_research";
import SalesForce from "./Pages/Insight/Case_Studies/SalesForce";
import Quotemedia from "./Pages/Insight/Case_Studies/Quotemedia";
import GHG_Emmission_Persifoni from "./Pages/Insight/Case_Studies/GHG_Emmission_Persifoni";
import Automated_data_warehouse from "./Pages/Insight/Case_Studies/Automated_data_warehouse";
import Tokenization_of_assets from "./Pages/Insight/Insightpages/Tokenization_of_assets";
import Regaining_inr_position from "./Pages/Insight/Insightpages/Regaining_inr_position";
import Nav_calculations from "./Pages/Insight/Case_Studies/Nav_calculations";
import Intelligent_doc_processing from "./Pages/Insight/Case_Studies/Intelligent_doc_processing";
import Data_managment_for_CRM from "./Pages/Insight/Case_Studies/Data_managment_for_CRM";
import Response_management_system from "./Pages/Insight/Case_Studies/Response_management_system";
import Portfolio_monitoring from "./Pages/Insight/Case_Studies/Portfolio_monitoring";
import Robotic_process_automation from "./Pages/Insight/Case_Studies/Robotic_process_automation";
import Customized_credit_platform from "./Pages/Insight/Case_Studies/Customized_credit_platform";
import Intelligent_dashboard from "./Pages/Insight/Case_Studies/Intelligent_dashboard";
import Fraud_detection from "./Pages/Insight/Case_Studies/Fraud_detection";
import Process_and_cost from "./Pages/Insight/Case_Studies/Process_and_cost";
import Automation_and_custom from "./Pages/Insight/Case_Studies/Automation_and_custom";
import Cutting_edge from "./Pages/Insight/Case_Studies/Cutting_edge";
import Offering_solutions from "./Pages/Insight/Case_Studies/Offering_solutions";
// import Analytical_dashboard_solution from "./Pages/Insight/Case_Studies/Analytical_dashboard_solution";
import Chinaauto from "./Pages/Insight/Infographic/Chinaauto";
import Taxi_aggregator from "./Pages/Insight/Infographic/Taxi_aggregator";
import Laser_Therapy from "./Pages/Insight/Infographic/Laser_Therapy";
import White_label_ATM from "./Pages/Insight/Infographic/White_label_ATM";
import Singularity from "./Pages/Newsletter/Singularity";
import Paisapulse from "./Pages/Newsletter/Paisapulse";
import Rolofdata from "./Pages/Media/Webinarfile/Rolofdata";
import Emerging_trends from "./Pages/Media/Webinarfile/Emerging_trends";
import Augmented_research from "./Pages/Media/Webinarfile/Augmented_research";
import Ecopulse from "./Pages/Product/Ecopulse";
import Coffeepulse from "./Pages/Product/Coffeepulse";
import Creditpulse from "./Pages/Product/Creditpulse";
import Analystpulse from "./Pages/Product/Analystpulse";
import Fidelitypulse from "./Pages/Product/Fidelitypulse";
import Robopulse from "./Pages/Product/Robopulse";
import Pressreleases from "./Pages/Media/Pressreleases";
import Event from "./Pages/Media/Event";
import Decimalnews from "./Pages/Media/Decimalnews";
// import Careers from "./Pages/Careers/Careers";
import Contactus from "./Pages/Contactus";
import Paisapulse_a from "./Pages/Newsletter/Piasapulse/Paisapulse_a";
import Paisapulse_b from "./Pages/Newsletter/Piasapulse/Paisapulse_b";
import Paisapulse_c from "./Pages/Newsletter/Piasapulse/Paisapulse_c";
import Paisapulse_d from "./Pages/Newsletter/Piasapulse/Paisapulse_d";
import Terms from "./Pages/Terms";
import Policy from "./Pages/Policy";
import Disclaimer from "./Pages/Disclaimer";
import Scrolltop from "./Component/Scrolltotop";
import Commingsoon from "./Pages/Commingsoon";
import Sitemap from "./Component/Sitemap";
import Eventback from "./Component/Eventback";
import Event_30_Nov from "./Pages/Media/Events/Event_30_nov";
import Event_22_Nov from "./Pages/Media/Events/Event_22_nov";
import Event_18_Nov from "./Pages/Media/Events/Event_18_nov";
import Event_10_Oct from "./Pages/Media/Events/Event_10_oct";
import Event_05_Oct from "./Pages/Media/Events/Event_05_oct";
import Event_01_Sep from "./Pages/Media/Events/Event_01_sep";
import Event_24_Mar from "./Pages/Media/Events/Event_24_mar";
import Event_21_Nov from "./Pages/Media/Events/Event_21_nov";
import Event_22_Feb from "./Pages/Media/Events/Event_22_feb";
import Event_03_Nov from "./Pages/Media/Events/Event_03_nov";
import Event_21_Aug from "./Pages/Media/Events/Event_21_aug";
import Event_28_Jul from "./Pages/Media/Events/Event_28_jul";
import FintechEntity from "./Pages/Media/PressRelease/FintechEntity";
import Inauguration_Nashik from "./Pages/Media/PressRelease/Inauguration_nashik";
import Tokenization_Funding from "./Pages/Media/PressRelease/Tokenization_funding";
import Riskmanagement from "./Pages/Services/RiskManagement";
import Case_studies from "./Pages/Insight/Case_studies";
import Articles from "./Pages/Insight/Articles";
import Blogs from "./Pages/Insight/Blogs";
import Infographics from "./Pages/Insight/Infographics";
import Event_round_table from "./Pages/Media/Events/Event_round_table";
import Transforming_banking_operations from "./Pages/Insight/Case_Studies/Transforming_banking_operations";
import Technical_support_and_process_automation from "./Pages/Insight/Case_Studies/Technical_support_and_process_automation";
import PageNotFound from "./Component/PageNotFound";
import LandingPopUp from "./Component/Landing_pop_up";
import SmallBusinessAdministration from "./Pages/Services/SmallBusinessAdministration";
import Subscribed_Successfully from "./Component/Subscribed_Successfully";
import Podcast from "./Pages/Insight/Podcast";
import Database_Management_for_Enhanced_DecisionMaking from "./Pages/Insight/Case_Studies/Database_Management_for_Enhanced_DecisionMaking";
import QuantifyingESGRisk from "./Pages/Media/PressRelease/QuantifyingESGRisk";
import DPAOvercomesProjectComplexity from "./Pages/Insight/Insightpages/DPAOvercomesProjectComplexity";
import Quantifying_ESG_Risk from "./Pages/Media/Events/Quantifying_ESG_Risk";
import Successfully_Connected from "./Component/Successfully_Connected";
import Driving_investment_banking_solution from "./Pages/Insight/Case_Studies/Driving_investment_banking_solution";
import FloatingContactForm from "./Component/Floating_Contact";
import ImplementingMRR from "./Pages/Insight/Insightpages/ImplementingMRR";
import ExecutiveRoundtableTheEvolutionOfESG from "./Pages/Media/Events/ExecutiveRoundtableTheEvolutionOfESG";
import HowAIDrivenAutomationRevolutionizes from "./Pages/Insight/Case_Studies/HowAIDrivenAutomationRevolutionizes";
import ImplementingMRRForCompetitiveAdvantage from "./Pages/Media/PressRelease/ImplementingMRRForCompetitiveAdvantage";
import YinandYangodweb3 from "./Pages/Media/Events/YinandYangodweb3";
import Innovation_Summit_2023 from "./Pages/Media/Events/Innovation_Summit_2023";
import EnhancedESGReportingFromVoluminousToClear from "./Pages/Insight/Case_Studies/EnhancedESGReportingFromVoluminousToClear";
import Exploring_Generative_AI_for_Enterprises from "./Pages/Media/Events/Exploring_Generative_AI_for_Enterprises";
import ESG from "./Pages/ESG/ESG";
import ESG_Blogs from "./Pages/ESG/ESG_Insights/ESG_Insights_Tabs/ESG_Blogs";
import ESG_Whitepaper from "./Pages/ESG/ESG_Insights/ESG_Insights_Tabs/ESG_Whitepaper";
import ESG_Newsletter from "./Pages/ESG/ESG_Insights/ESG_Insights_Tabs/ESG_Newsletter";
import ESG_Podcast from "./Pages/ESG/ESG_Insights/ESG_Insights_Tabs/ESG_Podcast";
import ESGNewsSentiMeter from "./Pages/ESG/ESGNewsSentiMeter";
import ESGPrivateEquity from "./Pages/ESG/ESGPrivateEquity";
import ESGGreenInvestPulse from "./Pages/ESG/ESGGreenInvestPulse";
import ESG_USA_NavigatingSustainability from "./Pages/ESG/ESG_Insights/ESG_Insights_Tabs/Whitepapers/Whitepaper_Pages/ESG_USA_NavigatingSustainability";
import ESG_canada_review from "./Pages/ESG/ESG_Insights/ESG_Insights_Tabs/Whitepapers/Whitepaper_Pages/ESG_canada_review";
import ESGSouthAfricaReview2023Whitepaper from "./Pages/ESG/ESG_Insights/ESG_Insights_Tabs/Whitepapers/Whitepaper_Pages/ESGSouthAfricaReview2023Whitepaper";
import ESG_Newsletter_August from "./Pages/ESG/ESG_Insights/ESG_Insights_Tabs/Newsletters/Newsletter_Pages/ESG_Newsletter_August";
import ClimateChangeNavigating from "./Pages/ESG/ESG_Insights/ESG_Insights_Tabs/Blogs/Blogs_Pages/ClimateChangeNavigating";
import ESG_and_its_impact from "./Pages/ESG/ESG_Insights/ESG_Insights_Tabs/Blogs/Blogs_Pages/ESG_and_its_impact";
import ESGPortfolioAllignmentToolsImplement from "./Pages/ESG/ESG_Insights/ESG_Insights_Tabs/Blogs/Blogs_Pages/ESGPortfolioAllignmentToolsImplement";
import Article_8_declarations from "./Pages/ESG/ESG_Insights/ESG_Insights_Tabs/Blogs/Blogs_Pages/Article_8_declarations";
import Indian_regulators from "./Pages/ESG/ESG_Insights/ESG_Insights_Tabs/Blogs/Blogs_Pages/Indian_regulators";
import ESG_rating_methodology from "./Pages/ESG/ESG_Insights/ESG_Insights_Tabs/Blogs/Blogs_Pages/ESG_rating_methodology";
import Accelerate_decarbonisation from "./Pages/ESG/ESG_Insights/ESG_Insights_Tabs/Blogs/Blogs_Pages/Accelerate_decarbonisation";
import Implementation_commitments from "./Pages/ESG/ESG_Insights/ESG_Insights_Tabs/Blogs/Blogs_Pages/Implementation_commitments";
import UNEP from "./Pages/ESG/ESG_Insights/ESG_Insights_Tabs/Blogs/Blogs_Pages/UNEP";
import Denmark from "./Pages/ESG/ESG_Insights/ESG_Insights_Tabs/Blogs/Blogs_Pages/Denmark";
import Emission_performance from "./Pages/ESG/ESG_Insights/ESG_Insights_Tabs/Blogs/Blogs_Pages/Emission_performance";
import Sebastian_vettel from "./Pages/ESG/ESG_Insights/ESG_Insights_Tabs/Blogs/Blogs_Pages/Sebastian_vettel";
import AdvancedFundAccountigSolutions from "./Pages/Insight/Case_Studies/AdvancedFundAccountigSolutions";
import IndianSovereignBondsInTheJPMorganIndex from "./Pages/Insight/Insightpages/IndianSovereignBondsInTheJPMorganIndex";
import OracleGlobalLeaders from "./Pages/Media/PressRelease/OracleGlobalLeaders";
import ESG_Newsletter_October from "./Pages/ESG/ESG_Insights/ESG_Insights_Tabs/Newsletters/Newsletter_Pages/ESG_Newsletter_October";
import IndiaEmergingAsGlobalTrendsetterInESGDisclosures from "./Pages/Media/PressRelease/IndiaEmergingAsGlobalTrendsetterInESGDisclosures";
import TransformativeImpactofGPT from "./Pages/Media/PressRelease/TransformativeImpactofGPT";
import ESGDisclosureList from "./Pages/Media/PressRelease/ESGDisclosureList";
import DramaAroundOpenAI from "./Pages/Insight/Insightpages/DramaAroundOpenAI";
import CentralBankDigitalCurrency from "./Pages/Media/PressRelease/CentralBankDigitalCurrency";
import CanadianRegTechAward from "./Pages/Media/PressRelease/CanadianRegTechAward";
import ESG_requiem from "./Pages/Insight/Insightpages/ESG_requiem";
import LLMDramaAroundOpenAI from "./Pages/Media/PressRelease/LLMDramaAroundOpenAI";
import RequiemforESG from "./Pages/Media/PressRelease/RequiemforESG";
import Unlocking_Success from "./Pages/ESG/ESG_Insights/ESG_Insights_Tabs/Blogs/Blogs_Pages/Unlocking_Success";
import GautamNaiduJoinsDPA from "./Pages/Media/PressRelease/GautamNaiduJoinsDPA";
import TheNewEraOfMoney from "./Pages/Media/PressRelease/TheNewEraOfMoney";
import DPAPlansOverseasExpansion from "./Pages/Media/PressRelease/DPAPlansOverseasExpansion";
import AIVersusAI from "./Pages/Media/PressRelease/AIVersusAI";
import CybersecurityInFintech from "./Pages/Media/PressRelease/CybersecurityInFintech";
import LLMsBeyondChatbots from "./Pages/Media/PressRelease/LLMsBeyondChatbots";
import UnlockingSuccess from "./Pages/Media/PressRelease/UnlockingSuccess";
import UnveilingNetZero from "./Pages/Media/PressRelease/UnveilingNetZero";
import ESG_Newsletter_January from "./Pages/ESG/ESG_Insights/ESG_Insights_Tabs/Newsletters/Newsletter_Pages/ESG_Newsletter_January";
import ShapingNewGrowth from "./Pages/Media/PressRelease/ShapingNewGrowth";
import InvestmentLeaderAutomatesCreditAndLoanOperations from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/InvestmentLeaderAutomatesCreditAndLoanOperations";
import OptimizingSupplierRiskManagement from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/OptimizingSupplierRiskManagement";
import DigitalAssetsCanLookForward from "./Pages/Insight/Insightpages/DigitalAssetsCanLookForward";
import Revolutionizing_Financial_Data_Analysis from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/Revolutionizing_Financial_Data_Analysis";
import Revolutionising_Machinery from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/Revolutionising_Machinery";
import EnhancingBusinessIntelligence from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/EnhancingBusinessIntelligence";
import OptimizingAssetAllocation from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/OptimizingAssetAllocation";
import UnveilingPowerofTokenization from "./Pages/Media/Events/UnveilingPowerofTokenization";
import GlobalTransformations from "./Pages/Media/PressRelease/GlobalTransformations";
import EnhancingAssetForecasting from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/EnhancingAssetForecasting";
import AutomatingDataandNews from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/AutomatingDataandNews";
import BlockchainBeyondBitcoin from "./Pages/Media/PressRelease/BlockchainBeyondBitcoin";
import DigitalAssetsFuture from "./Pages/Media/PressRelease/DigitalAssetsFuture";
import GlobalVaults from "./Pages/Media/PressRelease/GlobalVaults";
import CatastropheModeling from "./Pages/Services/CatastropheModeling";
import Podcast_New from "./Pages/Insight/Podcast_new";
import Smartpipe from "./Pages/Product/Smartpipe";
import PdfQuery from "./Pages/Product/PdfQuery";
import SmartCloudTerms from "./Pages/Product/SmartCloud/SmartCloudTerms";
import SmartCloudPolicy from "./Pages/Product/SmartCloud/SmartCloudPolicy";
import ESG_Newsletter_June from "./Pages/ESG/ESG_Insights/ESG_Insights_Tabs/Newsletters/Newsletter_Pages/ESG_Newsletter_June";
import GreenSignalforESG from "./Pages/ESG/ESG_Insights/ESG_Insights_Tabs/Blogs/Blogs_Pages/GreenSignalforESG";
import PixelsandBullets from "./Pages/Media/PressRelease/PixelsandBullets";
import LendingCompanyTransformsDataManagement from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/LendingCompanyTransformsDataManagement";
import OvercomingDataChallenges from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/OvercomingDataChallenges";
import IndianAssetManagers from "./Pages/ESG/ESG_Insights/ESG_Insights_Tabs/Blogs/Blogs_Pages/IndianAssetManagers";
import LLM_Models from "./Pages/Insight/Whitepapers/LLM_Models";
import TurningDataIntoDollars from "./Pages/Insight/Whitepapers/TurningDataIntoDollars";
import { Pentagon } from "./Component/Pentagon";
import Webinar from "./Pages/Media/Webinar";
import MediaCoverage from "./Pages/Media/Newsroom/media_coverage";
import PressRelease from "./Pages/Media/Newsroom/pressRelease";
import Index_Megamenu from "./Pages/Index_Megamenu";
import DPAEmpowersStudents from "./Pages/Media/PressRelease/DPAEmpowersStudents";
import DilipSahJoinsAsCTO from "./Pages/Media/PressRelease/DilipSahJoinsAsCTO";
import IndustryExpertsSaaS from "./Pages/Media/PressRelease/IndustryExpertsSaaS";
import RollercoasterRideofBitcoin from "./Pages/Media/PressRelease/RollercoasterRideofBitcoin";
import AdheringtoGreenPractices from "./Pages/Media/PressRelease/AdheringtoGreenPractices";
import ESG_Newsletter_July from "./Pages/ESG/ESG_Insights/ESG_Insights_Tabs/Newsletters/Newsletter_Pages/ESG_Newsletter_July";
// import OutsourceSBA from "./Pages/OutsourceSBA/OutsourceSBA";
import SBA_Form_submit from "./Component/SBA_Form_submit";
import {
  CRMLayout,
  FreeLayout,
  MainLayout,
  MegamenuLayout,
  SBALayout,
  SBASubmitLayout,
} from "./Component/Layout";
import BeyondTheEiffelTower from "./Pages/Media/PressRelease/BeyondTheEiffelTower";
import NewsletterNew from "./Pages/Newsletter/NewsletterNew";
import Oracle from "./Pages/Partner/Oracle";
import Whitepaper from "./Pages/Insight/Whitepaper";
import AlternativeDataInvestment from "./Pages/Insight/Whitepapers/AlternativeDataInvestment";
import AmplifiedNeedForTechLed from './Pages/Insight/Whitepapers/AmplifiedNeedForTechLed';
import GettingVisualWithData from './Pages/Insight/Whitepapers/GettingVisualWithData';
import TransformingRentRoll from './Pages/Insight/Case_Studies/NewSuccessStoryFormat/TransformingRentRoll';
import EnhancingPortfolioManagement from './Pages/Insight/Case_Studies/NewSuccessStoryFormat/EnhancingPortfolioManagement';
import TechnicalDebtCrisis from './Pages/Media/PressRelease/TechnicalDebtCrisis';
import GlobalCapabilityCenters from './Pages/Media/PressRelease/GlobalCapabilityCenters';
import Blog_Data from "./Pages/Insight/Blog_Data";
import SBA_Data from "./Pages/Services/SBA_Data";
import BudgetExpectations2024 from "./Pages/Media/PressRelease/BudgetExpectations2024";
import DataAConfidenceBooster from "./Pages/Insight/Whitepapers/DataAConfidenceBooster";
import GlobalAndInstant from "./Pages/Media/PressRelease/GlobalAndInstant";
import ConsultantDatabaseManagement from "./Pages/Services/ConsultantDatabaseManagement";
import InnovatingFinancewithAI from "./Pages/Media/Events/InnovatingFinancewithAI";
import StreamliningConsultant from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/StreamliningConsultant";
import Non_traditional_ways_to_access_crdit_risk from "./Pages/Insight/Insightpages/Non_traditional_ways_to_access_crdit_risk";
import CRMSolutions from "./Pages/Services/CRMSolutions";
import CrmHero from "./Pages/DPACrm/CrmHero";
import CRM_Form_submit from "./Component/CRM_Form_submit";
import HolographicFutureofRegulation from "./Pages/Media/PressRelease/HolographicFutureofRegulation";
import TheHolographicFutureofRegulation from "./Pages/Insight/Insightpages/TheHolographicFutureofRegulation";
import ReshapingDiscovery from "./Pages/Media/PressRelease/ReshapingDiscovery";
import IBM from "./Pages/Partner/IBM";
import PartnersContactUs from "./Component/PartnersContactUs";
import EnhancedRiskManagement from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/EnhancedRiskManagement";
import AcceleratingFundAccounting from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/AcceleratingFundAccounting";
import StreamliningLoanPool from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/StreamliningLoanPool";
import EnhancingInventory from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/EnhancingInventory";
import IndiasModernDayArthashastra from "./Pages/Media/PressRelease/IndiasModernDayArthashastra";
import IndiaModernArthashastra from "./Pages/Insight/Insightpages/IndiaModernArthashastra";
import JobDetail from "./Pages/Careers/CurrentOpenings/JobDetail";
import Clickup from "./Pages/Partner/Clickup";
import EnhancesClickUp from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/EnhancesClickUp";
import ExploringAiImpact from "./Pages/Media/Events/ExploringAiImpact";
import DPAatALTSNY from "./Pages/Media/Events/DPAatALTSNY";
import ThakurGlobalBusinessSchool from "./Pages/Media/Events/ThakurGlobalBusinessSchool";
import FiensteinEvent from "./Pages/Media/Events/FiensteinEvent";
import PareshSharmaRepresents from "./Pages/Media/Events/PareshSharmaRepresents";
import AmericaEastMeet from "./Pages/Media/Events/AmericaEastMeet";
import AvantikaUniversity from "./Pages/Media/Events/AvantikaUniversity";
import GlobalFintechFest2024 from "./Pages/Media/Events/GlobalFintechFest2024";
import OracleCloudWorld from "./Pages/Media/Events/OracleCloudWorld";
import FinovateFall2024 from "./Pages/Media/Events/FinovateFall2024";

import "./Style.css";
import "./Menu.css";
import "./asset/css/Pentagon.css";
import LifeAsADecimal from "./Pages/Careers/LifeAsADecimal";
import OurCulture from "./Pages/Careers/OurCulture";
import JourneyOfADecimal from "./Pages/Careers/JourneyOfADecimal";
import CreatingOpportunity from "./Pages/Careers/CreatingOpportunity";
import CurrentOpenings from "./Pages/Careers/CurrentOpenings";
import LeadingIndianBank from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/LeadingIndianBank";
import OptimizingPortfolioInvestment from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/OptimizingPortfolioInvestment";
import EnhancingCatastropheModeling from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/EnhancingCatastropheModeling";
import ProductPortfolio from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/ProductPortfolio";
import LeadingDataProvider from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/LeadingDataProvider";
import BootingForecast from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/BootingForecast";
import ImprovingOASBondPricing from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/ImprovingOASBondPricing";
import StreamliningOperations from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/StreamliningOperations";
import USAssetManagementFirm from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/USAssetManagementFirm";
import Achieving60FasterProcessing from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/Achieving60FasterProcessing";
import DeliveringPublicFloatData from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/DeliveringPublicFloatData";
import AutomatingFund from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/AutomatingFund";
import ReducingClientQueryTime from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/ReducingClientQueryTime";
import StandardizingBenchmarks from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/StandardizingBenchmarks";
import CraftingDynamicIndustry from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/CraftingDynamicIndustry";
import CurrencyRiskModel from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/CurrencyRiskModel";
import ReducingMaintenance from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/ReducingMaintenance";
import StreamliningInvoice from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/StreamliningInvoice";
import GlobalAlternativesFirm from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/GlobalAlternativesFirm";
import TransformingIPO from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/TransformingIPO";
import RealEstateFinanceFirms from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/RealEstateFinanceFirms";
import ComplianceExcellence from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/ComplianceExcellence";
import EnhancingSBALoan from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/EnhancingSBALoan";
import EnhancingWorkflowEfficiency from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/EnhancingWorkflowEfficiency";
import CostEffectiveExcellence from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/CostEffectiveExcellence";
import DoublingProductivity from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/DoublingProductivity";
import SavinginOverheads from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/SavinginOverheads";
import BoostingSBALoanApprovals from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/BoostingSBALoanApprovals";
import ReducingPenaltyPayments from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/ReducingPenaltyPayments";
import AchievingBacklogClearance from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/AchievingBacklogClearance";
import ImprovingCustomerAcquisition from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/ImprovingCustomerAcquisition";
import AchievingFasterApprovals from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/AchievingFasterApprovals";
import PreventingMillion from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/PreventingMillion";
import InvestorRelations from "./Pages/Services/InvestorRelations";
import RFP from "./Pages/Services/InvestorRelations/RFP";
import DDQ from "./Pages/Services/InvestorRelations/DDQ";
import CDM from "./Pages/Services/InvestorRelations/CDM";
import CRM from "./Pages/Services/InvestorRelations/CRM";
import OracleAutonomousDatabase from "./Pages/Insight/Case_Studies/NewSuccessStoryFormat/OracleAutonomousDatabase";
import ComprehensiveInvestor from "./Pages/Media/PressRelease/ComprehensiveInvestor";
import GenAIConclaveAwards2024 from "./Pages/Media/Events/GenAIConclaveAwards2024";
import GIFTIFSCConference2024 from "./Pages/Media/Events/GIFTIFSCConference2024";
import HarvestingOpportunities from "./Pages/Media/Events/HarvestingOpportunities";
import IFSCConference2024 from "./Pages/Media/Events/IFSCConference2024";
import CloudServices from "./Pages/Services/CloudServices";
import FundManagement from "./Pages/Services/FundManagement";
import IFSCATalentVista2024 from "./Pages/Media/Events/IFSCATalentVista2024";
import InnovationMeetsStrategy from "./Pages/Media/Events/InnovationMeetsStrategy";
import SmartStrategies from "./Pages/Media/Events/SmartStrategies";
import TheNewEconomics from "./Pages/Media/PressRelease/TheNewEconomics";
import LeveragingAgileScrum from "./Pages/Insight/Insightpages/LeveragingAgileScrum";
import BridgeLendingSolutions from "./Pages/Services/BridgeLendingSolutions";
import { Magazine } from "./Pages/Magazine";
import PredictiveIntelligenceFinancial from "./Pages/Insight/Whitepapers/PredictiveIntelligenceFinancial";
import UnleashInnovation from "./Pages/Media/Events/UnleashInnovation";
import ContemplatingCyberspace from "./Pages/Media/PressRelease/ContemplatingCyberspace";
import Newsletter_old from "./Pages/Newsletter/Newsletter_old";
import Hackathon2025 from "./Pages/Media/PressRelease/Hackathon2025";
import NavigatingAILandscape from "./Pages/Media/PressRelease/NavigatingAILandscape";
import ShaileshDhuriGenAI from "./Pages/Media/PressRelease/ShaileshDhuriGenAI";
// import NavigatingAILandscape from "./Pages/Insight/Whitepapers/NavigatingAILandscape";


// import QAEngineer from "./Pages/Careers/CurrentOpenings/QAEngineer";

// import "../src/asset/css/megamenu.css";
// import "../src/asset/css/new_footer.css";
// import "./asset/css/new.css";
// import "../src/asset/css/cookie_tracker.css";
// import "./asset/css/podcast_new.css";
// import "./asset/css/outsourcesba.css";
// import "./asset/css/oracle.css";
// import Oracle_Form_submit from "./Component/Oracle_Form_submit";

// const usePageTracking = () => {
//   const location = useLocation();

//   useEffect(() => {
//     pageview(location.pathname + location.search);
//   }, [location]);
// };

const App = () => {
  localStorage.setItem("isOpen", 1);

  // usePageTracking();

  return (
    <>
      <Router>
        <Scrolltop />
        <FloatingContactForm />


        <Switch>


          <MainLayout exact path="/" component={Index_new} />

          <MainLayout exact path="/who-we-are" component={Whoweare} />
          <MainLayout exact path="/whatwedo" component={Whatwedo} />
          <MainLayout exact path="/management" component={Management} />

          <MainLayout exact path="/awards" component={Awards} />
          <MainLayout exact path="/technologyedge" component={Technologyedge} />
          <MainLayout
            exact
            path="/valueproposition"
            component={Valueproposition}
          />
          {/* <MainLayout exact path="/testimonials" component={Testimonials} /> */}
          <MainLayout
            exact
            path="/structure-innovation"
            component={Structure_innovation}
          />
          <MainLayout exact path="/csr" component={Csr} />
          <MainLayout
            exact
            path="/energy-efficiency"
            component={Energy_efficiency}
          />
          <MainLayout
            exact
            path="/quantum-computing"
            component={Quantum_computing}
          />


          <MainLayout exact path="/decimal-sutras" component={DecimalSutras} />

          <MainLayout
            exact
            path="/data-solution-in-us"
            component={Datasolution}
          />
          <MainLayout
            exact
            path="/business-intelligence-solution"
            component={BusinessIntelligence}
          />
          <MainLayout
            exact
            path="/artificial-intelligence-solutions"
            component={Artificialintelligence}
          />
          <MainLayout
            exact
            path="/leading-research-solution-company"
            component={Researchsolution}
          />
          <MainLayout
            exact
            path="/risk-management-company-in-us"
            component={Riskmanagement}
          />

          <MainLayout
            exact
            path="/cloud-services"
            component={CloudServices}
          />

          <MainLayout
            exact
            path="/small-business-administration"
            component={SmallBusinessAdministration}
          />
          <MainLayout
            exact
            path="/catastrophe-modeling-services"
            component={CatastropheModeling}
          />
          <MainLayout
            exact
            path="/consultant-database-management"
            component={ConsultantDatabaseManagement}
          />
          <MainLayout
            exact
            path="/crm-solutions"
            component={CRMSolutions}
          />

          <MainLayout
            exact
            path="/fund-management-and-private-equity-support-solutions"
            component={FundManagement}
          />
          <MainLayout
            exact
            path="/bridge-lending-solutions"
            component={BridgeLendingSolutions}
          />

          {SBA_Data.map((item, index) => (
            <MainLayout
              exact
              key={index}
              path={typeof item.link === 'function' ? item.link.bind(item)() : item.link1} // Call `link()` if it's a function, otherwise use `link1`
              component={item.content}
            />
          ))}

          {/* Article pages starts from here   */}

          <MainLayout
            exact
            path="/articles/turning-data-into-dollars-combining-advanced-analytics-and-tech-to-unlock-intel-for-hedge-funds"
            component={TurningDataIntoDollars}
          />

          <MainLayout
            exact
            path="/articles/leveraging-large-language-models-to-minimize-technical-debt"
            component={LLM_Models}
          />

          <MainLayout
            exact
            path="/articles/implementing-machine-readable-regulation-for-competitive-advantage"
            component={ImplementingMRR}
          />

          <MainLayout
            exact
            path="/articles/dpa-overcomes-project-complexity-improves-performance-with-autonomous-database"
            component={DPAOvercomesProjectComplexity}
          />

          <MainLayout
            exact
            path="/articles/tokenization-of-assets-a-new-paradigm-for-businesses"
            component={Tokenization_of_assets}
          />
          <MainLayout
            exact
            path="/articles/regaining-inr-rightful-position-in-regional-trade"
            component={Regaining_inr_position}
          />
          <MainLayout
            exact
            path="/articles/how-can-asset-managers-engage-with-businesses-to-accelerate-drive-towards-net-zero"
            component={How_can_asset_managers}
          />
          <MainLayout
            exact
            path="/articles/pe-funds-and-esg-challenges"
            component={PE_funds}
          />
          <MainLayout
            exact
            path="/articles/challenges-in-operationalizing-esg-norms-in-asset-management"
            component={Challenges_in_ESG}
          />
          <MainLayout
            exact
            path="/articles/solving-challenges-in-esg-investing"
            component={Solving_challenges}
          />
          <MainLayout
            exact
            path="/articles/data-visualization-and-the-art-of-effective-storytelling"
            component={Data_visualization}
          />
          <MainLayout
            exact
            path="/articles/how-asset-managers-are-leveraging-natural-language-processing-techniques"
            component={NLP_technique}
          />
          <MainLayout
            exact
            path="/articles/private-equity-adopting-a-data-first-approach"
            component={Private_equity}
          />
          <MainLayout
            exact
            path="/articles/adopting-changes-in-investment-research-methodologies-roadmap-to-institutional-transformation"
            component={Roadmap_to_institutional}
          />
          <MainLayout
            exact
            path="/articles/big-data-and-analytics-the-way-forward-for-asset-managers"
            component={Way_forward_for_asset_management}
          />
          <MainLayout
            exact
            path="/articles/disrupting-investment-research-process"
            component={Disrupting_asset_management}
          />
          <MainLayout
            exact
            path="/articles/data-analytics-in-asset-backed-securities"
            component={Data_analytics_in_asset}
          />
          <MainLayout
            exact
            path="/articles/role-of-advanced-analytics-in-redefining-retail-banking"
            component={Role_of_advanced_analytics}
          />
          <MainLayout
            exact
            path="/articles/real-interest-rates-burder-on-indian-businesses"
            component={Interest_rates_burden}
          />
          <MainLayout
            exact
            path="/articles/evolution-of-the-new-age-avatars-of-asset-managers"
            component={New_age_asset_managers}
          />
          <MainLayout
            exact
            path="/articles/how-can-india-benefit-from-being-on-the-currency-watchlist-of-usa"
            component={India_on_currency}
          />
          <MainLayout
            exact
            path="/articles/non-traditional-to-access-credit-risk"
            component={Non_traditional_ways_to_access_crdit_risk}
          />
          <MainLayout
            exact
            path="/articles/india-leverage"
            component={India_leverage}
          />
          <MainLayout
            exact
            path="/articles/rethinking-corporate-governance"
            component={Rethinking_corporate_governance}
          />
          <MainLayout
            exact
            path="/articles/robo-advisors"
            component={Robo_advisors}
          />
          <MainLayout
            exact
            path="/articles/independant-research"
            component={Independant_research}
          />
          <MainLayout
            exact
            path="/articles/indian-budget-17-18"
            component={Indian_budget_17_18}
          />
          <MainLayout
            exact
            path="/articles/trumps-presidency"
            component={Trumps_presidency}
          />
          <MainLayout
            exact
            path="/articles/germany-and-upcoming-elections"
            component={Germany_and_upcoming_elections}
          />
          <MainLayout exact path="/articles/fork-wars" component={Fork_wars} />
          <MainLayout
            exact
            path="/articles/pursuit-of-alpha"
            component={Pursuit_of_alpha}
          />
          <MainLayout
            exact
            path="/articles/research-redux"
            component={Research_redux}
          />
          <MainLayout
            exact
            path="/articles/assessing-quality-of-research"
            component={Assessing_quality_of_research}
          />

          <MainLayout
            exact
            path="/articles/inclusion-indian-sovereign-bonds-jpmorgan-index"
            component={IndianSovereignBondsInTheJPMorganIndex}
          />
          <MainLayout
            exact
            path="/articles/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot"
            component={DramaAroundOpenAI}
          />

          <MainLayout
            exact
            path="/articles/is-it-time-to-write-esg-requiem-not-quite"
            component={ESG_requiem}
          />

          <MainLayout
            exact
            path="/articles/digital-assets-can-look-forward-to-a-brighter-future-in-2024"
            component={DigitalAssetsCanLookForward}
          />

          <MainLayout
            exact
            path="/articles/the-holographic-future-of-regulation-why-we-need-a-radical-new-approach-unravelling-the-regulatory-knot"
            component={TheHolographicFutureofRegulation}
          />

          <MainLayout
            exact
            path="/articles/india-modern-day-arthashastra-a-ministry-of-economic-security"
            component={IndiaModernArthashastra}
          />

          <MainLayout
            exact
            path="/articles/leveraging-agile-scrum-and-critical-path-method-cpm-for-enhanced-software-project-management"
            component={LeveragingAgileScrum}
          />

          {/* Article pages Ends from here   */}


          {/* blog pages starts from here */}
          {Blog_Data.map((item, index) => (
            <MainLayout
              exact
              key={index}
              // path={`${item.link1()}` || item.link} 
              // path={item.link1 ? item.link1.bind(item)() : item.link}
              path={item.link}
              component={item.content}
            />
          ))}

          {/* blog pages Ends from here */}

          {/* Infographic pages starts from here */}
          <MainLayout
            exact
            path="/infographics/china-auto-finance-market"
            component={Chinaauto}
          />
          <MainLayout
            exact
            path="/infographics/taxi-aggregator-market"
            component={Taxi_aggregator}
          />
          {/* <MainLayout
            exact
            path="/infographics/the-indian-tea-industry"
            component={The_indian_industry}
          /> */}
          <MainLayout
            exact
            path="/infographics/laser-therapy"
            component={Laser_Therapy}
          />
          <MainLayout
            exact
            path="/infographics/white-label-atm"
            component={White_label_ATM}
          />

          {/* Case Studies pages starts from here */}
          <MainLayout
            exact
            path="/case-studies/delivering-comprehensive-data-solutions-for-a-leading-financial-data-aggregator-and-provider"
            component={Quotemedia}
          />
          <MainLayout
            exact
            path="/case-studies/tailoring-process-automation-to-provide-quality-data-in-salesforce-crm"
            component={SalesForce}
          />
          {/* <MainLayout
            exact
            path="/case-studies/automated-and-dynamic-analytical-dashboards-solution-for-an-indian-pe-firm"
            component={Analytical_dashboard_solution}
          /> */}
          <MainLayout
            exact
            path="/case-studies/fraud-detection-and-analytics-for-a-leading-german-reinsurer"
            component={Fraud_detection}
          />
          <MainLayout
            exact
            path="/case-studies/process-and-cost-efficiency-through-structured-data-management-solutions"
            component={Process_and_cost}
          />
          <MainLayout
            exact
            path="/case-studies/salesforce-automation-to-simplify-your-complex-processes"
            component={Automation_and_custom}
          />
          <MainLayout
            exact
            path="/case-studies/cutting-edge-data-solutions-for-a-corporate-client"
            component={Cutting_edge}
          />
          <MainLayout
            exact
            path="/case-studies/offering-solutions-for-every-asset-managers"
            component={Offering_solutions}
          />
          <MainLayout
            exact
            path="/case-studies/nav-calculations-for-portfolio-management"
            component={Nav_calculations}
          />
          <MainLayout
            exact
            path="/case-studies/intelligent-document-processing-of-rent-rolls-for-an-asset-manager"
            component={Intelligent_doc_processing}
          />
          <MainLayout
            exact
            path="/case-studies/data-managment-for-efficient-crm-usage"
            component={Data_managment_for_CRM}
          />
          <MainLayout
            exact
            path="/case-studies/response-management-system-for-fund-raising"
            component={Response_management_system}
          />
          <MainLayout
            exact
            path="/case-studies/automated-portfolio-monitoring-system-for-a-private-equity-firm"
            component={Portfolio_monitoring}
          />
          <MainLayout
            exact
            path="/case-studies/robotic-process-automation-for-credit-funds"
            component={Robotic_process_automation}
          />
          <MainLayout
            exact
            path="/case-studies/customized-credit-platform-for-financial-institution"
            component={Customized_credit_platform}
          />
          <MainLayout
            exact
            path="/case-studies/intelligent-dashboard-solutions-for-banks"
            component={Intelligent_dashboard}
          />

          <MainLayout
            exact
            path="/case-studies/automated-data-warehouse-and-back-testing-solutions-for-global-index-provider-to-achieve-financial-benefits"
            component={Automated_data_warehouse}
          />
          <MainLayout
            exact
            path="/case-studies/extraction-of-ghg-emission-data-from-esg-disclosure-reports"
            component={GHG_Emmission_Persifoni}
          />

          <MainLayout
            exact
            path="/case-studies/transforming-banking-operations-with-advanced-data-analytics"
            component={Transforming_banking_operations}
          />
          <MainLayout
            exact
            path="/case-studies/streamlining-technical-support-and-enhancing-efficiency-through-process-automation"
            component={Technical_support_and_process_automation}
          />

          <MainLayout
            exact
            path="/case-studies/improving-database-management-for-enhanced-decision-making-in-the-alternate-fixed-income-industry"
            component={Database_Management_for_Enhanced_DecisionMaking}
          />

          {/* <MainLayout
            exact
            path="/case-studies/optimum-data-analytics-solution-for-a-leading-indian-bank"
            component={Optimum_Data_Analytics_Solution_For_A_Leading_Indian_Bank}
          /> */}

          <MainLayout
            exact
            path="/case-studies/driving-investment-banking-success-decimals-comprehensive-solutions-for-valuation-research-and-trade-recommendations"
            component={Driving_investment_banking_solution}
          />

          <MainLayout
            exact
            path="/case-studies/how-ai-driven-automation-revolutionizes-a-leading-european-industrial-company"
            component={HowAIDrivenAutomationRevolutionizes}
          />

          <MainLayout
            exact
            path="/case-studies/enhanced-esg-reporting-from-voluminous-data-to-clear-insights"
            component={EnhancedESGReportingFromVoluminousToClear}
          />

          <MainLayout
            exact
            path="/case-studies/advanced-fund-accounting-solutions-for-top-financial-services-group-in-mena-region"
            component={AdvancedFundAccountigSolutions}
          />

          {/* new case studies start from here */}

          <MainLayout
            exact
            path="/case-studies/investment-leader-automates-credit-and-loan-operations-achieving-remarkable-efficiency-and-precision"
            component={InvestmentLeaderAutomatesCreditAndLoanOperations}
          />

          <MainLayout
            exact
            path="/case-studies/optimizing-supplier-risk-management-unleashing-the-power-of-advanced-analytics-for-seamless-operation"
            component={OptimizingSupplierRiskManagement}
          />

          <MainLayout
            exact
            path="/case-studies/revolutionizing-financial-data-analysis-for-global-indices"
            component={Revolutionizing_Financial_Data_Analysis}
          />

          <MainLayout
            exact
            path="/case-studies/revolutionising-machinery-and-equipment-data-management-with-web-scraping-and-advanced-analytics"
            component={Revolutionising_Machinery}
          />

          <MainLayout
            exact
            path="/case-studies/financial-data-extraction-global-index-provider"
            component={EnhancingBusinessIntelligence}
          />

          <MainLayout
            exact
            path="/case-studies/wealth-management-solution"
            component={OptimizingAssetAllocation}
          />

          <MainLayout
            exact
            path="/case-studies/enhancing-asset-forecasting-capabilities-for-a-leading-financial-institution"
            component={EnhancingAssetForecasting}
          />

          <MainLayout
            exact
            path="/case-studies/automating-data-and-news-updation-for-enhanced-index-management"
            component={AutomatingDataandNews}
          />

          <MainLayout
            exact
            path="/case-studies/a-lending-company-transforms-data-management-a-case-study-in-efficiency"
            component={LendingCompanyTransformsDataManagement}
          />

          <MainLayout
            exact
            path="/case-studies/overcoming-data-challenges-to-build-a-credible-esg-index"
            component={OvercomingDataChallenges}
          />

          <MainLayout
            exact
            path="/case-studies/transforming-rent-roll-data-management-efficiency-and-automation-with-dpas-data-ingestion-platform"
            component={TransformingRentRoll}
          />

          <MainLayout
            exact
            path="/case-studies/enhancing-portfolio-management-through-advanced-analytics-and-machine-learning"
            component={EnhancingPortfolioManagement}
          />

          <MainLayout
            exact
            path="/case-studies/streamlining-consultant-database-management-for-enhanced-efficiency"
            component={StreamliningConsultant}
          />

          <MainLayout
            exact
            path="/case-studies/enhanced-risk-management-capabilities-for-a-leading-us-based-asset-management-firm"
            component={EnhancedRiskManagement}
          />

          <MainLayout
            exact
            path="/case-studies/reducing-fund-processing-time-by-for-a-mena-asset-management-firm"
            component={AcceleratingFundAccounting}
          />

          <MainLayout
            exact
            path="/case-studies/cutting-loan-pool-acquisition-time-by-for-a-leading-us-asset-management-firm"
            component={StreamliningLoanPool}
          />

          <MainLayout
            exact
            path="/case-studies/delivering-efficiency-gains-through-predictive-analytics-for-a-us-asset-advisory-firm"
            component={EnhancingInventory}
          />

          <MainLayout
            exact
            path="/case-studies/dpas-implementation-expertise-enhances-clickups-full-potential"
            component={EnhancesClickUp}
          />

          <MainLayout
            exact
            path="/case-studies/the-impact-of-predictive-analytics-on-strategic-growth-in-a-leading-indian-bank"
            component={LeadingIndianBank}
          />

          <MainLayout
            exact
            path="/case-studies/optimizing-portfolio-investment-decisions-for-a-leading-middle-eastern-asset-management-firm"
            component={OptimizingPortfolioInvestment}
          />

          <MainLayout
            exact
            path="/case-studies/enhancing-catastrophe-modeling-accuracy-for-a-uk-based-reinsurer-with-4000-models"
            component={EnhancingCatastropheModeling}
          />

          <MainLayout
            exact
            path="/case-studies/driving-1.5m-monthly-profit-increase-with-product-portfolio-optimization-for-a-mena-fmcg"
            component={ProductPortfolio}
          />

          <MainLayout
            exact
            path="/case-studies/achieving-90-accuracy-in-industry-classification-for-a-leading-us-financial-data-provider"
            component={LeadingDataProvider}
          />

          <MainLayout
            exact
            path="/case-studies/boosting-forecast-accuracy-by-30-with-advanced-inflation-models-for-a-global-hedge-fund"
            component={BootingForecast}
          />

          <MainLayout
            exact
            path="/case-studies/improving-oas-bond-pricing-accuracy-by-25-for-a-global-investment-firm"
            component={ImprovingOASBondPricing}
          />

          <MainLayout
            exact
            path="/case-studies/streamlining-operations-with-oracle-apex-and-40-cost-reduction-in-inventory-management"
            component={StreamliningOperations}
          />

          <MainLayout
            exact
            path="/case-studies/us-asset-management-firm-boosts-security-with-30-faster-operations-and-zero-downtime"
            component={USAssetManagementFirm}
          />

          <MainLayout
            exact
            path="/case-studies/achieving-60-faster-processing-times-in-bridge-lending-with-automated-processes"
            component={Achieving60FasterProcessing}
          />

          <MainLayout
            exact
            path="/case-studies/delivering-public-float-data-50-hours-faster-empowering-smarter-investment-decisions"
            component={DeliveringPublicFloatData}
          />

          <MainLayout
            exact
            path="/case-studies/automating-fund-classification-for-2600-funds-enhancing-insights-and-efficiency"
            component={AutomatingFund}
          />

          <MainLayout
            exact
            path="/case-studies/reducing-client-query-time-by-29-ensuring-100-support-coverage-with-an-offshore-team"
            component={ReducingClientQueryTime}
          />

          <MainLayout
            exact
            path="/case-studies/standardizing-benchmarks-across-6700-funds-elevating-investment-precision"
            component={StandardizingBenchmarks}
          />

          <MainLayout
            exact
            path="/case-studies/crafting-a-dynamic-industry-classification-system-enabling-real-time-insights-for-investment-strategies"
            component={CraftingDynamicIndustry}
          />

          <MainLayout
            exact
            path="/case-studies/achieving-50-improvement-in-currency-risk-model-accuracy"
            component={CurrencyRiskModel}
          />

          <MainLayout
            exact
            path="/case-studies/reducing-maintenance-downtime-by-90-with-ai-driven-solutions"
            component={ReducingMaintenance}
          />

          <MainLayout
            exact
            path="/case-studies/streamlining-invoice-processing-by-85-for-efficient-transaction-management"
            component={StreamliningInvoice}
          />

          <MainLayout
            exact
            path="/case-studies/achieving-99-data-accuracy-in-contact-management-for-global-alternatives-firm"
            component={GlobalAlternativesFirm}
          />

          <MainLayout
            exact
            path="/case-studies/transforming-ipo-data-management-with-6-hour-lead-for-stock-market-research"
            component={TransformingIPO}
          />

          <MainLayout
            exact
            path="/case-studies/how-automation-doubled-loan-processing-speed-and-cut-errors-by-50-for-real-estate-finance-firms"
            component={RealEstateFinanceFirms}
          />

          <MainLayout
            exact
            path="/case-studies/how-to-prevent-fraud-in-real-eEstate-finance-a-case-study-on-compliance-excellence"
            component={ComplianceExcellence}
          />

          <MainLayout
            exact
            path="/case-studies/enhancing-sba-loan-approval-rates-through-precise-documentation-and-ensuring-sba-compliance"
            component={EnhancingSBALoan}
          />

          <MainLayout
            exact
            path="/case-studies/enhancing-workflow-efficiency-and-reducing-costs-with-precision"
            component={EnhancingWorkflowEfficiency}
          />

          <MainLayout
            exact
            path="/case-studies/cost-effective-excellence–saving-lenders-60-in-overheads-while-enhancing-sba-loan-quality"
            component={CostEffectiveExcellence}
          />

          <MainLayout
            exact
            path="/case-studies/doubling-productivity-achieving-2x-faster-loan-processing-with-50-fewer-errors"
            component={DoublingProductivity}
          />

          <MainLayout
            exact
            path="/case-studies/saving-60-in-overheads-while-enhancing-sba-loan-quality-and-speed-by-40"
            component={SavinginOverheads}
          />

          <MainLayout
            exact
            path="/case-studies/boosting-sba-loan-approvals-by-30-with-99-documentation-accuracy"
            component={BoostingSBALoanApprovals}
          />

          <MainLayout
            exact
            path="/case-studies/reducing-penalty-payments-by-80-90-with-efficient-sba-reporting"
            component={ReducingPenaltyPayments}
          />

          <MainLayout
            exact
            path="/case-studies/achieving-100-backlog-clearance-in-less-than-2-months-for-sba-audit-readiness"
            component={AchievingBacklogClearance}
          />

          <MainLayout
            exact
            path="/case-studies/improving-customer-acquisition-by-25-faster-screening-and-40-increased-efficiency"
            component={ImprovingCustomerAcquisition}
          />

          <MainLayout
            exact
            path="/case-studies/achieving-40-faster-approvals-and-doubling-application-throughput"
            component={AchievingFasterApprovals}
          />

          <MainLayout
            exact
            path="/case-studies/preventing-5-million-in-fraud-and-increasing-efficiency-by-40-with-compliance-excellence"
            component={PreventingMillion}
          />

          <MainLayout
            exact
            path="/case-studies/dpas-innovative-approach-enhances-oracle-autonomous-database-implementation"
            component={OracleAutonomousDatabase}
          />

          {/* Case Studies pages Ends from here */}

          {/* Whitepaer */}

          {/* <MainLayout
            exact
            path="/whitepapers/navigating-the-ai-landscape-key-trends-shaping-2025-and-beyond"
            component={NavigatingAILandscape}
          /> */}

          <MainLayout
            exact
            path="/whitepapers/predictive-intelligence-in-financial-services-preparing-for-the-future"
            component={PredictiveIntelligenceFinancial}
          />

          <MainLayout
            exact
            path="/whitepapers/data-a-confidence-booster-in-commercial-real-estate-cre-lending-decisions"
            component={DataAConfidenceBooster}
          />

          <MainLayout
            exact
            path="/whitepapers/getting-visual-with-data-for-personalized-banking"
            component={GettingVisualWithData}
          />

          <MainLayout
            exact
            path="/whitepapers/the-amplified-need-for-tech-led-intelligence-in-insurance"
            component={AmplifiedNeedForTechLed}
          />

          <MainLayout
            exact
            path="/whitepapers/alternative-data-that-can-power-successful-alternative-investments"
            component={AlternativeDataInvestment}
          />

          <MainLayout
            exact
            path="/whitepapers/turning-data-into-dollars-combining-advanced-analytics-and-tech-to-unlock-intel-for-hedge-funds"
            component={TurningDataIntoDollars}
          />

          <MainLayout
            exact
            path="/whitepapers/leveraging-large-language-models-to-minimize-technical-debt"
            component={LLM_Models}
          />

          {/* Whitepaer */}

          {/* <MainLayout exact path="/marketweekly" component={Marketweekly} /> */}
          <MainLayout exact path="/singularity" component={Singularity} />
          <MainLayout exact path="/paisapulse" component={Paisapulse} />
          <MainLayout exact path="/paisapulse-a" component={Paisapulse_a} />
          <MainLayout exact path="/paisapulse-b" component={Paisapulse_b} />
          <MainLayout exact path="/paisapulse-c" component={Paisapulse_c} />
          <MainLayout exact path="/paisapulse-d" component={Paisapulse_d} />

          {/* <MainLayout exact path="/samplereport" component={Samplereport} /> */}
          <MainLayout exact path="/newsletter_old" component={Newsletter_old} />

          <MainLayout exact path="/newsletter" component={NewsletterNew} />

          <MainLayout exact path="/pressreleases" component={Pressreleases} />
          <MainLayout exact path="/event" component={Event} />
          <MainLayout exact path="/decimalnews" component={Decimalnews} />

          <MainLayout exact path="/rolofdata" component={Rolofdata} />
          <MainLayout
            exact
            path="/emerging-trends"
            component={Emerging_trends}
          />
          <MainLayout
            exact
            path="/augmented-research"
            component={Augmented_research}
          />

          <MainLayout
            exact
            path="/convergence-of-ai-and-iot"
            component={Event_30_Nov}
          />
          <MainLayout
            exact
            path="/application-of-machine-learning-in-catastrophe-modeling"
            component={Event_22_Nov}
          />
          <MainLayout
            exact
            path="/data-analytics-making-it-work"
            component={Event_18_Nov}
          />
          <MainLayout
            exact
            path="/round-table-by-dpa"
            component={Event_10_Oct}
          />
          <MainLayout exact path="/techniche-2022" component={Event_05_Oct} />
          <MainLayout
            exact
            path="/are-developed-markets-riskier"
            component={Event_01_Sep}
          />
          <MainLayout
            exact
            path="/indo-french-chamber-of-commerce"
            component={Event_24_Mar}
          />
          <MainLayout
            exact
            path="/chetana-institutes-of-management"
            component={Event_21_Nov}
          />
          <MainLayout
            exact
            path="/mipm-ies-mcrc-convention"
            component={Event_22_Feb}
          />
          <MainLayout exact path="/weschool-mumbai" component={Event_03_Nov} />
          <MainLayout
            exact
            path="/professional-risk-managers-international-association"
            component={Event_21_Aug}
          />
          <MainLayout
            exact
            path="/nl-dalmia-college"
            component={Event_28_Jul}
          />

          <MainLayout
            exact
            path="/executive-roundtable-on-portfolio-alignment-tools"
            component={Event_round_table}
          />

          <MainLayout
            exact
            path="/executive-roundtable-the-evolution-of-esg-reporting-implications-for-fund-managers"
            component={ExecutiveRoundtableTheEvolutionOfESG}
          />

          <MainLayout
            exact
            path="/yin-&-yang-of-web3-&-ai-to-transform-insurance-processes"
            component={YinandYangodweb3}
          />

          <MainLayout
            exact
            path="/innovation-summit-2023-at-sda-bocconi-asia-center"
            component={Innovation_Summit_2023}
          />

          <MainLayout
            exact
            path="/executive-roundtable-exploring-generative-ai-for-enterprises"
            component={Exploring_Generative_AI_for_Enterprises}
          />

          <MainLayout
            exact
            path="/unveiling-the-transformative-power-of-tokenization-insights-from-the-cfa-india-fintech-conference"
            component={UnveilingPowerofTokenization}
          />

          <MainLayout
            exact
            path="/executive-roundtable-on-innovating-finance-with-ai"
            component={InnovatingFinancewithAI}
          />

          <MainLayout
            exact
            path="/quantifying-esg-risk"
            component={Quantifying_ESG_Risk}
          />

          <MainLayout
            exact
            path="/shailesh-dhuris-talk-at-thakur-global-business-school-navigating-emerging-markets"
            component={ThakurGlobalBusinessSchool}
          />

          <MainLayout
            exact
            path="/decimal-point-analytics-proudly-supported-the-annual-feinstein-summer-concert-featuring-gwen-stefani"
            component={FiensteinEvent}
          />

          <MainLayout
            exact
            path="/paresh-sharma-represents-decimal-point-analytics-at-the-5th-picup-fintech-conference"
            component={PareshSharmaRepresents}
          />

          <MainLayout
            exact
            path="/decimal-point-analytics-at-america-east-meet-nyc-2024-advancing-data-driven-insights"
            component={AmericaEastMeet}
          />

          <MainLayout
            exact
            path="/arun-singh-at-avantika-universitys-student-orientation-ceremony-aarambh"
            component={AvantikaUniversity}
          />

          <MainLayout
            exact
            path="/global-fintech-fest-2024-insights-from-the-panel-discussion-on-gift-city"
            component={GlobalFintechFest2024}
          />

          <MainLayout
            exact
            path="/finovate-fall-2024-connecting-with-industry-leaders"
            component={FinovateFall2024}
          />

          <MainLayout
            exact
            path="/oracle-cloud-world-ai-powered-data-management-with-dilip-sah"
            component={OracleCloudWorld}
          />

          <MainLayout
            exact
            path="/exploring-ais-impact-on-the-insurance-sector-insights-from-the-mena-insurTech-association-summit-2024"
            component={ExploringAiImpact}
          />

          <MainLayout
            exact
            path="/decimal-point-analytics-at-altsny-june-2024"
            component={DPAatALTSNY}
          />

          <MainLayout
            exact
            path="/decimal-point-analytics-at-gen-ai-conclave-awards-2024"
            component={GenAIConclaveAwards2024}
          />

          <MainLayout
            exact
            path="/decimal-point-analytics-at-the-gift-ifsc-conference-2024-in-new-york"
            component={GIFTIFSCConference2024}
          />

          <MainLayout
            exact
            path="/harvesting-opportunities-funds-in-gift-city-scaling-new-heights"
            component={HarvestingOpportunities}
          />

          <MainLayout
            exact
            path="/decimal-point-analytics-at-ifsca-san-francisco-conference-2024"
            component={IFSCConference2024}
          />

          <MainLayout
            exact
            path="/decimal-point-analytics-at-ifsca-official-talent-vista-2024"
            component={IFSCATalentVista2024}
          />

          <MainLayout
            exact
            path="/where-innovation-meets-strategy-an-exclusive-boardroom-session"
            component={InnovationMeetsStrategy}
          />

          <MainLayout
            exact
            path="/roundtable-discussion-on-smart-strategies-for-multi-cloud-environments-and-data-integration"
            component={SmartStrategies}
          />

          <MainLayout
            exact
            path="/roundtable-discussion-on-unleash-innovation-build-the-next-big-thing"
            component={UnleashInnovation}
          />

          <MainLayout exact path="/ecopulse" component={Ecopulse} />
          <MainLayout exact path="/coffeepulse" component={Coffeepulse} />
          <MainLayout exact path="/creditpulse" component={Creditpulse} />
          <MainLayout exact path="/analystpulse" component={Analystpulse} />
          <MainLayout exact path="/fidelitypulse" component={Fidelitypulse} />
          <MainLayout exact path="/robopulse" component={Robopulse} />

          {/* <MainLayout exact path="/careers" component={Careers} /> */}
          <MainLayout exact path="/contact-us" component={Contactus} />

          <MainLayout exact path="/commingsoon" component={Commingsoon} />
          <MainLayout exact path="/form-submit" component={Form_submit} />

          <MainLayout exact path="/oracle" component={Oracle} />

          <MainLayout
            exact
            path="/subscribed-success"
            component={Subscribed_Successfully}
          />
          <MainLayout
            exact
            path="/successfully-connected"
            component={Successfully_Connected}
          />

          {/* <MainLayout exact path="/writemessage" component={Writemessage} /> */}
          <MainLayout exact path="/subscribe" component={Subscribe} />
          <MainLayout exact path="/insightback" component={Insightback} />
          <MainLayout exact path="/blogback" component={Blogback} />
          <MainLayout exact path="/infoback" component={Infoback} />
          <MainLayout exact path="/casestudyback" component={Casestudyback} />
          <MainLayout exact path="/eventback" component={Eventback} />
          <MainLayout exact path="/terms" component={Terms} />
          <MainLayout exact path="/policy" component={Policy} />
          <MainLayout exact path="/disclaimer" component={Disclaimer} />

          <MainLayout exact path="/sitemap" component={Sitemap} />

          {/* Press Releases | News ROom */}
          <MainLayout exact path="/fintechentity" component={FintechEntity} />
          <MainLayout
            exact
            path="/decimal-point-analytics-inaugurated-its-green-energy-office-in-nashik"
            component={Inauguration_Nashik}
          />
          <MainLayout
            exact
            path="/decimal-point-analytics-receives-grant-funding-to-develop-tokenization-solutions"
            component={Tokenization_Funding}
          />

          <MainLayout
            exact
            path="/decimal-pointa-analytics-and-prmia-collaborate-for-successful-quantifying-esg-risk-event"
            component={QuantifyingESGRisk}
          />

          <MainLayout
            exact
            path="/implementing-machine-readable-regulation-for-competitive-advantage"
            component={ImplementingMRRForCompetitiveAdvantage}
          />

          <MainLayout
            exact
            path="/oracle-global-leaders-recognizes-decimal-point-analytics-as-isv-of-the-year-2023"
            component={OracleGlobalLeaders}
          />
          <MainLayout
            exact
            path="/india-emerging-as-a-global-trendsetter-in-esg-disclosures"
            component={IndiaEmergingAsGlobalTrendsetterInESGDisclosures}
          />
          <MainLayout
            exact
            path="/transformative-impact-of-gpt-on-the-analytics-landscape"
            component={TransformativeImpactofGPT}
          />
          <MainLayout
            exact
            path="/decimal-point-analytics-wins-canadian-reg-tech-award-for-best-esg-solution-2023"
            component={CanadianRegTechAward}
          />
          <MainLayout
            exact
            path="/central-bank-digital-currency-enabled-fx-transactions"
            component={CentralBankDigitalCurrency}
          />
          <MainLayout
            exact
            path="/india-has-an-enviable-place-in-global-esg-disclosure-list"
            component={ESGDisclosureList}
          />
          <MainLayout
            exact
            path="/llms-the-drama-around-openai-is-Q-the-death-of-co-pilot"
            component={LLMDramaAroundOpenAI}
          />
          <MainLayout
            exact
            path="/is-it-time-to-write-esg-requiem-not-quite"
            component={RequiemforESG}
          />
          <MainLayout
            exact
            path="/gautam-naidu-joins-decimal-point-analytics-as-chief-revenue-officer-in-mumbai"
            component={GautamNaiduJoinsDPA}
          />
          <MainLayout
            exact
            path="/central-bank-digital-currencies-the-new-era-of-money"
            component={TheNewEraOfMoney}
          />
          <MainLayout
            exact
            path="/decimal-point-analytics-plans-overseas-expansion"
            component={DPAPlansOverseasExpansion}
          />
          <MainLayout
            exact
            path="/ai-versus-ai-how-ai-can-fight-ai-to-solve-cyberthreat"
            component={AIVersusAI}
          />
          <MainLayout
            exact
            path="/cybersecurity-in-fintech-ensuring-data-protection-and-privacy-in-a-world-of-connectivity"
            component={CybersecurityInFintech}
          />
          <MainLayout
            exact
            path="/llms-beyond-chatbots-new-frontiers"
            component={LLMsBeyondChatbots}
          />
          <MainLayout
            exact
            path="/unlocking-success-key-trends-for-asset-management-in-2024"
            component={UnlockingSuccess}
          />
          <MainLayout
            exact
            path="/unveiling-net-zero-decoding-decimal-point-analytics-purposeful-journey-towards-sustainability"
            component={UnveilingNetZero}
          />
          <MainLayout
            exact
            path="/shaping-a-new-growth-mindset-through-innovation-ideas-at-the-growth-summit"
            component={ShapingNewGrowth}
          />
          <MainLayout
            exact
            path="/global-transformations-the-impact-of-ai-trends-across-industries"
            component={GlobalTransformations}
          />
          <MainLayout
            exact
            path="/blockchain-beyond-bitcoin-a-journey-towards-true-financial-inclusion"
            component={BlockchainBeyondBitcoin}
          />
          <MainLayout
            exact
            path="/digital-assets-can-look-forward-to-a-brighter-future-in-2024"
            component={DigitalAssetsFuture}
          />
          <MainLayout
            exact
            path="/global-vaults-for-a-connected-world-the-promise-of-data-embassies"
            component={GlobalVaults}
          />
          <MainLayout
            exact
            path="/pixels-and-bullets-the-case-for-digital-defence-strategies"
            component={PixelsandBullets}
          />
          <MainLayout
            exact
            path="/decimal-point-analytics-empowers-underprivileged-students-through-dnyanodapi-dhanodapi-program"
            component={DPAEmpowersStudents}
          />
          <MainLayout
            exact
            path="/dilip-sah-joins-decimal-point-analytics-as-chief-technology-officer"
            component={DilipSahJoinsAsCTO}
          />
          <MainLayout
            exact
            path="/industry-experts-highlights-impact-of-saas-on-business-landscapes"
            component={IndustryExpertsSaaS}
          />
          <MainLayout
            exact
            path="/rollercoaster-ride-of-bitcoin-what-lies-ahead"
            component={RollercoasterRideofBitcoin}
          />
          <MainLayout
            exact
            path="/does-adhering-to-green-practices-add-costs-for-businesses"
            component={AdheringtoGreenPractices}
          />
          <MainLayout
            exact
            path="/beyond-the-eiffel-tower-building-frances-tech-undersea-wealth"
            component={BeyondTheEiffelTower}
          />

          <MainLayout
            exact
            path="/technical-debt-crisis-a-ticking-time-bomb-in-the-digital-age"
            component={TechnicalDebtCrisis}
          />

          <MainLayout
            exact
            path="/budget-expectation-2024"
            component={BudgetExpectations2024}
          />

          <MainLayout
            exact
            path="/global-and-instant-magnification-of-stupidity-as-we-watch-the-dream-of-the-internet-failing"
            component={GlobalAndInstant}
          />

          <MainLayout
            exact
            path="/global-capability-centers-strategic-catalysts-of-digital-transformation"
            component={GlobalCapabilityCenters}
          />

          <MainLayout
            exact
            path="/the-holographic-future-of-regulation-why-we-need-a-radical-new-approach-unravelling-the-regulatory-knot"
            component={HolographicFutureofRegulation}
          />

          <MainLayout
            exact
            path="/indias-modern-day-arthashastra-a-ministry-of-economic-security"

            component={IndiasModernDayArthashastra}
          />

          <MainLayout
            exact
            path="/reshaping-discovery-ais-transformative-impact-on-scientific-research"
            component={ReshapingDiscovery}
          />

          <MainLayout
            exact
            path="/decimal-point-analytics-launches-comprehensive-investor-relationship-management-solutions"
            component={ComprehensiveInvestor}
          />

          <MainLayout
            exact
            path="/the-new-economics-when-ai-meets-multi-agent-networks"
            component={TheNewEconomics}
          />

          <MainLayout
            exact
            path="/contemplating-cyberspace-and-beyond"
            component={ContemplatingCyberspace}
          />

          <MainLayout
            exact
            path="/decimal-point-analytics-hosts-hackathon-2025"
            component={Hackathon2025}
          />

          <MainLayout
            exact
            path="/navigating-the-ai-landscape-key-trends-shaping-2025-and-beyond"
            component={NavigatingAILandscape}
          />

          <MainLayout
            exact
            path="/shailesh-dhuri-ceo-of-decimal-point-analytics-discusses-genai-and-sustainability-in-exclusive-interviews-with-cxo-news-and-apac-news-network"
            component={ShaileshDhuriGenAI}
          />

          {/* Press Releases | News ROom */}

          <MainLayout exact path="/case-studies" component={Case_studies} />
          <MainLayout exact path="/articles" component={Articles} />
          <MainLayout exact path="/blog" component={Blogs} />
          <MainLayout exact path="/infographics" component={Infographics} />
          <MainLayout exact path="/podcast_old" component={Podcast} />
          <MainLayout exact path="/whitepaper" component={Whitepaper} />

          <MainLayout exact path="/podcast" component={Podcast_New} />

          {/* ESG Pages Started */}
          <MainLayout
            exact
            path="/environmental-social-and-governance-for-financial-institutions-and-enterprise-businesses"
            component={ESG}
          />

          <MainLayout
            exact
            path="/esg-insights/esg-whitepaper"
            component={ESG_Whitepaper}
          />
          <MainLayout
            exact
            path="/esg-insights/esg-newsletter"
            component={ESG_Newsletter}
          />
          <MainLayout
            exact
            path="/esg-insights/esg-podcast"
            component={ESG_Podcast}
          />
          <MainLayout
            exact
            path="/esg-insights/esg-blogs"
            component={ESG_Blogs}
          />
          <MainLayout
            exact
            path="/empowering-esg-investment-with-real-time-analytics"
            component={ESGNewsSentiMeter}
          />
          <MainLayout
            exact
            path="/elevating-private-equity-performance-with-strategic-esg-solutions"
            component={ESGPrivateEquity}
          />
          <MainLayout
            exact
            path="/align-your-investments-with-global-sustainability-goals"
            component={ESGGreenInvestPulse}
          />

          {/* ESG Whitepapers Imports */}
          <MainLayout
            exact
            path="/esg-insights/esg-whitepaper/navigating-sustainability-usa-esg-landscape-in-2023"
            component={ESG_USA_NavigatingSustainability}
          />
          <MainLayout
            exact
            path="/esg-insights/esg-whitepaper/esg-canada-review-2023-whitepaper"
            component={ESG_canada_review}
          />
          <MainLayout
            exact
            path="/esg-insights/esg-whitepaper/esg-south-africa-review-2023-whitepaper"
            component={ESGSouthAfricaReview2023Whitepaper}
          />
          {/* ESG Whitepapers Imports */}

          {/* ESG Newsletter Imports */}
          <MainLayout
            exact
            path="/esg-insights/esg-newsletter/building-analytical-solutions-for-a-sustainable-future-august-edition"
            component={ESG_Newsletter_August}
          />
          <MainLayout
            exact
            path="/esg-insights/esg-newsletter/building-analytical-solutions-for-a-sustainable-future-october-edition"
            component={ESG_Newsletter_October}
          />

          <MainLayout
            exact
            path="/esg-insights/esg-newsletter/building-analytical-solutions-for-a-sustainable-future-january-edition"
            component={ESG_Newsletter_January}
          />

          <MainLayout
            exact
            path="/esg-insights/esg-newsletter/building-analytical-solutions-for-a-sustainable-future-june-edition"
            component={ESG_Newsletter_June}
          />

          <MainLayout
            exact
            path="/esg-insights/esg-newsletter/building-analytical-solutions-for-a-sustainable-future-july-edition"
            component={ESG_Newsletter_July}
          />
          {/* ESG Newsletter Imports */}

          {/* ESG Blogs Imports */}
          <MainLayout
            exact
            path="/esg-insights/esg-blogs/indian-asset-managers-need-to-take-the-lead-in-net-zero-targeting"
            component={IndianAssetManagers}
          />
          <MainLayout
            exact
            path="/esg-insights/esg-blogs/giving-the-green-signalfor-esg-investing-with-the-right-insights"
            component={GreenSignalforESG}
          />
          <MainLayout
            exact
            path="/esg-insights/esg-blogs/unlocking-success-2024-top-five-esg-trends-for-asset-managers"
            component={Unlocking_Success}
          />
          <MainLayout
            exact
            path="/esg-insights/esg-blogs/navigating-climate-change-how-the-financial-services-industry-can-adapt-and-thrive"
            component={ClimateChangeNavigating}
          />
          <MainLayout
            exact
            path="/esg-insights/esg-blogs/what-is-esg-and-its-impact-on-investing"
            component={ESG_and_its_impact}
          />
          <MainLayout
            exact
            path="/esg-insights/esg-blogs/how-portfolio-alignment-tools-implement-esg-strategies-for-investors"
            component={ESGPortfolioAllignmentToolsImplement}
          />
          <MainLayout
            exact
            path="/esg-insights/esg-blogs/how-to-avoid-repenting-your-article-8-declarations"
            component={Article_8_declarations}
          />
          <MainLayout
            exact
            path="/esg-insights/esg-blogs/indian-regulator-wants-to-regulate-esg-ratings"
            component={Indian_regulators}
          />
          <MainLayout
            exact
            path="/esg-insights/esg-blogs/esg-ratings-muddle-created-by-needless-complexity-in-rating-methodologies"
            component={ESG_rating_methodology}
          />
          <MainLayout
            exact
            path="/esg-insights/esg-blogs/masterplan-to-accelerate-decarbonisation-of-five-major-sectors-planned"
            component={Accelerate_decarbonisation}
          />
          <MainLayout
            exact
            path="/esg-insights/esg-blogs/implementation-commitments-loss-and-damage-to-be-the-focus-at-cop27"
            component={Implementation_commitments}
          />
          <MainLayout
            exact
            path="/esg-insights/esg-blogs/unep-emissions-gap-report-for-2022-paints-a-grim-picture"
            component={UNEP}
          />
          <MainLayout
            exact
            path="/esg-insights/esg-blogs/denmark-sets-a-precedent-on-loss-and-damage-funding"
            component={Denmark}
          />
          <MainLayout
            exact
            path="/esg-insights/esg-blogs/emission-performance-of-global-auto-a-mixed-bag-in-2021"
            component={Emission_performance}
          />
          <MainLayout
            exact
            path="/esg-insights/esg-blogs/sebastian-vettel-and-net-zero"
            component={Sebastian_vettel}
          />
          {/* ESG Blogs Imports */}

          <MainLayout exact path="/pdfquery" component={PdfQuery} />
          <MainLayout
            exact
            path="/pdfquery/terms"
            component={SmartCloudTerms}
          />
          <MainLayout
            exact
            path="/pdfquery/privacy"
            component={SmartCloudPolicy}
          />

          <MainLayout exact path="/eazydata" component={Smartpipe} />
          <MainLayout
            exact
            path="/eazydata/terms"
            component={SmartCloudTerms}
          />
          <MainLayout
            exact
            path="/eazydata/privacy"
            component={SmartCloudPolicy}
          />

          <MainLayout exact path="/pentagon" component={Pentagon} />
          <MainLayout exact path="/webinar" component={Webinar} />
          <MainLayout
            exact
            path="/newsroom/media-coverage"
            component={MediaCoverage}
          />
          <MainLayout
            exact
            path="/newsroom/press-release"
            component={PressRelease}
          />
          <MegamenuLayout exact path="/megamenu" component={Index_Megamenu} />

          {/* <SBALayout exact path="/sba" component={OutsourceSBA} /> */}

          <CRMLayout exact path="/crm" component={CrmHero} />
          <CRMLayout exact path="/crm-form-submit" component={CRM_Form_submit} />

          <SBASubmitLayout
            exact
            path="/sba-form-submit"
            component={SBA_Form_submit}
          />

          <SBALayout exact path="/oracle" component={Oracle} />
          <MainLayout exact path="/ibm" component={IBM} />
          <MainLayout exact path="/clickup-partnership" component={Clickup} />

          <SBASubmitLayout
            exact
            // path="/oracle-form-submit"
            path="/form-submitted"
            component={PartnersContactUs}
          />

          <MainLayout
            path="/newsletter/monthly-newsletter-nov-2024.html"
            exact
            component={() => {
              window.location.href =
                "/newsletter/monthly-newsletter-nov-2024.html";
              return null;
            }}
          />

          <MainLayout
            path="/newsletter/monthly-newsletter-oct-2024.html"
            exact
            component={() => {
              window.location.href =
                "/newsletter/monthly-newsletter-oct-2024.html";
              return null;
            }}
          />

          <MainLayout
            path="/newsletter/monthly-newsletter-sep-2024.html"
            exact
            component={() => {
              window.location.href =
                "/newsletter/monthly-newsletter-sep-2024.html";
              return null;
            }}
          />

          <MainLayout
            path="/newsletter/monthly-newsletter-aug-2024.html"
            exact
            component={() => {
              window.location.href =
                "/newsletter/monthly-newsletter-aug-2024.html";
              return null;
            }}
          />

          <MainLayout
            path="/newsletter/monthly-newsletter-jul-2024.html"
            exact
            component={() => {
              window.location.href =
                "/newsletter/monthly-newsletter-jul-2024.html";
              return null;
            }}
          />

          <MainLayout
            path="/newsletter/monthly-newsletter-jun-2024.html"
            exact
            component={() => {
              window.location.href =
                "/newsletter/monthly-newsletter-jun-2024.html";
              return null;
            }}
          />

          <MainLayout
            path="/newsletter/monthly-newsletter-may-2024.html"
            exact
            component={() => {
              window.location.href =
                "/newsletter/monthly-newsletter-may-2024.html";
              return null;
            }}
          />

          <MainLayout
            path="/newsletter/monthly-newsletter-apr-2024.html"
            exact
            component={() => {
              window.location.href =
                "/newsletter/monthly-newsletter-apr-2024.html";
              return null;
            }}
          />

          <MainLayout
            path="/newsletter/monthly-newsletter-mar-2024.html"
            exact
            component={() => {
              window.location.href =
                "/newsletter/monthly-newsletter-mar-2024.html";
              return null;
            }}
          />

          <MainLayout
            path="/newsletter/monthly-newsletter-feb-2024.html"
            exact
            component={() => {
              window.location.href =
                "/newsletter/monthly-newsletter-feb-2024.html";
              return null;
            }}
          />

          <MainLayout
            path="/newsletter/monthly-newsletter-jan-2024.html"
            exact
            component={() => {
              window.location.href =
                "/newsletter/monthly-newsletter-jan-2024.html";
              return null;
            }}
          />

          <MainLayout
            path="/newsletter/monthly-newsletter-dec-2023.html"
            exact
            component={() => {
              window.location.href =
                "/newsletter/monthly-newsletter-dec-2023.html";
              return null;
            }}
          />

          <MainLayout
            path="/newsletter/monthly-newsletter-nov-2023.html"
            exact
            component={() => {
              window.location.href =
                "/newsletter/monthly-newsletter-nov-2023.html";
              return null;
            }}
          />

          <MainLayout
            path="/newsletter/monthly-newsletter-oct-2023.html"
            exact
            component={() => {
              window.location.href =
                "/newsletter/monthly-newsletter-oct-2023.html";
              return null;
            }}
          />

          <MainLayout
            path="/newsletter/monthly-newsletter-sep-2023.html"
            exact
            component={() => {
              window.location.href =
                "/newsletter/monthly-newsletter-sep-2023.html";
              return null;
            }}
          />

          <MainLayout
            path="/newsletter/monthly-newsletter-aug-2023.html"
            exact
            component={() => {
              window.location.href =
                "/newsletter/monthly-newsletter-aug-2023.html";
              return null;
            }}
          />

          <MainLayout
            path="/newsletter/monthly-newsletter-jul-2023.html"
            exact
            component={() => {
              window.location.href =
                "/newsletter/monthly-newsletter-jul-2023.html";
              return null;
            }}
          />

          <MainLayout
            path="/newsletter/monthly-newsletter-jun-2023.html"
            exact
            component={() => {
              window.location.href =
                "/newsletter/monthly-newsletter-jun-2023.html";
              return null;
            }}
          />

          <MainLayout
            path="/newsletter/monthly-newsletter-may-2023.html"
            exact
            component={() => {
              window.location.href =
                "/newsletter/monthly-newsletter-may-2023.html";
              return null;
            }}
          />

          <MainLayout
            path="/newsletter/monthly-newsletter-apr-2023.html"
            exact
            component={() => {
              window.location.href =
                "/newsletter/monthly-newsletter-apr-2023.html";
              return null;
            }}
          />

          <MainLayout
            path="/newsletter/monthly-newsletter-mar-2023.html"
            exact
            component={() => {
              window.location.href =
                "/newsletter/monthly-newsletter-mar-2023.html";
              return null;
            }}
          />

          <MainLayout
            path="/newsletter/monthly-newsletter-feb-2023.html"
            exact
            component={() => {
              window.location.href =
                "/newsletter/monthly-newsletter-feb-2023.html";
              return null;
            }}
          />

          <MainLayout
            path="/newsletter/monthly-newsletter-jan-2023.html"
            exact
            component={() => {
              window.location.href =
                "/newsletter/monthly-newsletter-jan-2023.html";
              return null;
            }}
          />

          <MainLayout
            path="/newsletter/monthly-newsletter-dec-2022.html"
            exact
            component={() => {
              window.location.href =
                "/newsletter/monthly-newsletter-dec-2022.html";
              return null;
            }}
          />

          <MainLayout
            path="/newsletter/monthly-newsletter-nov-2022.html"
            exact
            component={() => {
              window.location.href =
                "/newsletter/monthly-newsletter-nov-2022.html";
              return null;
            }}
          />

          <MainLayout
            path="/newsletter/monthly-newsletter-oct-2022.html"
            exact
            component={() => {
              window.location.href =
                "/newsletter/monthly-newsletter-oct-2022.html";
              return null;
            }}
          />

          <MainLayout
            path="/newsletter/monthly-newsletter-sep-2022.html"
            exact
            component={() => {
              window.location.href =
                "/newsletter/monthly-newsletter-sep-2022.html";
              return null;
            }}
          />

          {/* Current Openings Start */}
          <MainLayout
            exact
            path="/current-openings/:designation"
            component={JobDetail}
          />
          {/* Current Openings End */}

          <MainLayout exact path="/life-as-a-decimal" component={LifeAsADecimal} />
          <MainLayout exact path="/our-culture" component={OurCulture} />
          <MainLayout exact path="/journey-of-a-decimal" component={JourneyOfADecimal} />
          <MainLayout exact path="/dnyanodapi-dhanodapi" component={CreatingOpportunity} />
          <MainLayout exact path="/current-openings" component={CurrentOpenings} />

          <MainLayout exact path="/investor-relations-solutions" component={InvestorRelations} />
          <MainLayout exact path="/request-for-proposals-solutions" component={RFP} />
          <MainLayout exact path="/due-diligence-questionnaires-solutions" component={DDQ} />
          <MainLayout exact path="/consultant-database-management-solutions" component={CDM} />
          <MainLayout exact path="/customer-relationship-management-solutions" component={CRM} />


          <FreeLayout exact path="/magazine" component={Magazine} />



          <MainLayout component={PageNotFound} />
        </Switch>
        {/* <Footer /> */}
        {/* <Footer_new /> */}
        {<LandingPopUp />}
      </Router>

      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default App;
