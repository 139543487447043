import { Container, Grid, Typography, Box, Tabs, Tab, AppBar } from "@material-ui/core";
import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import { Helmet } from "react-helmet";
import Subscribe from "../../Component/Subscribe";
import { Link } from 'react-router-dom';
import Newsletter_Data from "./Newsletter_data";
import PropTypes from "prop-types";
import Design from "./Newsletter_Design";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function NewsletterNew() {

  const [value, setValue] = React.useState(2025);

  const handleChange = (event, newValue) => {
    window.scrollTo(0, 0);
    setValue(newValue);
  };


  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const year2025 = () => {
    return Newsletter_Data.filter(item => item.year === 2025);
  };
  const year2024 = () => {
    return Newsletter_Data.filter(item => item.year === 2024);
  };
  const year2023 = () => {
    return Newsletter_Data.filter(item => item.year === 2023);
  };
  const year2022 = () => {
    return Newsletter_Data.filter(item => item.year === 2022);
  };

  const data2025 = year2025();
  const data2024 = year2024();
  const data2023 = year2023();
  const data2022 = year2022();

  return (
    <>


      <Helmet>
        <meta charset="UTF-8" />
        <title>Newsletter Updates | Decimal Point Analytics</title>
        <meta name="description" content="Read the latest newsletter from Decimal Point Analytics. Get valuable insights into the world of business and technology. " />
        <meta name="keywords" content="ESG Disclosures, Data Science, AI, Banking Operations, Financial Ratings Firm, Data management, Research" />
        <meta name="author" content="Newsletter by Decimal Point Analytics" />
        <link rel="canonical" href="https://www.decimalpointanalytics.com/newsletter" />
      </Helmet>


      <Box
        id="newsletter"
        className="section sec_bg"
      >
        <Container maxWidth="lg" className="bdr">
          <Grid container spacing={1} alignItems="top">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className="al_center" mt={5} mb={1}>
                <Typography variant="h1" className="black mb0 newh1">
                  Newsletter
                </Typography>
                <Typography variant="h6" className="black mb0">
                  Stay upto date about the latest happenings in the Financial Industry.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>

        <Box className="maintabbedsection stiky linkedtabs">
          <Container maxWidth="lg" className="bdr">
            <Grid container spacing={1} alignItems="center">
              <Grid item lg={10} md={10} sm={8} xs={12}>
                <AppBar position="relative" className="tabbedbar">
                  <Tabs value={value} onChange={handleChange} variant="scrollable">
                  <Tab
                      label="2025"
                      component={Link}
                      to="/newsletter"
                      value={2025}
                    />
                    <Tab
                      label="2024"
                      component={Link}
                      to="/newsletter"
                      value={2024}
                    />
                    <Tab
                      label="2023"
                      component={Link}
                      to="/newsletter"
                      value={2023}
                    />
                    <Tab
                      label="2022"
                      component={Link}
                      to="/newsletter"
                      value={2022}
                    />
                  </Tabs>
                </AppBar>
              </Grid>
              <Grid item lg={2} md={2} sm={4} xs={12}>
                <Box mt={'-10px'} mb={1} className="flx_fe">
                  <Subscribe />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>

        <TabPanel value={value} index={2025}>
          <Box id="newsroom" className="sec_bg" component="section">
            <Container maxWidth="lg" className="bdr">

              <Box className="reportinfo">
                <Grid container spacing={1} alignItems="top" justifyContent="left">

                  {data2025.map((item, index) => (
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Design
                        key={index}
                        year={item.year}
                        link={item.link}
                        date={item.date}
                        imgsrc={item.imgsrc}
                      />
                    </Grid>
                  ))}

                </Grid>
              </Box>

            </Container>
          </Box>
        </TabPanel>

        <TabPanel value={value} index={2024}>
          <Box id="newsroom" className="sec_bg" component="section">
            <Container maxWidth="lg" className="bdr">

              <Box className="reportinfo">
                <Grid container spacing={1} alignItems="top" justifyContent="center">

                  {data2024.map((item, index) => (
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Design
                        key={index}
                        year={item.year}
                        link={item.link}
                        date={item.date}
                        imgsrc={item.imgsrc}
                        heading={item.heading}
                        subheading={item.subheading}
                      />
                    </Grid>
                  ))}

                </Grid>
              </Box>

            </Container>
          </Box>
        </TabPanel>

        <TabPanel value={value} index={2023}>
          <Box id="newsroom" className="sec_bg" component="section">
            <Container maxWidth="lg" className="bdr">

              <Box className="reportinfo">
                <Grid container spacing={1} alignItems="top" justifyContent="center">

                  {data2023.map((item, index) => (
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Design
                        key={index}
                        year={item.year}
                        link={item.link}
                        date={item.date}
                        imgsrc={item.imgsrc}
                        heading={item.heading}
                      />
                    </Grid>
                  ))}

                </Grid>
              </Box>

            </Container>
          </Box>
        </TabPanel>

        <TabPanel value={value} index={2022}>
          <Box id="newsroom" className="sec_bg" component="section">
            <Container maxWidth="lg" className="bdr">

              <Box className="reportinfo">
                <Grid container spacing={1} alignItems="top" justifyContent="center">

                  {data2022.map((item, index) => (
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Design
                        key={index}
                        year={item.year}
                        link={item.link}
                        date={item.date}
                        imgsrc={item.imgsrc}
                        heading={item.heading}
                      />
                    </Grid>
                  ))}

                </Grid>
              </Box>

            </Container>
          </Box>
        </TabPanel>
      </Box>
    </>
  );
}
